import React, { Component } from "react";
import { Router, Route, Switch } from "react-router-dom";
import { history } from "../index";
import { PrivateRoute } from "./PrivateRouter";
import SignUpContainer from "../containers/SignUpContainer";
import Login from "../containers/LoginContainer";
import NoMatch from "../components/NoMatch";
import SMSConfrimContainer from "../containers/SMSConfrimContainer";
import SetPasswordContainer from "../containers/SetPasswordContainer";
import PageUser from "../containers/PageUserContainer";
import RecipientPage from "../containers/RecipientPageContainer";
import ProductPage from "../containers/ProductPageContainer";
import ChangePhone from "../containers/ChangePhoneContainer";
import CreateChildUser from "../containers/CreateChildUserContainer";
import AddRecipient from "../containers/AddRecipient";
import Inventory from "../containers/InventoryContainer";
import ProductItemsPage from "../containers/ProductItemsPageContainer";
import { IntlProvider, FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import CategoryPermissionPage from "../components/CategoryPermissionPage";
import ProductTransactionPage from "../containers/ProductTransactionPageContainer";

export const intl = key => {
  if (!key) return "";
  return <FormattedMessage id={key} defaultMessage={key} />;
};

class AppRouter extends Component {
  render() {
    return (
      <IntlProvider
        locale={this.props.locale.lang}
        messages={this.props.locale.messages[this.props.locale.lang]}
      >
        <Router history={history}>
          <div>
            <Switch>
              <PrivateRoute exact path="/" component={PageUser} />
              <Route exact path="/login" component={Login} />
              <Route path="/sign_up" component={SignUpContainer} />
              <Route path="/sms" component={SMSConfrimContainer} />
              <Route path="/change_phone" component={ChangePhone} />
              <Route path="/create_child_user" component={CreateChildUser} />
              <Route path="/add_recipient" component={AddRecipient} />
              <PrivateRoute
                path="/set_password"
                component={SetPasswordContainer}
              />
              <PrivateRoute path="/product" component={ProductPage} />
              <PrivateRoute path="/recipient/:id" component={RecipientPage} />
              <PrivateRoute path="/inventories" component={Inventory} />
              <PrivateRoute
                path="/product_items"
                component={ProductItemsPage}
              />

              <PrivateRoute
                path="/transactions"
                component={ProductTransactionPage}
              />

              <Route
                path="/category_permission"
                component={CategoryPermissionPage}
              />
              <Route component={NoMatch} />
            </Switch>
          </div>
        </Router>
      </IntlProvider>
    );
  }
}

const mapStateToProps = state => ({
  ...state
});

export default connect(
  mapStateToProps,
  {}
)(AppRouter);
