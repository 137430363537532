import {
  CHANGE_FIELD,
  FIELD_PHONE,
  FIELD_FIRST_NAME,
  FIELD_LAST_NAME,
  FIELD_STORE,
  FIELD_CATEGORY,
  FIELD_CLEAR,
  CREATE_CHILD_USER_REQUEST_SUCCESS,
  CREATE_CHILD_USER_REQUEST_FAILURE
} from "../actions/create_child_user";
import { USER_LOGIN, OPRIONS_RECIPIENT_CATEGORY } from "../utils/constants";

const user = JSON.parse(localStorage.getItem(USER_LOGIN)) || {};

const initialState = {
  firstName: "",
  lastName: "",
  phone: "",
  category: "",
  store: "",
  user: user,
  options: OPRIONS_RECIPIENT_CATEGORY[user.user_category]
};

export default function createChildUser(state = initialState, action) {
  switch (action.type) {
    case CHANGE_FIELD + FIELD_FIRST_NAME:
      return { ...state, firstName: action.payload };

    case CHANGE_FIELD + FIELD_LAST_NAME:
      return { ...state, lastName: action.payload };

    case CHANGE_FIELD + FIELD_PHONE:
      return { ...state, phone: action.payload };

    case CHANGE_FIELD + FIELD_CATEGORY:
      return { ...state, category: action.payload };

    case CHANGE_FIELD + FIELD_STORE:
      return { ...state, store: action.payload };

    case CREATE_CHILD_USER_REQUEST_SUCCESS:
      return { ...state, success: action.res }

    case CREATE_CHILD_USER_REQUEST_FAILURE:
      return { ...state, error: action.error }

    case FIELD_CLEAR:
      return { ...initialState };

    default:
      return state;
  }
}
