import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";

export default class UIModel extends Component {
  render() {
    return (
      <Modal
        show={this.props.show}
        onRequestHide={this.props.onRequestHide}
        size="lg"
        dialogClassName={this.props.dialogClassName}
      >
        <Modal.Header>
          <Modal.Title>{this.props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{this.props.children}</Modal.Body>
        <Modal.Footer>
          <Button onClick={this.props.onClickCancel}>
            {this.props.textCancel}
          </Button>
          {this.props.textOk && (
            <Button
              bsStyle="primary"
              onClick={this.props.onClickOk}
              disabled={this.props.disabledOkBtn}
            >
              {this.props.textOk}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    );
  }
}
