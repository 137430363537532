import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ProductItemInfoModal from "../components/Modals/ProductItemInfoModal";
import * as productItemInfoModalActions from "../actions/product_item_info_modal";

const mapStateToProps = state => ({
  ...state.productItemInfoModal
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...productItemInfoModalActions
      },
      dispatch
    )
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductItemInfoModal);
