import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as recipientPageActions from "../actions/recipient_page";
import RecipientPage from "../components/RecipientPage";

const mapStateToProps = (state, ownProps) => ({
  ...state.recipients,
  selected_recipient_id: ownProps.match.params.id
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...recipientPageActions
      },
      dispatch
    )
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RecipientPage);
