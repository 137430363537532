import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Inventory from "../components/Inventory";
import * as InventoryAtions from "../actions/inventory";
import { open as addInventoryModalOpen } from "../actions/add_inventory_modal";

const mapStateToProps = state => ({
  ...state.inventory
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...InventoryAtions,
        addInventoryModalOpen
      },
      dispatch
    )
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Inventory);
