import { OPEN, CLOSE } from "../actions/products_to_recipient";
import { PRODUCTS_TO_RECIPIENT_SUCCESS } from "../actions/recipient_page";

const initialState = {
  isOpen: false,
  users: [],
  products: [],
  logged_user: JSON.parse(localStorage.getItem("user"))
};

export default function productsToRecipient(state = initialState, action) {
  switch (action.type) {
    case OPEN:
      return {
        ...state,
        isOpen: true
      };
    case CLOSE:
      return {
        ...state,
        isOpen: false
      };

    case PRODUCTS_TO_RECIPIENT_SUCCESS:
      return {
        ...state,
        users: action.users,
        products: action.products
      };
    default:
      return state;
  }
}
