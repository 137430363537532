import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import * as productActions from "../actions/product_page";
import {
  onRequestHide as onRequestHideProduct,
  onRequestShow as onRequestShowProduct
} from "../actions/add_product_modal";
import {
  onRequestHide as onRequestHideProductType,
  onRequestShow as onRequestShowProductType
} from "../actions/add_product_type_modal";
import {
  onRequestHide as onRequestHideQRcode,
  onRequestShow as onRequestShowQRcode
} from "../actions/qr_code_modal";
import ProductPage from "../components/ProductPage";
import {
  onRequestHide as onRequestHideProductItem,
  onRequestShow as onRequestShowProductItem,
  goToProductItems
} from "../actions/add_product_item";

const mapStateToProps = state => ({
  ...state,
  ...state.productPage
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...productActions,
        onRequestHideProduct,
        onRequestShowProduct,
        onRequestHideProductType,
        onRequestShowProductType,
        onRequestHideQRcode,
        onRequestShowQRcode,
        showLoading,
        hideLoading,
        onRequestHideProductItem,
        onRequestShowProductItem,
        goToProductItems
      },
      dispatch
    )
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductPage);
