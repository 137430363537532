import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as productTypeActions from "../actions/product_type_list";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import ProductTypeList from "../components/ProductTypeList";

const mapStateToProps = state => ({
  ...state.productTypeList,
  ...state.productPage
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...productTypeActions,
        showLoading,
        hideLoading
      },
      dispatch
    )
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductTypeList);
