import axios from "axios";
import history from "../App";
import { AUTH_TOKEN } from "../utils/constants";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { setLocale as setLocaleBase } from "./language_locale";

export const CHANGE_FIELD = "CHANGE_FIELD/";
export const FIELD_PHONE = "FIELD_PHONE";
export const FIELD_CLEAR = "FIELD_CLEAR";
export const FIELD_PASSWORD = "FIELD_STORE";
export const PHONE_VALIATE_ERROR = "PHONE_VALIATE_ERROR";
export const LOGIN_REQUEST_SUCCESS = "LOGIN_REQUEST_SUCCESS";
export const LOGIN_REQUEST_FAILURE = "LOGIN_REQUEST_FAILURE";

export const FIELDS = {
  phone: CHANGE_FIELD + FIELD_PHONE,
  password: CHANGE_FIELD + FIELD_PASSWORD
};

export function getFormValues(state) {
  const formValues = {
    phone: state.phone,
    password: state.password
  };
  return formValues;
}

export const changeLogin = value => {
  return (dispatch, getState) =>
    dispatch(changeField(value.target.name, value.target.value));
};

export function changeField(field, value) {
  return { type: FIELDS[field], payload: value };
}

export function login() {
  return (dispatch, getState) => {
    const state = getState();
    const phone = getPhone(state);
    const password = state.login.password;
    if (valitatePhone(phone)) {
      dispatch(showLoading());
      axios
        .post(
          "api/users/login",
          JSON.stringify({
            email_or_phone: phone,
            password: password
          })
        )
        .then(res => {
          let auth = res.data;
          if (auth.token)
            localStorage.setItem(AUTH_TOKEN, JSON.stringify(auth));
          dispatch({ type: LOGIN_REQUEST_SUCCESS, payload: auth });
          dispatch({ type: FIELD_CLEAR });
          dispatch(hideLoading());
          history.push("/");
        })
        .catch(error => {
          dispatch({ type: LOGIN_REQUEST_FAILURE, error: error.response });
          dispatch(hideLoading());
        });
    } else {
      dispatch({
        type: PHONE_VALIATE_ERROR,
        phoneError: "error.phone.notValidate"
      });
    }
  };
}

export function valitatePhone(phone) {
  const regex = /^\+[0-9]*$/gm;
  return phone.match(regex);
}

export function getPhone(state) {
  const phone = state.login.phone;
  const phone_clear1 = phone.replace("(", "");
  const phone_clear2 = phone_clear1.replace(")", "");
  const phone_clear3 = phone_clear2.replace("-", "");
  return phone_clear3;
}

export const setLocale = setLocaleBase;
