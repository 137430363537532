import axios from "axios";
import { authHeader } from "../utils/auth_headers";
import { hideLoading } from "react-redux-loading-bar";
import { SHOW_LOAD } from "./product_type_list";

export const GET_PRODUCTS_REQUEST_SUCCESS = "GET_PRODUCTS_REQUEST_SUCCESS";
export const GET_PRODUCTS_REQUEST_FAILURE = "GET_PRODUCTS_REQUEST_FAILURE";
export const GET_PRODUCT_TYPES_REQUEST_SUCCESS =
  "GET_PRODUCT_TYPES_REQUEST_SUCCESS";
export const GET_PRODUCT_TYPES_REQUEST_FAILURE =
  "GET_PRODUCT_TYPES_REQUEST_FAILURE";
export const GET_PRODUCT_TYPES_REQUEST_IS_LOAD =
  "GET_PRODUCT_TYPES_REQUEST_IS_LOAD";

export const DELETE_PRODUCT_REQUEST_SUCCESS = "DELETE_PRODUCT_REQUEST_SUCCESS";

export function getProudctTypes() {
  return (dispatch, getState) => {
    axios.defaults.headers = authHeader();
    axios
      .get("api/product_type/get_all")
      .then(res => {
        let product_types = res.data;
        product_types.map(product_type => {
          product_type.label = product_type.name;
          product_type.value = product_type.id;
          return product_type;
        });
        dispatch({
          type: GET_PRODUCT_TYPES_REQUEST_SUCCESS,
          product_types: product_types
        });
        dispatch({ type: GET_PRODUCT_TYPES_REQUEST_IS_LOAD });
        dispatch({ type: SHOW_LOAD });
      })
      .catch(error => {
        dispatch({
          type: GET_PRODUCT_TYPES_REQUEST_FAILURE,
          error: error.response
        });
      });
  };
}

export function getProudcts() {
  return (dispatch, getState) => {
    axios.defaults.headers = authHeader();
    axios
      .get(`api/product/all?is_sold=${false}&only_me=${false}`)
      .then(res => {
        let products = res.data;

        dispatch({
          type: GET_PRODUCTS_REQUEST_SUCCESS,
          products: products
        });
        dispatch(hideLoading());
      })
      .catch(error => {
        dispatch({
          type: GET_PRODUCTS_REQUEST_FAILURE,
          error: error.response
        });
      });
  };
}
