import React, { Component } from "react";
import NavbarPage from "../containers/NavbarPageContainer";
import { FormattedMessage } from "react-intl";
import { Redirect } from "react-router-dom";
import ProductsToRecipient from "../containers/ProductsToRecipientContainer";

export const SOLD = "sold";
export const SEND = "send";
export const ALL = "all";

export default class RecipientList extends Component {
  componentWillMount() {
    const { selected_recipient_id } = this.props;
    this.props.actions.getProductCountsToRecipient(selected_recipient_id);
  }

  componentWillReceiveProps(nextProps) {
    const { selected_recipient_id } = this.props;
    if (nextProps.selected_recipient_id !== selected_recipient_id)
      this.props.actions.getProductCountsToRecipient(
        nextProps.selected_recipient_id
      );
  }

  render() {
    const product = this.props;
    const recipients = this.props.users;
    const { selected_recipient_id } = this.props;
    const recipient = recipients.find(
      recipient => recipient.id === parseInt(this.props.selected_recipient_id)
    );
    if (!recipient) return <Redirect to="/" />;
    return (
      <div>
        <NavbarPage />
        <div className="user-list-form-control">
          <div>
            <h2 className="text-form-control">
              <FormattedMessage
                id="recipientPage.userProfileText"
                defaultMessage="Профиль пользователя"
              />
            </h2>
            <hr />
          </div>
          <div className="row">
            <div className="col-sm-3">
              <div className="image-form-control">
                <img
                  src={require("../utils/images/icon_user.png")}
                  alt="avatar"
                  className="image-container"
                />
              </div>
            </div>
            <div className="col-md-4">
              <label className="list-group-item">
                <FormattedMessage
                  id="recipientPage.recipientLast_name"
                  defaultMessage="Фамилия:"
                />
              </label>
              <label className="list-group-item">
                <FormattedMessage
                  id="recipientPage.recipientFirst_name"
                  defaultMessage="Имя:"
                />
              </label>
              <label className="list-group-item">
                <FormattedMessage
                  id="recipientPage.recipientPhone"
                  defaultMessage="Тел:"
                />
              </label>
              <label className="list-group-item">
                <FormattedMessage
                  id="recipientPage.recipientUser_category"
                  defaultMessage="Категория:"
                />
              </label>
            </div>
            <div className="col-md-4">
              <label className="list-group-item">{recipient.first_name}</label>
              <label className="list-group-item">{recipient.last_name}</label>
              <label className="list-group-item">{recipient.phone}</label>
              <label className="list-group-item">
                {recipient.user_category}
              </label>
              <br />
            </div>
            <div>
              <h2 className="text-form-control">
                <FormattedMessage
                  id="recipientPage.productInfoText"
                  defaultMessage="Информация о продукте"
                />
              </h2>
            </div>
            <div className="row">
              <div className="col-12 col-md-1" />
              <div className="col-12 col-md-10">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">№</th>
                      <th scope="col">
                        <FormattedMessage
                          id="recipientPage.productName"
                          defaultMessage="Товар"
                        />
                      </th>
                      <th scope="col">
                        <FormattedMessage
                          id="recipientPage.productQuantityOfGoods"
                          defaultMessage="Количество товара"
                        />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      className="table-recipient"
                      onClick={e =>
                        this.props.actions.getProductsRecipient(
                          selected_recipient_id
                        )
                      }
                    >
                      <th scope="row">1</th>
                      <td>
                        <FormattedMessage
                          id="recipientPage.productProductsByUser"
                          defaultMessage="Продукты у пользователя"
                        />
                      </td>
                      <td>{product.unsoldProductCound}</td>
                    </tr>
                    <tr
                      className="table-recipient"
                      onClick={e =>
                        this.props.actions.getProductsRecipient(
                          selected_recipient_id,
                          SOLD
                        )
                      }
                    >
                      <th scope="row">2</th>
                      <td>
                        <FormattedMessage
                          id="recipientPage.productSoldProducts"
                          defaultMessage="Проданный продукты"
                        />
                      </td>
                      <td>{product.soldProductCound}</td>
                    </tr>
                    <tr
                      className="table-recipient"
                      onClick={e =>
                        this.props.actions.getProductsRecipient(
                          selected_recipient_id,
                          SEND
                        )
                      }
                    >
                      <th scope="row">3</th>
                      <td>
                        <FormattedMessage
                          id="recipientPage.productSentProducts"
                          defaultMessage="Переданный продукты"
                        />
                      </td>
                      <td>{product.sendProductCount}</td>
                    </tr>
                    <tr
                      className="table-recipient"
                      onClick={e =>
                        this.props.actions.getProductsRecipient(
                          selected_recipient_id,
                          ALL
                        )
                      }
                    >
                      <th scope="row">4</th>
                      <td>
                        <FormattedMessage
                          id="recipientPage.productAllProducts"
                          defaultMessage="Все продукты"
                        />
                      </td>
                      <td>{product.allCount}</td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <ProductsToRecipient
                  selected_recipient_id={this.props.selected_recipient_id}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
