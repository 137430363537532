import React, { Component } from "react";
import { Button } from "react-bootstrap";
import ReactPhoneInput from "react-phone-input-2";
import { Redirect, Link } from "react-router-dom";
import CreatableSelect from "react-select/lib/Creatable";
import { getAuthToken } from "../utils/auth_headers";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { OPTIONS_USER_CATEGORY } from "../utils/constants";
import { intl } from "../route/AppRouter";

const options = OPTIONS_USER_CATEGORY;

const pStyle = {
  width: "100%"
};

export default class Register extends Component {
  static propsTypes = {
    actions: PropTypes.shape({
      changeSignUpFields: PropTypes.func,
      onChangePhone: PropTypes.func,
      changeSelectCategory: PropTypes.func
    })
  };

  rensderSingUpError() {
    const { errorSingUp } = this.props.signUp;
    return (
      <small className="text-danger">
        {errorSingUp && errorSingUp.data && intl(errorSingUp.data.message)}
      </small>
    );
  }

  renderSignUpForm = () => {
    const { firstName, lastName, store } = this.props.signUp;
    const actions = this.props.actions;
    return (
      <form>
        <div>
          <label>
            <FormattedMessage
              id="register.lastNameText"
              defaultMessage="Фамилия:"
            />{" "}
          </label>
          <input
            className="select-control"
            name="last_name"
            type="text"
            value={lastName}
            onChange={actions.changeSignUpFields}
            required={true}
          />
        </div>
        <div>
          <label>
            <FormattedMessage
              id="register.firstNameText"
              defaultMessage="Имя:"
            />
          </label>
          <input
            className="select-control"
            name="first_name"
            type="text"
            value={firstName}
            onChange={actions.changeSignUpFields}
          />
        </div>
        <div>
          <label>
            <FormattedMessage
              id="register.storText"
              defaultMessage="Торговый точка:"
            />
          </label>
          <input
            className="select-control"
            name="store"
            type="text"
            value={store}
            onChange={actions.changeSignUpFields}
            required={true}
          />
        </div>
      </form>
    );
  };

  renderPhone = () => {
    const { phone } = this.props.signUp;
    return (
      <div>
        <label>
          <FormattedMessage id="register.phoneText" defaultMessage="Тел:" />
        </label>
        <div>
          <ReactPhoneInput
            value={phone}
            defaultCountry={"kg"}
            inputExtraProps={{
              name: "phone",
              required: true,
              autoFocus: true
            }}
            onChange={this.props.actions.onChangePhone}
            required="true"
            inputStyle={pStyle}
            onlyCountries={["kg", "ru", "kz", "uz"]}
            autoFormat={false}
          />
        </div>
      </div>
    );
  };

  renderCotegory = () => {
    return (
      <div>
        <label>
          <FormattedMessage
            id="register.categoryText"
            defaultMessage="Категория:"
          />
        </label>
        <div>
          <CreatableSelect
            name="category"
            options={options}
            placeholder={
              <FormattedMessage
                id="register.categoryPlaceholderText"
                defaultMessage="Выберите категории"
              />
            }
            onChange={this.props.actions.changeSelectCategory}
          />
        </div>
      </div>
    );
  };

  render() {
    const auth_token = getAuthToken();
    if (auth_token) {
      return <Redirect to="/" from="/sing_up" />;
    }
    return (
      <div>
        <div className="signUpForm">
          <h2 className="text-form-control">
            <FormattedMessage
              id="register.titleText"
              defaultMessage="Регистрация"
            />
          </h2>
          {this.renderSignUpForm()}
          {this.renderPhone()}
          {this.rensderSingUpError()}
          {this.renderCotegory()}
          <br />
          <Button
            bsStyle="primary"
            bsSize="large"
            onClick={this.props.actions.signUp}
            block
          >
            <FormattedMessage
              id="rigester.buttonText"
              defaultMessage="Регистрация"
            />
          </Button>
          <div className="text-form-control">
            <Link to="/login" className="btn-link">
              <FormattedMessage id="rigester.linkText" defaultMessage="Назад" />{" "}
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
