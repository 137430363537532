import React, { Component } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import UIModal from "./UIModal";
import { getDate } from "../../utils/utils";
import { intl } from "../../route/AppRouter";

export default class ProductInfo extends Component {
  rowStyleFormat(row, rowIdx) {
    return {
      backgroundColor: row && row.final_buyer ? "#00ff00" : ""
    };
  }

  formatPreview = (cell, row) => {
    let { transactions } = row;
    transactions = transactions || [];
    const transaction = transactions.filter(
      transaction => transaction.customer_id === this.props.recipient.id
    )[0];
    return (
      <div>{transaction && getDate(new Date(transaction.date_create))}</div>
    );
  };

  formatPreviewDateSold(cell, row) {
    if (row.final_buyer) {
      return getDate(new Date(row.final_buyer.create_at));
    }
    return cell;
  }

  renderRecipientInfo = () =>{
    return (
      <div>
        <table class="table table-hover">
          <thead>
          </thead>
          <tbody>
            <tr>
              <th className="info" scope="row">{intl("register.lastNameText")}</th>
              <td className="info" >{this.props.recipient.last_name}</td>
            </tr>
            <tr>
              <th className="info" scope="row">{intl("register.firstNameText")}</th>
              <td className="info">{this.props.recipient.first_name}</td>
            </tr>
            <tr>
              <th className="info" scope="row">{intl("register.phoneText")}</th>
              <td className="info">{this.props.recipient.phone}</td>
            </tr>
            <tr>
              <th className="info" scope="row">{intl("register.storText")}</th>
              <td className="info">{this.props.recipient.store}</td>
            </tr>
            <tr>
              <th className="info" scope="row">{intl("register.categoryText")}</th>
              <td className="info">{this.props.recipient.user_category}</td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }

  render() {
    const { recipient } = this.props;
    if (recipient.user_category === "UC1") {
      recipient.user_category = "Производитель";
    }
    if (recipient.user_category === "UC2") {
      recipient.user_category = "Дистрибютор";
    }
    if (recipient.user_category === "UC3") {
      recipient.user_category = "Диллер-оптовик";
    }
    if (recipient.user_category === "UC4") {
      recipient.user_category = "Магазин-продавец";
    }

    return (
      <div>
        <UIModal
          title={intl("transacrion.infoUIModalTitle")}
          textCancel={intl("productInfo.productInfoUIModalCancel")}
          show={this.props.isOpen}
          onClickCancel={this.props.actions.close}
        >
          <div>
            {this.props.recipient.id && this.renderRecipientInfo()}
            <BootstrapTable
              data={this.props.recipientProducts}
              height="380"
              scrollTop={"Bottom"}
              trStyle={this.rowStyleFormat}
            >
              <TableHeaderColumn dataField="id" isKey dataSort={true}>
                ID
              </TableHeaderColumn>
              <TableHeaderColumn dataField="qr_code" dataSort={true}>
                {intl("comComponents.productQrCode")}
              </TableHeaderColumn>
              <TableHeaderColumn dataFormat={this.formatPreview}>
                {intl("comComponents.dateOfReceiving")}
              </TableHeaderColumn>
              <TableHeaderColumn
                dataFormat={this.formatPreviewDateSold}
                dataField="final_buyer.create_at"
              >
                {intl("comComponents.dateSold")}
              </TableHeaderColumn>
            </BootstrapTable>
          </div>
        </UIModal>
      </div>
    );
  }
}
