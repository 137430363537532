import {
  CHANGE_FIELD,
  FIELD_PASSWORD,
  FIELD_PASSWORD_CONFIRM,
  SET_PASSWORD_SUCCESS,
  SEND_PASSWORD_FAILURE,
  PASSWORD_VALIATE_ERROR,
  FIELD_CLEAR
} from "../actions/set_password";

const initialState = {
  password: "",
  password_confirm: "",
  errors: []
};

export default function setPassword(state = initialState, action) {
  switch (action.type) {
    case CHANGE_FIELD + FIELD_PASSWORD:
      return { ...state, password: action.upload };

    case CHANGE_FIELD + FIELD_PASSWORD_CONFIRM:
      return { ...state, password_confirm: action.upload };

    case PASSWORD_VALIATE_ERROR:
      return { ...state, errors: action.errors };

    case SET_PASSWORD_SUCCESS:
      return { ...state, payload: action.payload };

    case SEND_PASSWORD_FAILURE:
      return { ...state, errorSetPass: action.errors };

   case FIELD_CLEAR:
      return { ...initialState };

    default:
      return state;
  }
}
