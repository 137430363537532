import {
  SHOW_LOAD,
  DELETE_PRODUCT_REQUEST_TYPE_SUCCESS,
  SHOW_DELETE_ALERT,
  HIDE_DELETE_ALERT,
  SELECT_PRODUCT_TYPE,
  SHOW_UPDATE_MODAL_PRODUCT_TYPE,
  HIDE_UPDATE_MODAL_PRODUCT_TYPE,
  PRODUCT_TYPE_UPDATE_REQUEST_FAILURE,
  DELETE_PRODUCT_REQUEST_TYPE_FAILURE
} from "../actions/product_type_list";

import {
  CHANGE_FIELD,
  NAME_PRODUCT_TYPE_FIELD,
  DESCRIPTION_PRODUCT_TYPE_FIELD,
  PRICE_PRODUCT_TYPE_FIELD,
  WARRANTY_DAY_PRODUCT_TYPE_FIELD
} from "../actions/add_product_type_modal";

const initialState = {
  isLoad: false,
  showDeleteAlert: false,
  selected_product_type: {
    name: "",
    description: "",
    price: 0,
    warranty_days: 0
  },
  showUpdateModal: false,
  error: {}
};

export default function productTypeList(state = initialState, action) {
  switch (action.type) {
    case SHOW_LOAD:
      return { ...state, isLoad: true };

    case DELETE_PRODUCT_REQUEST_TYPE_SUCCESS:
      return { ...state };

    case SHOW_DELETE_ALERT:
      return { ...state, showDeleteAlert: true };

    case HIDE_DELETE_ALERT:
      return { ...state, showDeleteAlert: false };

    case SELECT_PRODUCT_TYPE:
      return { ...state, selected_product_type: action.product_type };

    case SHOW_UPDATE_MODAL_PRODUCT_TYPE:
      return { ...state, showUpdateModal: true };

    case HIDE_UPDATE_MODAL_PRODUCT_TYPE:
      return { ...state, showUpdateModal: false };

    case CHANGE_FIELD + NAME_PRODUCT_TYPE_FIELD:
      return {
        ...state,
        selected_product_type: {
          ...state.selected_product_type,
          name: action.value
        }
      };

    case CHANGE_FIELD + DESCRIPTION_PRODUCT_TYPE_FIELD:
      return {
        ...state,
        selected_product_type: {
          ...state.selected_product_type,
          description: action.value
        }
      };

    case CHANGE_FIELD + PRICE_PRODUCT_TYPE_FIELD:
      return {
        ...state,
        selected_product_type: {
          ...state.selected_product_type,
          price: action.value
        }
      };

    case CHANGE_FIELD + WARRANTY_DAY_PRODUCT_TYPE_FIELD:
      return {
        ...state,
        selected_product_type: {
          ...state.selected_product_type,
          warranty_days: action.value
        }
      };

    case PRODUCT_TYPE_UPDATE_REQUEST_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case DELETE_PRODUCT_REQUEST_TYPE_FAILURE:
      return {
        ...state,
        error: action.error
      };

    default:
      return state;
  }
}
