import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as addProductModal from "../actions/add_product_modal";
import AddProductModal from "../components/Modals/AddProductModal";

const mapStateToProps = state => ({
  ...state.addProductModal, ...state.productPage
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...addProductModal
      },
      dispatch
    )
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddProductModal);
