import axios from "axios";
import { authHeader } from "../utils/auth_headers";
import { compose } from "redux";

export const REQUEST_GET_PRODUCT_ITEMS =
  "PRODUCT_ITEMS_PAGE/REQUEST_GET_PRODUCT_ITEMS";
export const REQUEST_GET_PRODUCT_ITEMS_SUCCESS =
  "PRODUCT_ITEMS_PAGE/REQUEST_GET_PRODUCT_ITEMS_SUCCESS";
export const REQUEST_GET_PRODUCT_ITEMS_FAILURE =
  "PRODUCT_ITEMS_PAGE/REQUEST_GET_PRODUCT_ITEMS_FAILURE";

export const getProductItems = () => {
  return (dispatch, getState) => {
    dispatch({ type: REQUEST_GET_PRODUCT_ITEMS });
    axios.defaults.headers = authHeader();
    axios({
      method: "GET",
      url: "api/product_item/get_by_owner"
    })
      .then(res => {
        dispatch({
          type: REQUEST_GET_PRODUCT_ITEMS_SUCCESS,
          productItems: res.data
        });
      })
      .catch(error => {
        dispatch({
          type: REQUEST_GET_PRODUCT_ITEMS_FAILURE,
          error: error
        });
      });
  };
};
