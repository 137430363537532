import { AUTH_TOKEN, USER_LOGIN } from "../utils/constants";
import { history } from "..";
import { setLocale as setLocaleBase } from "../actions/language_locale";

export const FIELD_CLEAR = "FIELD_CLEAR";

export const logout = () => {
  localStorage.removeItem(AUTH_TOKEN);
  localStorage.removeItem(USER_LOGIN);
  history.push("/login");
};

export const goToSetPassPage = () => {
  history.push("/set_password");
};

export const goToProductPage = () => {
  history.push("/product");
};

export const goToCreateUserPage = () => {
  history.push("/create_child_user");
};

export const goToAddRecipientPage = () => {
  history.push("/add_recipient");
};

export const setLocale = setLocaleBase;
