import axios from "axios";
import { history } from "../index";
import { authHeader } from "../utils/auth_headers";

export const CHANGE_FIELD = "CHANGE_FIELD/";
export const FIELD_CLEAR = "FIELD_CLEAR";
export const FIELD_PASSWORD = "FIELD_PASSWORD";
export const FIELD_PASSWORD_CONFIRM = "FIELD_PASSWORD_CONFIRM";
export const PASSWORD_VALIATE_ERROR = "PASSWORD_VALIATE_ERROR";
export const SET_PASSWORD_SUCCESS = "SET_PASSWORD/SUCCESS";
export const SEND_PASSWORD_FAILURE = "SEND_PASSWORD/FAILURE";

export const FIELDS = {
  password: CHANGE_FIELD + FIELD_PASSWORD,
  password_confirm: CHANGE_FIELD + FIELD_PASSWORD_CONFIRM
};

export function getPasswordFormValues(state) {
  const passwordformValues = {
    password: state.password,
    password_confirm: state.passwordConfirm
  };
  return passwordformValues;
}

export const changePassword = value => {
  return (dispatch, getState) => {
    dispatch(changeField(value.target.name, value.target.value));
    const password = getState().setPassword.password;
    if (value.target.name === "password_confirm")
      dispatch(
        validatePasswordError(validatePassword(password, value.target.value))
      );
  };
};

export const changeField = (field, value) => {
  return { type: FIELDS[field], upload: value };
};

export const validatePassNotMatch = (password1, password2) => {
  let errors = [];
  if (password1 !== password2)
    errors["not_match"] = "password.validate.doNotMatch";
  return errors;
};

export const isPassNotMatch = () => {
  return (dispatch, getState) => {
    const password1 = getState().setPassword.password;
    const password2 = getState().setPassword.password_confirm;
    const errors = validatePassNotMatch(password1, password2);
    dispatch(validatePasswordError(errors));
    return errors.not_match;
  };
};

export function validatePassword(password1, password2) {
  let errors = [];
  if (password1.indexOf(password2) === -1)
    errors["sub_true"] = "password.validate.notASubstring";
  return errors;
}

export const validatePasswordError = errors => {
  return { type: PASSWORD_VALIATE_ERROR, errors };
};

export const setPassword = () => {
  return (dispatch, getState) => {
    const baseUrl = "api/users/set_password";
    const password = getState().setPassword.password;
    axios.defaults.headers = authHeader();
    axios
      .post(baseUrl, JSON.stringify({ password }))
      .then(res => {
        dispatch({ type: SET_PASSWORD_SUCCESS, payload: res.data });
        dispatch(changeField("password", ""));
        dispatch({ type: FIELD_CLEAR });
        history.push("/");
      })
      .catch(error => {
        dispatch({ type: SEND_PASSWORD_FAILURE, payload: error.response });
      });
  };
};
