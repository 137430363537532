import axios from "axios";
import { AUTH_TOKEN } from "../utils/constants";
import { history } from "../index";

export const CHANGE_FIELD = "CHANGE_FIELD/";
export const FIELD_PHONE = "FIELD_PHONE";
export const FIELD_FIRST_NAME = "FIELD_FIRST_NAME";
export const FIELD_LAST_NAME = "FIELD_LAST_NAME";
export const FIELD_CATEGORY = "FIELD_CATEGORY";
export const FIELD_STORE = "FIELD_STORE";
export const FIELD_CLEAR = "FIELD_CLEAR";
export const SIGN_UP_REQUEST_SUCCESS = "SIGN_UP_REQUEST/SUCCESS";
export const SIGN_UP_REQUEST_FAILURE = "SIGN_UP_REQUEST/FAILURE ";

export const FIELDS = {
  first_name: CHANGE_FIELD + FIELD_FIRST_NAME,
  last_name: CHANGE_FIELD + FIELD_LAST_NAME,
  phone: CHANGE_FIELD + FIELD_PHONE,
  store: CHANGE_FIELD + FIELD_STORE,
  category: CHANGE_FIELD + FIELD_CATEGORY
};

export function getFormValues(state) {
  const formValues = {
    first_name: state.firstName,
    last_name: state.lastName,
    email_or_phone: state.phone,
    store: state.store,
    user_category: state.category
  };
  return formValues;
}

export function signUp() {
  return (dispatch, getState) => {
    const formValues = getFormValues(getState().signUp);
    axios
      .post("api/users/sign_up", JSON.stringify(formValues))
      .then(res => {
        let auth = res.data;
        if (auth.token) localStorage.setItem(AUTH_TOKEN, JSON.stringify(auth));
        dispatch({ type: SIGN_UP_REQUEST_SUCCESS, payload: auth });
        dispatch({ type: FIELD_CLEAR });
        history.push("/sms");
      })
      .catch(error => {
        dispatch({ type: SIGN_UP_REQUEST_FAILURE, error: error.response });
      });
  };
}

export const changeSelectCategory = (category, value) => {
  return (dispatch, getState) => {
    dispatch(changeField("category", category.value));
  };
};

export const changeSignUpFields = event => {
  return (dispatch, getState) => {
    dispatch(changeField(event.target.name, event.target.value));
  };
};

export const onChangePhone = value => {
  return (dispatch, getState) => {
    dispatch(changeField("phone", value));
  };
};

export function changeField(field, value) {
  return { type: FIELDS[field], payload: value };
}
