import React from "react";
import { Route, Redirect } from "react-router-dom";
import { AUTH_TOKEN } from "../utils/constants";

export const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      const auth_token = localStorage.getItem(AUTH_TOKEN);
      if (auth_token) return <Component {...props} />;
      else
        return (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        );
    }}
  />
);
