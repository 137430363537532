import axios from "axios";
import { authHeader } from "../utils/auth_headers";
import { open as openTransactionInfoModal } from "../actions/transaction_info_modal";
import { TRANSACTION_INFO_MODAL } from "../actions/transaction_info_modal";

export const REQUES_FULL_RECIPIENTS =
  "PRODUCT_TRANSACTIONS_PAGE/REQUES_FULL_RECIPIENTS";

export const SELECT_RECIPIENT = "PRODUCT_TRANSACTIONS_PAGE/SELECT_RECIPIENT";

export const REQUES_FULL_RECIPIENTS_SUCCESS =
  "PRODUCT_TRANSACTIONS_PAGE/REQUES_FULL_RECIPIENTS_SUCCESS";
export const REQUES_FULL_RECIPIENTS_FAILURE =
  "PRODUCT_TRANSACTIONS_PAGE/REQUES_FULL_RECIPIENTS_FAILURE";

export const REQUES_FULL_PRODUCT_TRANSACTIONS =
  "PRODUCT_TRANSACTIONS_PAGE/REQUES_FULL_PRODUCT_TRANSACTIONS";

export const REQUES_FULL_PRODUCT_TRANSACTIONS_SUCCESS =
  "PRODUCT_TRANSACTIONS_PAGE/REQUES_FULL_PRODUCT_TRANSACTIONS_SUCCESS";

export const REQUES_FULL_PRODUCT_TRANSACTIONS_FAILURE =
  "PRODUCT_TRANSACTIONS_PAGE/REQUES_FULL_PRODUCT_TRANSACTIONS_FAILURE";

export const getFullRecipients = () => {
  return (dispatch, getState) => {
    const url = "api/user/get_full_recipients";
    dispatch({ type: REQUES_FULL_RECIPIENTS });
    axios.defaults.headers = authHeader();
    axios
      .get(url)
      .then(res => {
        dispatch({ type: REQUES_FULL_RECIPIENTS_SUCCESS, user: res.data });
      })
      .catch(error => {});
  };
};

export const getProductTransactions = () => {
  const url = "api/transactions/get";
  return (dispatch, getState) => {
    dispatch({ type: REQUES_FULL_PRODUCT_TRANSACTIONS });
    axios.defaults.headers = authHeader();
    axios
      .get(url)
      .then(res => {
        const { users, products } = res.data;
        dispatch({
          type: REQUES_FULL_PRODUCT_TRANSACTIONS_SUCCESS,
          users,
          products
        });
      })
      .catch(error => {});
  };
};

export const onClickRecipient = (event, nodeKey) => {
  return (dispatch, getState) => {
    dispatch({ type: SELECT_RECIPIENT, selectRecipientName: nodeKey });
    const { users, products } = getState().productTransactionPage;
    const recipient = users.filter(user => user.name === nodeKey)[0] || {};

    const recipientProducts = products.filter(
      product => product.who_the_product_id === recipient.id
    );
    dispatch(openTransactionInfoModal());
    dispatch({ type: TRANSACTION_INFO_MODAL, recipient, recipientProducts });
  };
};
