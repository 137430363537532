import React from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { intl } from "../route/AppRouter";
import { UserInventory } from "./CommonComponents";

export default class InventoryTable extends React.Component {
  rowStyleFormat(row, rowIdx) {
    return {
      backgroundColor: row && row.closed_at ? "" : "#ff4757"
    };
  }

  rowStyleFormatUser(row, rowIdx) {
    return {
      backgroundColor:
        row &&
        row.inventory_items.every(inventory_item => inventory_item.closed_at)
          ? "#00ff00"
          : ""
    };
  }

  formatPreview = (cell, row) => {
    if (!row.closed_at)
      return (
        <button
          className="btn btn-success"
          onClick={e => this.props.getFullInventory(row.id)}
          disabled={this.props.disabledCloseButton}
        >
          {intl("inventory.preview")}
        </button>
      );
    return cell;
  };

  expandComponent(row) {
    return <UserInventory data={row.inventory_items} />;
  }

  render() {
    const selectRow = {
      mode: "checkbox",
      className: "invntory-selection-custom"
    };
    return (
      <div>
        <BootstrapTable
          data={this.props.inventories}
          pagination={true}
          trStyle={this.rowStyleFormat}
          selectRow={selectRow}
          className="table-style-custom"
          deleteRow
          options={{ onDeleteRow: this.props.onAfterDeleteRow }}
        >
          <TableHeaderColumn dataField="id" isKey={true} dataSort={true}>
            {intl("inventory.table.id")}
          </TableHeaderColumn>
          <TableHeaderColumn dataField="name" dataSort={true}>
            {intl("inventory.table.name")}
          </TableHeaderColumn>
          <TableHeaderColumn dataField="created_at">
            {intl("inventory.table.created_at")}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="closed_at"
            dataFormat={this.formatPreview}
          >
            {intl("inventory.table.close_at")}
          </TableHeaderColumn>
        </BootstrapTable>
        {this.props.users.length > 0 && (
          <div>
            <button
              className="btn btn-success close-btn-text"
              onClick={e =>
                this.props.closeOpenInventory(this.props.inventory.id)
              }
              disabled={this.props.disabledCloseButton}
            >
              {intl("inventory.closeBtnText")}
            </button>
            <BootstrapTable
              data={this.props.users}
              expandComponent={this.expandComponent}
              expandableRow={row => true}
              expandColumnOptions={{ expandColumnVisible: true }}
              trStyle={this.rowStyleFormatUser}
            >
              <TableHeaderColumn dataField="id" isKey={true} dataSort={true}>
                {intl("inventory.table.id")}
              </TableHeaderColumn>
              <TableHeaderColumn dataField="first_name" dataSort={true}>
                {intl("inventory.user.firstName")}
              </TableHeaderColumn>
              <TableHeaderColumn dataField="last_name" dataSort={true}>
                {intl("inventory.user.lastName")}
              </TableHeaderColumn>
              <TableHeaderColumn dataField="phone" dataSort={true}>
                {intl("inventory.user.phone")}
              </TableHeaderColumn>
            </BootstrapTable>
          </div>
        )}
      </div>
    );
  }
}
