import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { intl } from "../route/AppRouter";
import PropTypes from "prop-types";

export default class SetPassword extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      isPassNotMatch: PropTypes.func,
      changePassword: PropTypes.func
    })
  };

  hasErrors = () => {
    const { errors } = this.props;
    return errors.sub_true;
  };

  onClick = () => {
    const { actions } = this.props;
    if (!actions.isPassNotMatch()) {
      actions.setPassword();
    }
  };

  renderErrorPassNotMatch = () => {
    const { errors } = this.props;
    return <small className="text-danger">{intl(errors.not_match)}</small>;
  };

  renderPasswordFrom = () => {
    const { password, passwordConfirm } = this.props;
    const actions = this.props.actions;
    return (
      <form>
        <div>
          <label>
            <FormattedMessage
              id="password.passwordText"
              defaultMessage="Пароль:"
            />{" "}
          </label>
          <input
            className="password-form-control"
            name="password"
            type="password"
            value={password}
            onChange={actions.changePassword}
          />
        </div>
        <div>
          <label>
            <FormattedMessage
              id="password.passwordConfirm"
              defaultMessage="Повторите пароль:"
            />{" "}
          </label>
          <input
            className="password-form-control"
            name="password_confirm"
            type="password"
            value={passwordConfirm}
            onChange={actions.changePassword}
          />
        </div>
        {this.hasErrors() && (
          <small className="text-danger">
            <FormattedMessage
              id="password.passwordText"
              defaultMessage="Пароль"
            />
          </small>
        )}
        {this.renderErrorPassNotMatch()}
        <div>
          <Button
            bsStyle="primary"
            className="button-Password"
            bsSize="large"
            disabled={this.hasErrors()}
            onClick={this.onClick}
            block
          >
            <FormattedMessage id="password.buttonText" defaultMessage="Ok" />
          </Button>
        </div>
      </form>
    );
  };

  render() {
    return (
      <div className="sms-form-control">
        <div className="password-center-control">
          <h1 className="text-form-control">
            <FormattedMessage id="password.titleText" defaultMessage="Пароль" />{" "}
          </h1>
          <br />
          {this.renderPasswordFrom()}
        </div>
      </div>
    );
  }
}
