import { connect } from "react-redux";
import {bindActionCreators} from 'redux';
import * as smsConfirmActions from "../actions/comfrimation_sms";
import SMSConfirm from "../components/SMSConfirm";

const mapStateToProps = state => ({
  ...state.confirmSMS
})

function mapDispatchToProps(dispatch){
  return {
    actions: bindActionCreators({
      ...smsConfirmActions
    }, dispatch)
  }
}

  

export default connect(mapStateToProps, mapDispatchToProps)(SMSConfirm);