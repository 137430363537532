import messages from "../language/messages";
import { LOCALE_SET, LANGUAGE } from "../actions/language_locale";

const language = localStorage.getItem(LANGUAGE, "ru");

const initialState = {
  lang: language || 'ru',
  messages: messages
};

export default function locale(state = initialState, actoin) {
  switch (actoin.type) {
    case LOCALE_SET:
      return { ...state, lang: actoin.lang };

    default:
      return state;
  }
}
