import React, { Component } from "react";
import PropTypes from "prop-types";
import { LoaderCustom } from "./CommonComponents";
import { ProductTypeTable } from "../components/CommonComponents";
import UpdateProductTypeModal from "../components/Modals/UpdateProductTypeModal";
import SweetAlert from "react-bootstrap-sweetalert";
import { FormattedMessage } from "react-intl";

class ProductTypeList extends Component {
  static propTypes = {
    isLoad: PropTypes.bool,
    showUpdateModal: PropTypes.bool,
    actions: PropTypes.shape({
      showLoading: PropTypes.func,
      getRecipients: PropTypes.func
    })
  };

  componentDidMount() {
    this.props.actions.showLoading();
  }

  rendarDeleteAlert(show) {
    return (
      <SweetAlert
        show={show}
        warning
        showCancel
        confirmBtnText={
          <FormattedMessage
            id="productTypeList.DeleteAlertConfirmBtnTex"
            defaultMessage="Да, удалить!"
          />
        }
        cancelBtnText={
          <FormattedMessage
            id="productTypeList.DeleteAlertCancelBtnText"
            defaultMessage="Отмена"
          />
        }
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="default"
        title={
          <FormattedMessage
            id="productTypeList.DeleteAlertTitleText"
            defaultMessage="Уверены ли вы?"
          />
        }
        onConfirm={this.props.actions.deleteProductType}
        onCancel={this.props.actions.cancelDelete}
      >
        <FormattedMessage
          id="productTypeList.DeleteAlertText"
          defaultMessage="Вы не сможете восстановить этот воображаемый продукт!"
        />
      </SweetAlert>
    );
  }

  render() {
    const { isLoad } = this.props;
    return (
      <div>
        {!isLoad && <LoaderCustom />}
        {this.rendarDeleteAlert(this.props.showDeleteAlert)}
        <ProductTypeTable
          product_types={this.props.product_types}
          updateProductType={this.props.actions.updateProductType}
          onClickDelete={this.props.actions.onClickDelete}
        />
        <UpdateProductTypeModal
          {...this.props.selected_product_type}
          show={this.props.showUpdateModal}
          onRequestHide={this.props.actions.onRequestHide}
          changeUpdateProudctModal={this.props.actions.changeUpdateProudctModal}
          onUpdate={this.props.actions.onUpdate}
        />
      </div>
    );
  }
}
export default ProductTypeList;
