import {
  CHANGE_FIELD,
  FIELD_PHONE,
  ADD_RECIPIENT_FIALURE,
  ADD_RECIPIENT_SUCCESS,
  SHOW_OK_ALERT,
  SHOW_ADD_RECIPIENT_ALERT,
  SHOW_NOT_ADDED_RECIPIENT_ALERT,
  SHOW_ERROR_ALERT,
  SHOW_USER_ADDED_ALERT,
  SHOW_AUTH_USER_ALERT,
  HIDE_ADD_RECIPIENT_ALERT,
  HIDE_NOT_ADDED_RECIPIENT_ALERT,
  HIDE_ERROR_ALERT,
  HIDE_USER_ADDED,
  HIDE_AUTH_USER,
  FIELD_CLEAR
} from "../actions/add_recipient";

const initialState = {
  phone: "",
  errorAddRecipient: [],
  showOkAlert: false,
  showAddRecipient: false,
  showNotAddRecipient: false,
  showError: false,
  showUserAdded: false,
  showAuthUser: false
};

export default function addRecipient(state = initialState, action) {
  switch (action.type) {
    case CHANGE_FIELD + FIELD_PHONE:
      return { ...state, phone: action.payload };

    case ADD_RECIPIENT_FIALURE:
      return { ...state, errorAddRecipient: action.error };

    case ADD_RECIPIENT_SUCCESS:
      return { ...state, success: action.res };

    case SHOW_OK_ALERT:
      return { ...state, showOkAlert: true };

    case SHOW_ADD_RECIPIENT_ALERT:
      return { ...state, showAddRecipient: true };

    case SHOW_NOT_ADDED_RECIPIENT_ALERT:
      return { ...state, showNotAddRecipient: true };

    case SHOW_ERROR_ALERT:
      return { ...state, showError: true };

    case SHOW_USER_ADDED_ALERT:
      return { ...state, showUserAdded: true };

    case SHOW_AUTH_USER_ALERT:
      return { ...state, showAuthUser: true };

    case HIDE_ADD_RECIPIENT_ALERT:
      return { ...state, showAddRecipient: false };

    case HIDE_NOT_ADDED_RECIPIENT_ALERT:
      return { ...state, showNotAddRecipient: false };

    case HIDE_ERROR_ALERT:
      return { ...state, showError: false };

    case HIDE_USER_ADDED:
      return { ...state, showUserAdded: false };

    case HIDE_AUTH_USER:
      return { ...state, showAuthUser: false };

    case FIELD_CLEAR:
      return { ...initialState };

    default:
      return state;
  }
}
