import {
  CHANGE_FIELD,
  FIELD_PASSWORD,
  FIELD_PHONE,
  FIELD_CLEAR,
  PHONE_VALIATE_ERROR,
  LOGIN_REQUEST_SUCCESS,
  LOGIN_REQUEST_FAILURE
} from "../actions/login";

const initialState = {
  phone: "",
  password: "",
  phoneError: ""
};

export default function login(state = initialState, action) {
  switch (action.type) {
    case CHANGE_FIELD + FIELD_PHONE:
      return { ...state, phone: action.payload };
    case CHANGE_FIELD + FIELD_PASSWORD:
      return { ...state, password: action.payload };

    case PHONE_VALIATE_ERROR:
      return { ...state, phoneError: action.phoneError };

    case LOGIN_REQUEST_SUCCESS:
      return { ...state, payload: action.payload };

    case LOGIN_REQUEST_FAILURE:
      return { ...state, error: action.error };

   case FIELD_CLEAR:
      return { ...initialState }

    default:
      return state;
  }
}
