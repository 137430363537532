import React, { Component } from "react";
import PropTypes from "prop-types";
import NavbarPage from "../containers/NavbarPageContainer";
import { intl } from "../route/AppRouter";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { AddButton } from "./CommonComponents";
import AddProductItem from "../containers/AddProductItemContainer";
import ProductItemInfoModal from "../containers/ProductInfoModalContainer";

class ProductItemsPage extends Component {
  static propTypes = {
    isLoad: PropTypes.bool.isRequired,
    productItems: PropTypes.array.isRequired,
    actions: PropTypes.shape({
      getProductItems: PropTypes.func,
      getInfoProductItem: PropTypes.func
    })
  };

  intl = key => intl(`productItem.${key}`);

  componentDidMount() {
    this.props.actions.getProductItems();
  }

  rowStyleFormat(row, rowIdx) {
    return {
      backgroundColor: row && row.replacement_date ? "#ff4757" : ""
    };
  }

  formatPreview(cell, row) {
    return (
      <button
        className="btn btn-default"
        onClick={e => this.props.actions.openProductInfoModal(row.id)}
      >
        <i className="glyphicon glyphicon-info-sign" />
      </button>
    );
  }

  render() {
    return (
      <div>
        <NavbarPage />
        <div>
          <div className="table-custom-inventory">
            <AddButton
              className="btn btn-primary add-inventory-button"
              onClick={this.props.actions.onRequestShowProductItem}
            />
            <BootstrapTable
              data={this.props.productItems}
              pagination={true}
              className="table-style-custom"
              trStyle={this.rowStyleFormat}
            >
              <TableHeaderColumn dataField="id" isKey={true} dataSort={true}>
                {intl("productItem.table.id")}
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="serial_number"
                dataSort={true}
                filter={{ type: "TextFilter", delay: 500 }}
              >
                {intl("productItem.table.serialNumber")}
              </TableHeaderColumn>
              <TableHeaderColumn dataField="replacement_date" dataSort={true}>
                {intl("productItem.table.replacementDate")}
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="serial_number"
                dataSort={true}
                dataFormat={this.formatPreview.bind(this)}
              >
                {intl("productItem.table.info")}
              </TableHeaderColumn>
            </BootstrapTable>
            <AddProductItem />
            <ProductItemInfoModal />
          </div>
        </div>
      </div>
    );
  }
}

export default ProductItemsPage;
