import React from "react";
import UIModal from "./UIModal";
import { intl } from "../../route/AppRouter";
import PropTypes from "prop-types";
import SweetAlert from "react-bootstrap-sweetalert/lib/dist/SweetAlert";

export default class AddInventoryModal extends React.Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    openDeleteAlert: PropTypes.bool.isRequired,
    actions: PropTypes.shape({
      deleteProductItem: PropTypes.func
    })
  };

  renderSweetAlerts() {
    const { isOpenDeleteAlert, actions, error } = this.props;
    return (
      <div>
        <SweetAlert
          show={isOpenDeleteAlert}
          warning
          showCancel
          confirmBtnText={intl("productTypeList.DeleteAlertConfirmBtnTex")}
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          cancelBtnText={intl("productTypeList.DeleteAlertCancelBtnText")}
          title={intl("productTypeList.DeleteAlertTitleText")}
          onConfirm={actions.deleteProductItem}
          onCancel={actions.closeDeleteAlert}
        >
          {intl("productItem.DeleteAlertText")}
        </SweetAlert>

        <SweetAlert
          show={!!error.message}
          error
          confirmBtnText={intl("OK")}
          cancelBtnBsStyle="danger"
          title={"Ой"}
          onConfirm={actions.onConfirmErrorAlert}
          className="custom-alret"
        >
          {intl(`productErrorAler.${error.message}`)}
        </SweetAlert>
      </div>
    );
  }

  renderBody() {
    const { productItem } = this.props;
    return (
      <div>
        <div className="row product-item-modal-row">
          <div className="col-md-4">
            {intl("productItemInfoModal.body.qrCode")}
          </div>
          <div className="col-md-8">{productItem.qr_code}</div>
        </div>
        <div className="row product-item-modal-row">
          <div className="col-md-4">
            {intl("productItemInfoModal.body.serialNumber")}
          </div>
          <div className="col-md-8">{productItem.serial_number}</div>
        </div>
        <div className="row product-item-modal-row">
          <div className="col-md-4">
            {intl("productItemInfoModal.body.owner")}
          </div>
          <div className="col-md-8">
            {productItem.owner && productItem.owner.first_name}
          </div>
        </div>
        <div className="row product-item-modal-row">
          <div className="col-md-4">
            {intl("productItemInfoModal.body.phone")}
          </div>
          <div className="col-md-8">
            {productItem.owner && productItem.owner.phone}
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { actions } = this.props;
    return (
      <div>
        <UIModal
          title={intl("productItemInfoModal.title")}
          textCancel={intl("addProductItem.uIModalTextCancel")}
          show={this.props.show}
          onRequestHide={actions.close}
          onClickCancel={actions.close}
          onClickOk={actions.onAddInventory}
          disabledOkBtn={this.props.isLoading}
        >
          <button className="btn btn-default" onClick={actions.openDeleteAlert}>
            <i className="glyphicon glyphicon-trash" />
          </button>
          {this.renderBody()}
        </UIModal>
        {this.renderSweetAlerts()}
      </div>
    );
  }
}
