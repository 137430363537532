import React, { Component } from "react";
import { Loader } from "./Loader";
import RecipientItem from "./RecipientItem";
import PropTypes from "prop-types";
import SweetAlert from "react-bootstrap-sweetalert";
import { intl } from ".././route/AppRouter";

class RecipientList extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      showLoading: PropTypes.func,
      getRecipients: PropTypes.func
    })
  };

  renderDeleteRecipientAlert(show) {
    return (
      <SweetAlert
        show={show}
        warning
        showCancel
        confirmBtnText={intl("recipientList.deleteRecipientConfirmBtnText")}
        confirmBtnBsStyle="warning"
        cancelBtnBsStyle="primary"
        title={intl("recipientList.deleteRecipientTitle")}
        onCancel={this.props.actions.cancelDelete}
        onConfirm={this.props.actions.deleteRecipient}
      />
    );
  }

  renderErrorRecipientConnotDeleteAlert(show) {
    return (
      <SweetAlert
        show={show}
        warning
        confirmBtnText={intl(
          "recipientList.errorRecipientConnotDeleteConfirmBtnText"
        )}
        confirmBtnBsStyle="danger"
        title={intl("recipientList.errorRecipientConnotDeleteTitle")}
        onConfirm={this.props.actions.cancelErrorRecipientConnotDelete}
      >
        {intl("recipientList.errorRecipientConnotDeleteText")}
      </SweetAlert>
    );
  }

  renderErrorRecipientPoductDeleteAlert(show) {
    return (
      <SweetAlert
        show={show}
        warning
        confirmBtnText={intl(
          "recipientList.errorRecipientPoductDeleteConfirmBtnText"
        )}
        confirmBtnBsStyle="danger"
        title={intl("recipientList.errorRecipientPoductDeleteTitle")}
        onConfirm={this.props.actions.cancelErrorRecipientHasAProduct}
      >
        {intl("recipientList.errorRecipientPoductDeleteText")}
      </SweetAlert>
    );
  }

  renderRecipientDeletedAlert(show) {
    return (
      <SweetAlert
        show={show}
        success
        confirmBtnText={intl("recipientList.recipientDeletedConfirmBtnText")}
        confirmBtnBsStyle="success"
        title={intl("recipientList.ecipientDeletedTitle")}
        onConfirm={this.props.actions.cancelSuccessDelete}
      />
    );
  }

  renderDeleteRecipientUserNoRecipient(show) {
    return (
      <SweetAlert
        show={show}
        warning
        confirmBtnText={intl(
          "recipientList.errorRecipientConnotDeleteConfirmBtnText"
        )}
        confirmBtnBsStyle="danger"
        title={intl("recipientList.deleteRecipientUserNoRecipientTitle")}
        onConfirm={this.props.actions.cancelUserNoRecipient}
      >
        {intl("recipientList.deleteRecipientUserNoRecipient")}
      </SweetAlert>
    );
  }

  componentDidMount() {
    this.props.actions.showLoading();
    this.props.actions.getRecipients();
  }

  render() {
    const { isLoad, actions, users, filter_users, filterText } = this.props;
    if (!isLoad) {
    }
    return (
      <div>
        <div className="search-recipient">
          <input
            className="form-control"
            placeholder="Поиск..."
            onChange={e => actions.onSearch(e.target.value)}
            value={filterText}
          />
        </div>
        <ul>
          {(filter_users || users).map(user => (
            <div key={user.id}>
              <div className="users-list">
                <RecipientItem
                  user={user}
                  showDeletet={this.props.actions.showDelete}
                />
                {this.renderDeleteRecipientAlert(this.props.showDeleteAlert)}
                {this.renderErrorRecipientConnotDeleteAlert(
                  this.props.showErrorDeleteRecipient
                )}
                {this.renderErrorRecipientPoductDeleteAlert(
                  this.props.showErrorDeleteRecipientProduct
                )}
                {this.renderRecipientDeletedAlert(this.props.showSuccessDelete)}
                {this.renderDeleteRecipientUserNoRecipient(
                  this.props.showUserNoRecipient
                )}
              </div>
            </div>
          ))}
        </ul>
      </div>
    );
  }
}
export default Loader(RecipientList);
