import axios from "axios";
import { authHeader } from "../utils/auth_headers";

export const REQUEST_ALL_INVENTORY = "INVENTORY/REQUEST_ALL_INVENTORY";
export const GET_ALL_INVENTORY_SUCCESS = "INVENTORY/GET_ALL_INVENTORY_SUCCESS";
export const CLOSE_OPEN_INVENTORY_SUCCESS =
  "INVENTORY/CLOSE_OPEN_INVENTORY_SUCCESS";
export const CLOSE_OPEN_INVENTORY_FAILURE =
  "INVENTORY/CLOSE_OPEN_INVENTORY_FAILURE";
export const DELETE_INVENTORY_SUCCESS = "INVENTORY/DELETE_INVENTORY_SUCCESS";
export const GET_FULL_INVENTORY_SUCCESS =
  "INVENTORY/GET_FULL_INVENTORY_SUCCESS";

export const getInventories = () => {
  return (dispatch, getState) => {
    const url = "api/inventory/get_all";
    dispatch({ type: REQUEST_ALL_INVENTORY });
    axios.defaults.headers = authHeader();
    axios
      .get(url)
      .then(res => {
        dispatch({ type: GET_ALL_INVENTORY_SUCCESS, inventories: res.data });
      })
      .catch(error => {});
  };
};

export const closeOpenInventory = inventoryId => {
  return (dispatch, getState) => {
    const url = "api/inventory/close";
    dispatch({ type: REQUEST_ALL_INVENTORY });
    axios.defaults.headers = authHeader();
    axios
      .put(
        url,
        JSON.stringify({
          inventory_id: inventoryId
        })
      )
      .then(res => {
        dispatch({ type: CLOSE_OPEN_INVENTORY_SUCCESS, inventoryId });
      })
      .catch(error => {
        dispatch({ type: CLOSE_OPEN_INVENTORY_FAILURE, error });
      });
  };
};

export const onAfterDeleteRow = inventoryIds => {
  return (dispatch, getState) => {
    const url = "api/inventory/select_delete";
    dispatch({ type: REQUEST_ALL_INVENTORY });
    axios.defaults.headers = authHeader();
    axios({
      method: "DELETE",
      url: url,
      data: { inventory_ids: inventoryIds }
    })
      .then(res => {
        dispatch({ type: DELETE_INVENTORY_SUCCESS, inventoryIds });
      })
      .catch(error => {});
  };
};

export const getFullInventory = (id=48) => {
  return (dispatch, getState) => {
    const url = `api/inventory/get?inventory_id=${id}`;
    dispatch({ type: REQUEST_ALL_INVENTORY });
    axios.defaults.headers = authHeader();
    axios
      .get(url)
      .then(res => {
        dispatch({ type: GET_FULL_INVENTORY_SUCCESS, inventory: res.data });
      })
      .catch(error => {});
  };
};
