import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as loginActions from "../actions/login";
import Login from "../components/Login";
import { showLoading, hideLoading } from "react-redux-loading-bar";

const mapStateToProps = state => ({
  ...state.login
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...loginActions,
        showLoading,
        hideLoading
      },
      dispatch
    )
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
