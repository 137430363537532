import {
  REQUEST_GET_PRODUCT_ITEMS,
  REQUEST_GET_PRODUCT_ITEMS_SUCCESS
} from "../actions/product_items_page";
import { getDate } from "../utils/utils";
import { ADD_PRODUCT_ITEM_REQUEST_SUCCESS } from "../actions/add_product_item";
import { DELETE_PRODUCT_ITEM_SUCCESS } from "../actions/product_item_info_modal";

const initialState = {
  isLoading: false,
  productItems: []
};

export default function productItemsPage(state = initialState, action) {
  switch (action.type) {
    case REQUEST_GET_PRODUCT_ITEMS:
      return { ...state, isLoading: true };

    case REQUEST_GET_PRODUCT_ITEMS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        productItems: action.productItems.map(productItem => {
          if (productItem.replacement_date)
            productItem.replacement_date = getDate(
              new Date(productItem.replacement_date)
            );
          return productItem;
        })
      };

    case ADD_PRODUCT_ITEM_REQUEST_SUCCESS:
      return {
        ...state,
        productItems: [action.productItem, ...state.productItems]
      };

    case DELETE_PRODUCT_ITEM_SUCCESS:
      return {
        ...state,
        productItems: [
          ...state.productItems.filter(
            productItem => productItem.id !== action.productItemId
          )
        ]
      };

    default:
      return state;
  }
}
