import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as productInfo from "../actions/product_info-modal";
import ProductInfo from "../components/Modals/ProductInfo";

const mapStateToProps = state => ({
  ...state.productInfo
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...productInfo
      },
      dispatch
    )
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductInfo);