import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import TransactionInfoModal from "../components/Modals/TransactionInfoModal";
import * as traansactionsInfoModalActions from "../actions/transaction_info_modal";

const mapStateToProps = state => ({
  ...state.transactionInfoModal
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({
        ...traansactionsInfoModalActions
    }, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TransactionInfoModal);
