import {
  REQUES_FULL_RECIPIENTS,
  REQUES_FULL_RECIPIENTS_SUCCESS,
  REQUES_FULL_PRODUCT_TRANSACTIONS_SUCCESS,
  REQUES_FULL_PRODUCT_TRANSACTIONS,
  SELECT_RECIPIENT
} from "../actions/product_transactions";

const initialState = {
  user: {},
  isLoading: false,
  users: [],
  products: [],
  selectRecipientName: ""
};

export default function productTransactionPage(state = initialState, action) {
  switch (action.type) {
    case REQUES_FULL_RECIPIENTS:
      return {
        ...state,
        isLoading: true
      };

    case REQUES_FULL_RECIPIENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: action.user
      };

    case REQUES_FULL_PRODUCT_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        products: action.products,
        users: action.users
      };

      case REQUES_FULL_PRODUCT_TRANSACTIONS:
      return{
        ...state,
        isLoading: true,
      }

      case SELECT_RECIPIENT:
      return {
        ...state,
        selectRecipientName: action.selectRecipientName

      }

    default:
      return state;
  }
}
