import axios from "axios";
import { history } from "../index";
import { USER_LOGIN } from "../utils/constants";
import { authHeader } from "../utils/auth_headers";

export const CHANGE_FIELD = "CHANGE_FIELD/";
export const FIELD_SMS_NUMBER = "FIELD_SMS_NUMBER";
export const SMS_CODE_VALIDATE = "SMS_CODE_VALIDATE";
export const SEND_CONFIRMATION_SMS_SUCCESS = "SEND_CONFIRMATION_SMS/SUCCESS";
export const SEND_CONFIRMATION_SMS_FAILURE = "SEND_CONFIRMATION_SMS/FAILURE";

export const LENGTH_SMS_CODE = 11;

export const changeSmsForm = value => {
  return (dispatch, getState) => {
    dispatch(changeField(value));
    const sms_code = getState().confirmSMS.confirmation_sms_code;
    const errors = validateSmsCode(sms_code);
    dispatch(validateError(errors));
  };
};

export function validateSmsCode(code) {
  let errors = [];
  if (code.length !== LENGTH_SMS_CODE) {
    errors.push("smsCode.error.notCorrectLength");
  }
  return errors;
}

export const changeField = value => {
  return { type: CHANGE_FIELD + FIELD_SMS_NUMBER, upload: value };
};

export const validateError = errors => {
  return { type: SMS_CODE_VALIDATE, errors };
};

export const sendComfirmation = () => {
  return (dispatch, getState) => {
    const baseUrl = "api/users/confirmation_sms";
    const new_sms_code = getSmsCode(getState());
    axios.defaults.headers = authHeader();
    axios
      .post(baseUrl, JSON.stringify(new_sms_code))
      .then(res => {
        let user = res.data;
        localStorage.setItem(USER_LOGIN, JSON.stringify(user));
        dispatch({ type: SEND_CONFIRMATION_SMS_SUCCESS, payload: user });
        history.push("/set_password");
      })
      .catch(error => {
        dispatch({
          type: SEND_CONFIRMATION_SMS_FAILURE,
          error: error.response
        });
      });
  };
};

function getSmsCode(state) {
  const sms_code = state.confirmSMS.confirmation_sms_code;
  const new_sms_code = sms_code.replace(/-/g, "");
  return { confirmation_sms_code: new_sms_code };
}

export const reSentSms = () => {
  return (dispatch, getState) => {
    const baseUrl = "api/users/re_send_sms";
    const sms = { confirmation: true };
    axios.defaults.headers = authHeader();
    axios
      .post(baseUrl, JSON.stringify(sms))
  };
};
