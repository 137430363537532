import { OPEN, CLOSE } from "../actions/qr_code_modal";

const initialState = {
  product: {},
  show: false,
  error: {}
};

export default function qrCodeModal(state = initialState, action) {
  switch (action.type) {
    case OPEN:
      return { ...state, show: true };

    case CLOSE:
      return { ...state, show: false };

    default:
      return state;
  }
}
