import { CHANGE_FIELD, FIELD_PHONE} from '../actions/login';


const initialState = {
   phone: "",
}


export default function change_phone(state = initialState, action) {
   switch (action.type) {
      case CHANGE_FIELD + FIELD_PHONE:
         return {...state, phone: action.payload };

      default:
         return state;
   }
 }
