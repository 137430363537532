import { connect } from "react-redux";
import {bindActionCreators} from 'redux';
import * as setPasswordActions from "../actions/set_password";
import SetPassword from "../components/SetPassword";

const mapStateToProps = state => ({
  ...state.setPassword
})

function mapDispatchToProps(dispatch){
  return {
    actions: bindActionCreators({
      ...setPasswordActions
    }, dispatch)
  }
}

  

export default connect(mapStateToProps, mapDispatchToProps)(SetPassword);