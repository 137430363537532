import {
  OPEN,
  CLOSE,
  CHANGE_FIELD,
  NAME_PRODUCT_TYPE_FIELD,
  DESCRIPTION_PRODUCT_TYPE_FIELD,
  PRICE_PRODUCT_TYPE_FIELD,
  WARRANTY_DAY_PRODUCT_TYPE_FIELD,
  VALIDATE_FEILDS,
  FIELDS_CLEAR,
  ADD_PRODUCT_TYPE_REQUEST_SUCCESS,
  ADD_PRODUCT_TYPE_REQUEST_FAILURE
} from "../actions/add_product_type_modal";

const initialState = {
  show: false,
  error: {},
  description: "",
  name: "",
  price: "",
  warranty_days: ""
};

export default function addProductTypeModal(state = initialState, action) {
  switch (action.type) {
    case OPEN:
      return { ...state, show: true };

    case CLOSE:
      return { ...state, show: false };

    case CHANGE_FIELD + NAME_PRODUCT_TYPE_FIELD:
      return { ...state, name: action.value };

    case CHANGE_FIELD + DESCRIPTION_PRODUCT_TYPE_FIELD:
      return { ...state, description: action.value };

    case CHANGE_FIELD + PRICE_PRODUCT_TYPE_FIELD:
      return { ...state, price: action.value };

    case CHANGE_FIELD + WARRANTY_DAY_PRODUCT_TYPE_FIELD:
      return { ...state, warranty_days: action.value };

    case VALIDATE_FEILDS:
      return { ...state, error: action.error };

    case FIELDS_CLEAR:
      return { ...initialState };

    case ADD_PRODUCT_TYPE_REQUEST_SUCCESS:
      return { ...state, product_type: action.product_type };

    case ADD_PRODUCT_TYPE_REQUEST_FAILURE:
      return { ...state, errors: action.errors };

    default:
      return state;
  }
}
