export function getDate(date) {
  return (
    digits(date.getDate()) +
    "/" +
    digits(date.getMonth() + 1) +
    "/" +
    date.getFullYear() +
    " " +
    digits(date.getHours()) +
    ":" +
    digits(date.getMinutes())
  );
}

export function digits(number) {
  return number > 9 ? number : "0" + number;
}

export function isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
}