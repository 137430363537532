import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ProductItemsPage from "../components/ProductItemsPage";
import * as productItemsPageActions from "../actions/product_items_page";
import { onRequestShow as onRequestShowProductItem } from "../actions/add_product_item";
import { open as openProductInfoModal } from "../actions/product_item_info_modal";

const mapStateToProps = state => ({
  ...state.productItemsPage
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...productItemsPageActions,
        onRequestShowProductItem,
        openProductInfoModal
      },
      dispatch
    )
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductItemsPage);
