import {
  OPEN,
  CLOSE,
  ADD_SERIAL_NUMBER,
  REMOVE_SERIAL_NUMBER,
  INCREMENT_INDEX,
  DECREMENT_INDEX,
  CLEAR_INDEX,
  CHANGE_SERIAL_NUMBER,
  ERROR_DUBLICATE_SERIAL_NUMBER,
  SELECT_PRODUCT_TYPE,
  ERROR_EMPTY_SERIAL_NUMBER,
  CHANGE_QR_PRODUCT_TYPE,
  CHANGE_DESCPRIPTION_PRODUCT_TYPE,
  FIELDS_CLEAR,
  ADD_PRODUCT_REQUEST_FAILURE,
  VALIDE_SERIAL_NUMBER_REQUEST,
  ERROR_VALIDATE_ADD_PRODUCT,
  ERROR_VALIDATE_QR_CODE,
  ADD_PRODUCT_REQUEST_SUCCESS
} from "../actions/add_product_modal";
import { PRODUCT_REQUEST_PRINT } from "../actions/product_list";

const initialState = {
  show: false,
  serial_numbers: [],
  index_serial_number: 0,
  select_product_type: {},
  qr_field: "",
  description: "",
  product: {},
  errors: {
    duplicates: [],
    emptys: [],
    qr_field: "",
    serial_numbers: []
  }
};

export default function addProductModal(state = initialState, action) {
  switch (action.type) {
    case OPEN:
      return { ...state, show: true };

    case CLOSE:
      return { ...state, show: false };

    case ADD_SERIAL_NUMBER:
      return {
        ...state,
        serial_numbers: [
          ...state.serial_numbers,
          {
            index: `serial_number_value_${state.index_serial_number}`,
            value: ""
          }
        ]
      };

    case REMOVE_SERIAL_NUMBER:
      return {
        ...state,
        serial_numbers: [
          ...state.serial_numbers.slice(0, action.index),
          ...state.serial_numbers.slice(action.index + 1)
        ]
      };

    case INCREMENT_INDEX:
      return {
        ...state,
        index_serial_number: state.index_serial_number + 1
      };

    case ADD_PRODUCT_REQUEST_SUCCESS:
      return {
        ...state,
        product: action.product
      };

    case DECREMENT_INDEX:
      return {
        ...state,
        index_serial_number: state.index_serial_number - 1
      };

    case CHANGE_QR_PRODUCT_TYPE:
      return {
        ...state,
        qr_field: action.value
      };

    case CHANGE_DESCPRIPTION_PRODUCT_TYPE:
      return {
        ...state,
        description: action.value
      };
    case CLEAR_INDEX:
      return {
        ...state,
        index_serial_number: 0
      };

    case CHANGE_SERIAL_NUMBER:
      return {
        ...state,
        serial_numbers: state.serial_numbers.map(el => {
          if (el.index === action.serial_number.index)
            el.value = action.serial_number.value;
          return el;
        })
      };

    case ERROR_DUBLICATE_SERIAL_NUMBER:
      return {
        ...state,
        errors: { ...state.errors, duplicates: action.errors }
      };
    case ERROR_VALIDATE_QR_CODE:
      return {
        ...state,
        errors: { ...state.errors, qr_field: action.error }
      };

    case ERROR_VALIDATE_ADD_PRODUCT:
      return {
        ...state,
        errors: {
          ...state.errors,
          qr_field: state.errors.qr_field || action.errors.qr_field,
          select_product_type: action.errors.select_product_type
        }
      };
    case ERROR_EMPTY_SERIAL_NUMBER:
      return {
        ...state,
        errors: { ...state.errors, emptys: action.errors }
      };

    case SELECT_PRODUCT_TYPE:
      return {
        ...state,
        select_product_type: action.product_type
      };

    case FIELDS_CLEAR:
      return {
        ...initialState
      };

    case ADD_PRODUCT_REQUEST_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, qr_field: action.errors }
      };
    case VALIDE_SERIAL_NUMBER_REQUEST:
      return {
        ...state,
        errors: { ...state.errors, serial_numbers: action.errors }
      };

    case PRODUCT_REQUEST_PRINT:
      return { ...state, product: action.product };
    default:
      return state;
  }
}
