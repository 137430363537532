import {
  CHENG_SEARCH_FIELD_FILTER_TEXT,
  SHOW_DELETE_ALERT,
  HIDE_DELETE_ALERT,
  DELETE_PRODUCT,
  PRODUCT_REQUEST_PRINT,
  DELETE_PRODUCT_REQUEST_FAILURE,
  ON_CHANGE_RADIO_FILTER,
  HIDE_ADD_SERIAL_NUMBER_ALERT,
  SHOW_ADD_SERIAL_NUMBER_ALERT
} from "../actions/product_list";
import { ALL_PRODUCTS_RADIO_FILTER } from "../components/ProductList";

const initialState = {
  filterText: "",
  showDeleteAlert: false,
  showAddSerialNumberAlert: false,
  product_select: {},
  error: {},
  products: [],
  radioFilterValue: ALL_PRODUCTS_RADIO_FILTER
};

export default function productList(state = initialState, action) {
  switch (action.type) {
    case CHENG_SEARCH_FIELD_FILTER_TEXT:
      return { ...state, filterText: action.filterText };

    case SHOW_DELETE_ALERT:
      return { ...state, showDeleteAlert: true };

    case HIDE_DELETE_ALERT:
      return { ...state, showDeleteAlert: false };

    case SHOW_ADD_SERIAL_NUMBER_ALERT:
      return { ...state, showAddSerialNumberAlert: true };

    case HIDE_ADD_SERIAL_NUMBER_ALERT:
      return { ...state, showAddSerialNumberAlert: false }

    case DELETE_PRODUCT:
      return { ...state, product_select: action.product };

    case PRODUCT_REQUEST_PRINT:
      return { ...state, product_select: action.product };

    case DELETE_PRODUCT_REQUEST_FAILURE:
      return { ...state, error: action.error };

    case ON_CHANGE_RADIO_FILTER:
      return {
        ...state,
        radioFilterValue: action.name
      };

    default:
      return state;
  }
}
