import axios from "axios";
import { authHeader } from "../utils/auth_headers";
import { hideLoading } from "react-redux-loading-bar";

export const GET_RESPIENTS_REQUEST_SUCCESS = "GET_RESPIENTS_REQUEST_SUCCESS";
export const GET_RESPIENTS_REQUEST_FAILURE = "GET_RESPIENTS_REQUEST_FAILURE";
export const GET_RESPIENTS_REQUEST_IS_LOAD = "GET_RESPIENTS_REQUEST_IS_LOAD";
export const SHOW_DELETE_ALERT = "SHOW_DELETE_ALERT";
export const HIDE_DELETE_ALERT = "HIDE_DELETE_ALERT";
export const DELETE_RECIPIENT = "DELETE_RECIPIENT";
export const RESET_SELECT_RECIPIENT = "RESET_SELECT_RECIPIENT";
export const DELETE_RECIPIENT_REQUEST_SUCCESS =
  "DELETE_RECIPIENT_REQUEST_SUCCESS";
export const RESIPIENT_FAILURE = "RESIPIENT_FAILURE";
export const SHOW_RECIPIENT_DELETE_CANNOT_DELETE_ALERT =
  "SHOW_RECIPIENT_DELETE_CANNOT_DELETE_ALERT";
export const HIDE_RECIPIENT_DELETE_CANNOT_DELETE_ALERT =
  "HIDE_RECIPIENT_DELETE_CANNOT_DELETE_ALERT";
export const SHOW_RECIPIENT_DELETE_HAS_A_PRODUCT_ALERT =
  "SHOW_RECIPIENT_DELETE_HAS_A_PRODUCT_ALERT";
export const HIDE_RECIPIENT_DELETE_HAS_A_PRODUCT_ALERT =
  "HIDE_RECIPIENT_DELETE_HAS_A_PRODUCT_ALERT";
export const SHOW_RECIPIENT_DELETE_SUCCESS_ALERT =
  "SHOW_RECIPIENT_DELETE_SUCCESS_ALERT";
export const HIDE_RECIPIENT_DELETE_SUCCESS_ALERT =
  "HIDE_RECIPIENT_DELETE_SUCCESS_ALERT";
export const SHOW_RECIPIENT_DELETE_USER_NO_RECIPIENT =
  "SHOW_RECIPIENT_DELETE_USER_NO_RECIPIENT";
export const HIDE_RECIPIENT_DELETE_USER_NO_RECIPIENT =
  "HIDE_RECIPIENT_DELETE_USER_NO_RECIPIENT";
export const ON_SEARCH_RECIPIENT = "RECIPIENT_LIST/ON_SEARCH_RECIPIENT";

export function getRecipients() {
  return (dispatch, getState) => {
    const baseUrl = "api/users/get_recipients?is_admin=true";
    axios.defaults.headers = authHeader();
    axios
      .get(baseUrl)
      .then(res => {
        let users = res.data;
        dispatch({
          type: GET_RESPIENTS_REQUEST_IS_LOAD,
          isLoad: true
        });
        dispatch(hideLoading());
        dispatch({
          type: GET_RESPIENTS_REQUEST_SUCCESS,
          users: users
        });
      })
      .catch(error => {
        dispatch(hideLoading());
        dispatch({
          type: GET_RESPIENTS_REQUEST_FAILURE,
          error: error.response
        });
      });
  };
}

export const deleteRecipient = () => {
  return (dispatch, getState) => {
    const baseUrl = "api/users/delete_recipient";
    const user_recipient = getState().recipients.selected_recipient;
    axios.defaults.headers = authHeader();
    axios({
      method: "DELETE",
      url: baseUrl,
      data: { user_recipient_id: user_recipient.id }
    })
      .then(res => {
        dispatch({ type: DELETE_RECIPIENT_REQUEST_SUCCESS, user_recipient });
        dispatch({ type: SHOW_RECIPIENT_DELETE_SUCCESS_ALERT });
        dispatch({ type: HIDE_DELETE_ALERT });
      })
      .catch(error => {
        dispatch({
          type: RESIPIENT_FAILURE,
          error: error.response
        });
        const errors = error.response;
        if (
          errors &&
          errors.data &&
          errors.data.message === "recipient.delete.cannotDelete"
        ) {
          dispatch({ type: SHOW_RECIPIENT_DELETE_CANNOT_DELETE_ALERT });
        }
        if (
          errors &&
          errors.data &&
          errors.data.message === "recipient.delete.hasAProduct"
        ) {
          dispatch({ type: SHOW_RECIPIENT_DELETE_HAS_A_PRODUCT_ALERT });
        }
        if (
          errors &&
          errors.data &&
          errors.data.message === "addRecipient.user.noRecipient"
        ) {
          dispatch({ type: SHOW_RECIPIENT_DELETE_USER_NO_RECIPIENT });
        }
      });
  };
};

export const cancelDelete = () => {
  return (dispatch, getState) => {
    dispatch({ type: HIDE_DELETE_ALERT });
    dispatch({ type: RESET_SELECT_RECIPIENT });
  };
};

export const showDelete = recipient => {
  return (dispatch, getState) => {
    dispatch({ type: SHOW_DELETE_ALERT });
    dispatch({ type: DELETE_RECIPIENT, recipient });
  };
};

export const cancelErrorRecipientConnotDelete = () => {
  return (dispatch, getState) => {
    dispatch({ type: HIDE_RECIPIENT_DELETE_CANNOT_DELETE_ALERT });
    dispatch({ type: HIDE_DELETE_ALERT });
  };
};

export const cancelErrorRecipientHasAProduct = () => {
  return (dispatch, getState) => {
    dispatch({ type: HIDE_RECIPIENT_DELETE_HAS_A_PRODUCT_ALERT });
    dispatch({ type: HIDE_DELETE_ALERT });
  };
};

export const cancelSuccessDelete = () => {
  return (dispatch, getState) => {
    dispatch({ type: HIDE_RECIPIENT_DELETE_SUCCESS_ALERT });
    dispatch({ type: HIDE_DELETE_ALERT });
  };
};

export const cancelUserNoRecipient = () => {
  return (dispatch, getState) => {
    dispatch({ type: HIDE_RECIPIENT_DELETE_USER_NO_RECIPIENT });
    dispatch({ type: HIDE_DELETE_ALERT });
  };
};

export const onSearch = filterText => {
  return {
    type: ON_SEARCH_RECIPIENT,
    filterText
  };
};
