import {
  OPEN,
  CLOSE,
  TRANSACTION_INFO_MODAL
} from "../actions/transaction_info_modal";

const initialState = {
  isOpen: false,
  recipient: {},
  recipientProducts: []
};

export default function transactionInfoModal(state = initialState, action) {
  switch (action.type) {
    case OPEN:
      return {
        ...state,
        isOpen: true
      };
    case CLOSE:
      return {
        ...state,
        isOpen: false
      };

    case TRANSACTION_INFO_MODAL:
      return {
        ...state,
        recipient: action.recipient,
        recipientProducts: action.recipientProducts
      };

    default:
      return state;
  }
}
