import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as qrcodeModalActions from "../actions/qr_code_modal";
import QRcodeModal from "../components/Modals/QRcodeModal";

const mapStateToProps = state => ({
  ...state.qrCodeModal,
  product: state.addProductModal.product
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...qrcodeModalActions
      },
      dispatch
    )
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QRcodeModal);
