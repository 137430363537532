import React from "react";
import UIModal from "./UIModal";
import { intl } from "../../route/AppRouter";

export default class AddProductItem extends React.Component {
  onChange = event => {
      this.props.actions.changeAddProductItem(
        event.target.name,
        event.target.value
      );
    };

  renderErrorAddProductItem =() =>{
    const { error } = this.props;
    if (error && error.data)
      return (<small className="text-danger">{error.data && intl(error.data.message)}</small>);
    else return (<small />);
  }

  renderFromInput = () => {
    return (
      <div>
        <label>{intl("addProductItem.serialNumber")}:</label>
        <input
          className="form-control"
          name="serial_number"
          onChange={this.onChange.bind(this)}
          value={this.props.serial_number}
        />
      </div>
    );
  };
  render() {
    return (
      <UIModal
        title={intl("addProductItem.uIModalTitle")}
        textOk={intl("addProductItem.uIModalTextOK")}
        textCancel={intl("addProductItem.uIModalTextCancel")}
        show={this.props.show}
        onRequestHide={this.props.actions.onRequestHide}
        onClickCancel={this.props.actions.onRequestHide}
        onClickOk={this.props.actions.onSave}
      >
        {this.renderFromInput()}
        {this.renderErrorAddProductItem()}
      </UIModal>
    );
  }
}
