import React from "react";
import { Button, Glyphicon } from "react-bootstrap";
import Loader from "react-loader-spinner";
import { getDate } from "../utils/utils";
import { FormattedMessage } from "react-intl";
import UIModal from "../components/Modals/UIModal";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { intl } from "../route/AppRouter";

export const AddButton = ({ className, ...props }) => (
  <Button {...props} className={`options-control-button ${className}`}>
    <Glyphicon glyph="plus" />
  </Button>
);

export const DeleteButton = ({ className, ...props }) => (
  <Button {...props} className={`options-control-button ${className}`}>
    <Glyphicon glyph="minus" />
  </Button>
);

export const LoaderCustom = () => (
  <div className="spinner">
    <Loader type="Ball-Triangle" color="#0000FF" height="100" width="100" />
  </div>
);

export class ProductRow extends React.Component {
  render() {
    return (
      <tr>
        <td>
          <Button
            onClick={this.props.onRequestShowProductInfo.bind(
              this,
              this.props.product
            )}
            className="button-qr-print"
          >
            {this.props.product.qr_code}
          </Button>
        </td>
        <td>
          {this.props.product.product_type &&
            this.props.product.product_type.name}
        </td>
        <td>{getDate(new Date(this.props.product.date_create))}</td>
        <td>
          <Button
            onClick={this.props.onChangePrint.bind(this, this.props.product)}
          >
            <i className="glyphicon glyphicon-print" />
          </Button>
        </td>
        <td>
          <Button
            disabled={!!this.props.product.who_the_product}
            onClick={this.props.onClickDelete.bind(this, this.props.product)}
          >
            <i className="glyphicon glyphicon-trash" />
          </Button>
        </td>
      </tr>
    );
  }
}

export class ProductTable extends React.Component {
  render() {
    var rows = [];
    this.props.products &&
      this.props.products.forEach(product => {
        if (product.qr_code.indexOf(this.props.filterText) === -1) {
          return;
        }
        rows.push(
          <ProductRow
            product={product}
            key={product.id}
            onChangePrint={this.props.onChangePrint}
            onClickDelete={this.props.onClickDelete}
            onRequestShowProductInfo={this.props.onRequestShowProductInfo}
          />
        );
      });
    return (
      <table className="table">
        <thead>
          <tr>
            <th>
              <FormattedMessage
                id="comComponents.productQrCode"
                defaultMessage="QR код"
              />
            </th>
            <th>
              <FormattedMessage
                id="comComponents.productType"
                defaultMessage="Тип продукт"
              />
            </th>
            <th>
              <FormattedMessage
                id="comComponents.dataCreate"
                defaultMessage="Дата создания"
              />
            </th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    );
  }
}

export class SearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.handleFilterTextInputChange = this.handleFilterTextInputChange.bind(
      this
    );
  }

  handleFilterTextInputChange(e) {
    this.props.onFilterTextInput(e.target.value);
  }

  render() {
    return (
      <form>
        <input
          className="form-control"
          type="text"
          placeholder="Search..."
          value={this.props.filterText}
          onChange={this.handleFilterTextInputChange}
        />
      </form>
    );
  }
}

export const YesNoModal = props => {
  return (
    <div>
      <UIModal
        title={
          <FormattedMessage
            id="comComponents.uIModalTitle"
            defaultMessage="Удалить товар"
          />
        }
        textOk={
          <FormattedMessage
            id="comComponents.uIModalTextOk"
            defaultMessage="Да"
          />
        }
        textCancel={
          <FormattedMessage
            id="comComponents.uIModalTextCancel"
            defaultMessage="Нет"
          />
        }
        show={props.show}
        onRequestHide={props.onRequestHide}
        onClickCancel={props.onRequestHide}
        onClickOk={props.onDelete}
      />
    </div>
  );
};

export class ProductTypeRow extends React.Component {
  render() {
    return (
      <tr>
        <td>{this.props.product_type.name}</td>
        <td>{this.props.product_type.price}</td>
        <td>{this.props.product_type.product_count}</td>
        <td>
          <button
            onClick={this.props.updateProductType.bind(
              this,
              this.props.product_type
            )}
          >
            <i className="glyphicon glyphicon-pencil" />
          </button>
        </td>
        <td>
          <Button
            onClick={this.props.onClickDelete.bind(
              this,
              this.props.product_type
            )}
            disabled={this.props.product_type.product_count > 0}
          >
            <i className="glyphicon glyphicon-trash" />
          </Button>
        </td>
      </tr>
    );
  }
}

export class ProductTypeTable extends React.Component {
  render() {
    var rows = [];
    this.props.product_types &&
      this.props.product_types.forEach(product_type => {
        rows.push(
          <ProductTypeRow
            key={product_type.id}
            product_type={product_type}
            updateProductType={this.props.updateProductType}
            onClickDelete={this.props.onClickDelete}
          />
        );
      });
    return (
      <table className="table">
        <thead>
          <tr>
            <th>
              <FormattedMessage
                id="comComponents.productTypeName"
                defaultMessage="Название"
              />
            </th>
            <th>
              <FormattedMessage
                id="comComponents.productTypePrice"
                defaultMessage="Цена"
              />
            </th>
            <th>
              <FormattedMessage
                id="comComponents.productTypeCound"
                defaultMessage="Подсчитывать"
              />
            </th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    );
  }
}

export class SerialNumberRow extends React.Component {
  render() {
    const { replacement_date } = this.props.productItem;
    let className = "";
    if (replacement_date) className = "bold-serial-number";
    return (
      <tr className={className}>
        <td>{`Серийный номер - ${this.props.order}:`} </td>
        <td>{this.props.productItem.serial_number}</td>
        <td>{replacement_date && getDate(new Date(replacement_date))}</td>
      </tr>
    );
  }
}

export class SerialNumberTable extends React.Component {
  render() {
    const { product_items } = this.props;
    return (
      <table className="table">
        <thead>
          <tr>
            <th>
              <FormattedMessage
                id="comComponents.serialNumber"
                defaultMessage="Серийные номера"
              />
            </th>
            <th>
              <FormattedMessage
                id="comComponents.productItemSerialNumber"
                defaultMessage="Серийные номера"
              />
            </th>
            <th>
              <FormattedMessage
                id="comComponents.productItemReplacementDate"
                defaultMessage="Дата замены"
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {product_items &&
            product_items.map((product_item, index) => (
              <SerialNumberRow order={index} productItem={product_item} />
            ))}
        </tbody>
      </table>
    );
  }
}

export class UserInventory extends React.Component {
  rowStyleFormat(row, rowIdx) {
    return {
      backgroundColor: row && row.closed_at ? "#00ff00" : "#ee5253",
      color: row && row.closed_at ? "#000000" : "#ffffff"
    };
  }
  render() {
    const { data } = this.props;
    const closedData = data.filter(el => el.closed_at);
    if (data) {
      return (
        <div>
          <div className="row custom-count-text">
            <div className="col-md-3">{intl("inventoryItem.count")}</div>
            <div className="col-md-3">{data.length}</div>
            <div className="col-md-3">{intl("inventoryItem.closed.count")}</div>
            <div className="col-md-3">{closedData.length}</div>
          </div>
          <BootstrapTable data={this.props.data} trStyle={this.rowStyleFormat}>
            <TableHeaderColumn dataField="id" isKey={true}>
              {intl("user.inventoryItem.id")}
            </TableHeaderColumn>
            <TableHeaderColumn dataField="qr_code">
              {intl("user.inventoryItem.productQr")}
            </TableHeaderColumn>
            <TableHeaderColumn dataField="closed_at">
              {intl("user.inventoryItem.closedAt")}
            </TableHeaderColumn>
          </BootstrapTable>
        </div>
      );
    } else {
      return <p>?</p>;
    }
  }
}
