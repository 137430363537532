import axios from "axios";
import { authHeader } from "../utils/auth_headers";
import { OPEN as OPEN_QR_CODE } from "../actions/qr_code_modal";
import {
  OPEN as OPEN_PRODUCT_INFO_MODAL,
  PRODUCT_INFO_SUCCESS
} from "../actions/product_info-modal";
import { DELETE_PRODUCT_REQUEST_SUCCESS } from "../actions/product_page";

export const SHOW_DELETE_ALERT = "SHOW_DELETE_ALERT";
export const HIDE_DELETE_ALERT = "HIDE_DELETE_ALERT";
export const CHENG_SEARCH_FIELD_FILTER_TEXT = "CHENG_SEARCH_FIELD_FILTER_TEXT";
export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const PRODUCT_REQUEST_PRINT = "PRODUCT_REQUEST_PRINT";
export const DELETE_PRODUCT_REQUEST_FAILURE = "DELETE_PRODUCT_REQUEST_FAILURE";
export const ON_CHANGE_RADIO_FILTER = "PRODUCT_LIST/ON_CHANGE_RADIO_FILTER";
export const SHOW_ADD_SERIAL_NUMBER_ALERT = "SHOW_ADD_SERIAL_NUMBER_ALERT";
export const HIDE_ADD_SERIAL_NUMBER_ALERT = "HIDE_ADD_SERIAL_NUMBER_ALERT";

export const handleFilterTextInput = filterText => {
  return (dispatch, getState) => {
    dispatch({ type: CHENG_SEARCH_FIELD_FILTER_TEXT, filterText });
  };
};

export const onChangePrint = product => {
  return (dispatch, getState) => {
    const url = `/api/product/qr_code?qr_code=${product.qr_code}`;
    axios.defaults.headers = authHeader();
    axios({
      method: "GET",
      url: url
    })
      .then(res => {
        const product = res.data;
        dispatch({
          type: PRODUCT_REQUEST_PRINT,
          product: product
        });
        dispatch({ type: OPEN_QR_CODE });
      })
      .catch(error => {});
  };
};

export const onChangeRadioFilter = (name, value) => {
  return (dispatch, getState) => {
    dispatch({ type: ON_CHANGE_RADIO_FILTER, name });
  };
};

export const onClickDelete = product => {
  return (dispatch, getState) => {
    dispatch({ type: SHOW_DELETE_ALERT });
    dispatch({ type: DELETE_PRODUCT, product });
  };
};

export const deleteProduct = () => {
  return (dispatch, getState) => {
    const product = getState().productList.product_select;
    axios.defaults.headers = authHeader();
    axios({
      method: "DELETE",
      url: "api/product/delete",
      data: { product_id: product.id }
    })
      .then(res => {
        dispatch({ type: DELETE_PRODUCT_REQUEST_SUCCESS, product });
        dispatch({ type: HIDE_DELETE_ALERT });
      })
      .catch(error => {
        dispatch({
          type: DELETE_PRODUCT_REQUEST_FAILURE,
          error: error.response
        });
        const errors = error.response;
        if (
          errors &&
          errors.data &&
          errors.data.message === "product.owner.alreadySold"
        ) {
          dispatch({ type: SHOW_ADD_SERIAL_NUMBER_ALERT });
          dispatch({ type: HIDE_DELETE_ALERT });
        }
      });
  };
};

export const cancelDelete = () => {
  return (dispatch, getState) => {
    dispatch({ type: HIDE_DELETE_ALERT });
  };
};

export const onRequestShowProductInfo = product => {
  return (dispatch, getState) => {
    const url = `/api/product/qr_code?qr_code=${product.qr_code}`;
    axios.defaults.headers = authHeader();
    axios({
      method: "GET",
      url: url
    })
      .then(res => {
        const product = res.data;
        dispatch({ type: PRODUCT_INFO_SUCCESS, product });
        dispatch({ type: OPEN_PRODUCT_INFO_MODAL });
      })
      .catch(error => {});
  };
};

export const cancelAddSerialNumberAlert = () => {
  return (dispatch, getState) => {
    dispatch({ type: HIDE_ADD_SERIAL_NUMBER_ALERT });
  };
};
