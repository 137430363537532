import axios from "axios";
import { authHeader } from "../utils/auth_headers";

export const OPEN = "PRODUCT_ITEM_INFO_MODAL/OPEN";
export const CLOSE = "PRODUCT_ITEM_INFO_MODAL/CLOSE";
export const OPEN_DELETE_ALERT = "PRODUCT_ITEM_INFO_MODAL/OPEN_DELETE_ALERT";
export const CLOSE_DELETE_ALERT = "PRODUCT_ITEM_INFO_MODAL/CLOSE_DELETE_ALERT";
export const CLOSE_ERROR_ALERT = "PRODUCT_ITEM_INFO_MODAL/CLOSE_ERROR_ALERT";
export const GET_PRODUCT_ITEM_SUCCESS =
  "PRODUCT_ITEM_INFO_MODAL/GET_PRODUCT_ITEM_SUCCESS";
export const SELECT_PRODUCT_ITEM_ID =
  "PRODUCT_ITEM_INFO_MODAL/SELECT_PRODUCT_ITEM_ID";
export const DELETE_PRODUCT_ITEM_REQUEST =
  "PRODUCT_ITEM_INFO_MODAL/DELETE_PRODUCT_ITEM_REQUEST";
export const DELETE_PRODUCT_ITEM_SUCCESS =
  "PRODUCT_ITEM_INFO_MODAL/DELETE_PRODUCT_ITEM_SUCCESS";

export const DELETE_PRODUCT_ITEM_FAILURE =
  "PRODUCT_ITEM_INFO_MODAL/DELETE_PRODUCT_ITEM_FAILURE";

export const open = productItemId => {
  return (dispatch, getState) => {
    dispatch({ type: SELECT_PRODUCT_ITEM_ID, productItemId });
    axios.defaults.headers = authHeader();
    axios({
      method: "GET",
      url: `api/product_item/get?product_item_id=${productItemId}`
    }).then(res => {
      dispatch({ type: OPEN });
      dispatch({ type: GET_PRODUCT_ITEM_SUCCESS, productItem: res.data });
    }).catch(error=>{
      dispatch({ type: GET_PRODUCT_ITEM_SUCCESS, productItem: error.response });
    });
  };
};

export const close = () => {
  return {
    type: CLOSE
  };
};

export const openDeleteAlert = () => {
  return { type: OPEN_DELETE_ALERT };
};

export const closeDeleteAlert = () => {
  return { type: CLOSE_DELETE_ALERT };
};

export const deleteProductItem = () => {
  return (dispatch, getState) => {
    const { productItemId } = getState().productItemInfoModal;
    dispatch({ type: DELETE_PRODUCT_ITEM_REQUEST });
    axios.defaults.headers = authHeader();
    axios({
      method: "DELETE",
      url: "api/product_item/delete",
      data: {
        product_item_id: productItemId
      }
    })
      .then(res => {
        dispatch({ type: DELETE_PRODUCT_ITEM_SUCCESS, productItemId });
      })
      .catch(error => {
        dispatch({
          type: DELETE_PRODUCT_ITEM_FAILURE,
          error: error.response.data
        });
      });
  };
};

export const onConfirmErrorAlert = () => {
  return {
    type: CLOSE_ERROR_ALERT
  };
};
