import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as addProductItem from "../actions/add_product_item";
import AddProductItem from "../components/Modals/AddProductItem";

const mapStateToProps = state => ({
  ...state.addProductItem
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...addProductItem
      },
      dispatch
    )
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddProductItem);
