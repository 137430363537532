import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as productActions from "../actions/product_list";
import ProductList from "../components/ProductList";

const mapStateToProps = state => ({
  ...state.productList,
  products: state.productPage.products
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...productActions,
      },
      dispatch
    )
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductList);
