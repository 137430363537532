import {
  GET_RESPIENTS_REQUEST_SUCCESS,
  GET_RESPIENTS_REQUEST_FAILURE,
  GET_RESPIENTS_REQUEST_IS_LOAD,
  DELETE_RECIPIENT_REQUEST_SUCCESS,
  SHOW_DELETE_ALERT,
  HIDE_DELETE_ALERT,
  RESET_SELECT_RECIPIENT,
  DELETE_RECIPIENT,
  RESIPIENT_FAILURE,
  SHOW_RECIPIENT_DELETE_CANNOT_DELETE_ALERT,
  HIDE_RECIPIENT_DELETE_CANNOT_DELETE_ALERT,
  SHOW_RECIPIENT_DELETE_HAS_A_PRODUCT_ALERT,
  HIDE_RECIPIENT_DELETE_HAS_A_PRODUCT_ALERT,
  SHOW_RECIPIENT_DELETE_SUCCESS_ALERT,
  HIDE_RECIPIENT_DELETE_SUCCESS_ALERT,
  SHOW_RECIPIENT_DELETE_USER_NO_RECIPIENT,
  HIDE_RECIPIENT_DELETE_USER_NO_RECIPIENT,
  ON_SEARCH_RECIPIENT
} from "../actions/recipients";
import {
  USER_PRODUCT_LIST_SUCCESS,
  PRODUCT_ALL_COUNT,
  PRODUCT_SEND_PRODUCT_COUNT,
  PRODUCT_SOLD_PRODUCT_COUNT,
  PRODUCT_UNSOLD_PRODUCT_COUNT,
  USER_PRODUCT_LIST_FAILUER
} from "../actions/recipient_page";

const initialState = {
  users: [],
  filter_users: null,
  isLoad: false,
  error: "",
  showDeleteAlert: false,
  selected_recipient: {},
  errorRecipient: [],
  showErrorDeleteRecipient: false,
  showErrorDeleteRecipientProduct: false,
  showSuccessDelete: false,
  showUserNoRecipient: false,
  allCount: 0,
  sendProductCount: 0,
  soldProductCound: 0,
  unsoldProductCound: 0,
  filterText: ""
};

export default function recipients(state = initialState, action) {
  switch (action.type) {
    case GET_RESPIENTS_REQUEST_SUCCESS:
      return { ...state, users: action.users };

    case GET_RESPIENTS_REQUEST_FAILURE:
      return { ...state, error: action.error };

    case GET_RESPIENTS_REQUEST_IS_LOAD:
      return { ...state, isLoad: action.isLoad };

    case SHOW_DELETE_ALERT:
      return { ...state, showDeleteAlert: true };

    case HIDE_DELETE_ALERT:
      return { ...state, showDeleteAlert: false };

    case RESET_SELECT_RECIPIENT:
      return { ...state, selected_recipient: {} };

    case DELETE_RECIPIENT:
      return { ...state, selected_recipient: action.recipient };

    case DELETE_RECIPIENT_REQUEST_SUCCESS:
      return {
        ...state,
        users: [
          ...state.users.filter(
            user_recipient => user_recipient.id !== action.user_recipient.id
          )
        ]
      };

    case RESIPIENT_FAILURE:
      return { ...state, errorRecipient: action.error };

    case SHOW_RECIPIENT_DELETE_CANNOT_DELETE_ALERT:
      return { ...state, showErrorDeleteRecipient: true };

    case HIDE_RECIPIENT_DELETE_CANNOT_DELETE_ALERT:
      return { ...state, showErrorDeleteRecipient: false };

    case SHOW_RECIPIENT_DELETE_HAS_A_PRODUCT_ALERT:
      return { ...state, showErrorDeleteRecipientProduct: true };

    case HIDE_RECIPIENT_DELETE_HAS_A_PRODUCT_ALERT:
      return { ...state, showErrorDeleteRecipientProduct: false };

    case SHOW_RECIPIENT_DELETE_SUCCESS_ALERT:
      return { ...state, showSuccessDelete: true };

    case HIDE_RECIPIENT_DELETE_SUCCESS_ALERT:
      return { ...state, showSuccessDelete: false };

    case USER_PRODUCT_LIST_SUCCESS:
      return { ...state };

    case PRODUCT_ALL_COUNT:
      return { ...state, allCount: action.all_count };

    case PRODUCT_SEND_PRODUCT_COUNT:
      return { ...state, sendProductCount: action.send_product_count };

    case PRODUCT_SOLD_PRODUCT_COUNT:
      return { ...state, soldProductCound: action.sold_product_count };

    case PRODUCT_UNSOLD_PRODUCT_COUNT:
      return { ...state, unsoldProductCound: action.unsold_product_count };

    case USER_PRODUCT_LIST_FAILUER:
      return {
        ...state,
        allCount: 0,
        sendProductCount: 0,
        soldProductCound: 0,
        unsoldProductCound: 0
      };
    case SHOW_RECIPIENT_DELETE_USER_NO_RECIPIENT:
      return { ...state, showUserNoRecipient: true };

    case HIDE_RECIPIENT_DELETE_USER_NO_RECIPIENT:
      return { ...state, showUserNoRecipient: false };

    case ON_SEARCH_RECIPIENT:
      return {
        ...state,
        filterText: action.filterText,
        filter_users: state.users.filter(user => {
          return `${user.first_name} ${user.last_name}`
            .toLowerCase()
            .includes(action.filterText.toLowerCase());
        })
      };

    default:
      return state;
  }
}
