import React, { Component } from "react";
import ReactPhoneInput from "react-phone-input-2";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import NavbarPage from "../containers/NavbarPageContainer";
import { FormattedMessage } from "react-intl";

const pStyle = {
  width: "100%"
};

export default class AddRecipient extends Component {
  render;

  renderRecipientAdded(show) {
    return (
      <SweetAlert
        show={show}
        success
        confirmBtnText={
          <Link to="/">
            <FormattedMessage
              id="addRecipient.recipientAddedConfirmBtxText"
              defaultMessage="Ok"
            />
          </Link>
        }
        confirmBtnBsStyle="success"
        title={
          <FormattedMessage
            id="addRecipient.recipientAddedTitleText"
            defaultMessage="Пользователь добавлен"
          />
        }
        onConfirm={this.props.actions.clearDialogAddResipient}
      />
    );
  }

  renderAuthUserError(show) {
    return (
      <SweetAlert
        show={show}
        error
        confirmBtnText={
          <FormattedMessage
            id="addRecipient.authUserErrorConfirmBtnTex"
            defaultMessage="Отменить"
          />
        }
        confirmBtnBsStyle="error"
        title={
          <FormattedMessage
            id="addRecipient.authUserErrorTitleText"
            defaultMessage="Требуется авторизация пользователя"
          />
        }
        onConfirm={this.props.actions.cancelAuthUser}
      />
    );
  }

  renderAddRecipientWarning(show) {
    return (
      <SweetAlert
        show={show}
        warning
        showCancel
        confirmBtnText={
          <Link to="/create_child_user">
            <FormattedMessage
              id="addRecipient.addRecipientWarningConfirmBtnText"
              defaultMessage="Создать пользователя"
            />
          </Link>
        }
        confirmBtnBsStyle="warning"
        cancelBtnBsStyle="primary"
        title={
          <FormattedMessage
            id="addRecipient.addRecipientWarningTitleText"
            defaultMessage="Добавить получатель себя"
          />
        }
        onCancel={this.props.actions.cancelAddRecipient}
      />
    );
  }

  renderNotAddedRicipient(show) {
    return (
      <SweetAlert
        show={show}
        warning
        confirmBtnText={
          <FormattedMessage
            id="addRecipient.notAddedRicipientConfirmBtnText"
            defaultMessage="Отменить"
          />
        }
        confirmBtnBsStyle="error"
        title={
          <FormattedMessage
            id="addRecipient.notAddedRicipientTitleText"
            defaultMessage="Не можете добавить себя"
          />
        }
        onConfirm={this.props.actions.cancelNotAddedRicipient}
      />
    );
  }

  renderError(show) {
    return (
      <SweetAlert
        show={show}
        error
        confirmBtnText={
          <FormattedMessage
            id="addRecipient.errorConfirmBtnText"
            defaultMessage="Отменить"
          />
        }
        confirmBtnBsStyle="error"
        title={
          <FormattedMessage
            id="addRecipient.errorTitleTex"
            defaultMessage="Вы не можете добавит! Пользователь высшей категории"
          />
        }
        onConfirm={this.props.actions.cancelError}
      />
    );
  }

  renderUserAdded(show) {
    return (
      <SweetAlert
        show={show}
        warning
        confirmBtnText={
          <FormattedMessage
            id="addRecipient.userAddedConfirmBtnText"
            defaultMessage="Отменить"
          />
        }
        confirmBtnBsStyle="error"
        title={
          <FormattedMessage
            id="addRecipient.userAddedTitleText"
            defaultMessage="Пользователь уже добавлен"
          />
        }
        onConfirm={this.props.actions.cancelUserAdded}
      />
    );
  }

  renderAddRecipient = () => {
    const { phone } = this.props;
    return (
      <form>
        <div>
          <label>
            <FormattedMessage
              id="addRecipient.addRecipientPhoneTitleText"
              defaultMessage="Тел"
            />
            :
          </label>
          <div>
            <ReactPhoneInput
              value={phone}
              defaultCountry={"kg"}
              inputExtraProps={{
                name: "phone",
                required: true,
                autoFocus: true
              }}
              onChange={this.props.actions.changePhone}
              required="true"
              inputStyle={pStyle}
              onlyCountries={["kg", "ru", "kz", "uz"]}
              autoFormat={false}
            />
          </div>
        </div>
      </form>
    );
  };

  renderButton() {
    return (
      <div className="text-form-control">
        <Button
          onClick={this.props.actions.addRecipient}
          bsStyle="primary"
          bsSize="large"
          block
        >
          <FormattedMessage
            id="addRecipient.addRecipientButtonText"
            defaultMessage="Добавить получателя"
          />
        </Button>
        <br />
        <Link to="/" className="text-form-control">
          <FormattedMessage id="addRecipient.linkText" defaultMessage="Назад" />
        </Link>
      </div>
    );
  }

  render() {
    return (
      <div>
        <NavbarPage />
        <div className="addRecipient-form-control">
          <h2 className="text-form-control">
            <FormattedMessage
              id="addRecipient.addRecipientButtonText"
              defaultMessage="Добавить получателя"
            />
          </h2>
          {this.renderAddRecipient()}
          <br />
          {this.renderButton()}
          {this.renderRecipientAdded(this.props.showOkAlert)}
          {this.renderAddRecipientWarning(this.props.showAddRecipient)}
          {this.renderNotAddedRicipient(this.props.showNotAddRecipient)}
          {this.renderError(this.props.showError)}
          {this.renderUserAdded(this.props.showUserAdded)}
          {this.renderAuthUserError(this.props.showAuthUser)}
        </div>
      </div>
    );
  }
}
