import {
  CHANGE_FIELD,
  FIELD_SMS_NUMBER,
  SMS_CODE_VALIDATE,
  SEND_CONFIRMATION_SMS_SUCCESS,
  SEND_CONFIRMATION_SMS_FAILURE
} from "../actions/comfrimation_sms";
import { USER_LOGIN } from "../utils/constants";

const initialState = {
  confirmation_sms_code: "",
  errors: ["begin"],
  errorConfirm: [],
  user: JSON.parse(localStorage.getItem(USER_LOGIN))
};

export default function confirmSMS(state = initialState, action) {
  switch (action.type) {
    case CHANGE_FIELD + FIELD_SMS_NUMBER:
      return { ...state, confirmation_sms_code: action.upload };

    case SMS_CODE_VALIDATE:
      return { ...state, errors: action.errors };

    case SEND_CONFIRMATION_SMS_SUCCESS:
      return { ...state, user: action.payload };
    case SEND_CONFIRMATION_SMS_FAILURE:
      return { ...state, errorConfirm: action.error };
    default:
      return state;
  }
}
