import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as addRecipientActions from "../actions/add_recipient";
import AddRecipient from "../components/AddRecipient";

const mapStateToProps = state => ({
  ...state.addRecipient
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...addRecipientActions
      },
      dispatch
    )
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddRecipient);
