import axios from "axios";
import { authHeader } from "../utils/auth_headers";
import { history } from "../index";

export const OPEN = "OPEN";
export const CLOSE = "CLOSE";
export const CHENGE_PRODUCT_ITEM = "CHENGE_PRODUCT_ITEM";
export const ADD_PRODUCT_ITEM_REQUEST_SUCCESS =
  "ADD_PRODUCT_ITEM_REQUEST_SUCCESS";
export const ADD_PRODUCT_ITEM_REQUEST_FAILURE =
  "ADD_PRODUCT_ITEM_REQUEST_FAILURE";
export const FIELDS_CLEAR = "FIELDS_CLEAR";

export const onRequestHide = () => {
  return (dispatch, getState) => {
    dispatch({ type: CLOSE });
    dispatch({ type: FIELDS_CLEAR });
  };
};

export const onRequestShow = () => {
  return (dispatch, getState) => {
    dispatch({ type: OPEN });
  };
};

export const changeAddProductItem = (name, value) => {
  return (dispatch, getState) => {
    dispatch({ type: CHENGE_PRODUCT_ITEM, value });
  };
};

export const onSave = () => {
  return (dispatch, getState) => {
    const serial_number = getState().addProductItem;
    const baseUrl = "api/product/product_item/create";
    axios.defaults.headers = authHeader();
    axios
      .post(
        baseUrl,
        JSON.stringify({ serial_number: serial_number.serial_number })
      )
      .then(res => {
        dispatch({
          type: ADD_PRODUCT_ITEM_REQUEST_SUCCESS,
          productItem: res.data
        });
        dispatch({ type: CLOSE });
        dispatch({ type: FIELDS_CLEAR });
      })
      .catch(error => {
        dispatch({
          type: ADD_PRODUCT_ITEM_REQUEST_FAILURE,
          error: error.response
        });
      });
  };
};

export const goToProductItems = () => {
  history.push("/product_items");
};
