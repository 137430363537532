import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ProductTransactionPage from "../components/ProductTransactionPage";
import * as productTransactionsActions from "../actions/product_transactions";
import { open as openTransactionInfoModal } from "../actions/transaction_info_modal";

const mapStateToProps = state => ({
  ...state.productTransactionPage
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...productTransactionsActions,
        openTransactionInfoModal
      },
      dispatch
    )
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductTransactionPage);
