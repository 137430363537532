export const AUTH_TOKEN = "auth";
export const USER_LOGIN = "user";

export const SUPPLIER = "UC1";
export const DISTRIBUTOR = "UC2";
export const WHOLESALER = "UC3";
export const SHOP_SELLER = "UC4";

export const OPTIONS_USER_CATEGORY = [
  { label: "Производитель", value: SUPPLIER },
  { label: "Дистрибютор", value: DISTRIBUTOR},
  { label: "Диллер-оптовик", value: WHOLESALER },
  { label: "Магазин-продавец", value: SHOP_SELLER }
];


export const OPRIONS_RECIPIENT_CATEGORY = {
   [SUPPLIER]:  [
      { label: "Дистрибютор", value: DISTRIBUTOR},
      { label: "Диллер-оптовик", value: WHOLESALER },
      { label: "Магазин-продавец", value: SHOP_SELLER }
    ],
    [DISTRIBUTOR]:  [
      { label: "Диллер-оптовик", value: WHOLESALER },
      { label: "Магазин-продавец", value: SHOP_SELLER }
    ],
    [WHOLESALER]:  [
      { label: "Магазин-продавец", value: SHOP_SELLER }
    ],
    [SHOP_SELLER]: []
}

