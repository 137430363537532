import { AUTH_TOKEN } from "./constants";

export function authHeader() {
  let auth = JSON.parse(localStorage.getItem(AUTH_TOKEN));
  if (auth && auth.token) {
    return { Authorization: auth.token };
  } else {
    return {};
  }
}

export const getAuthToken = () => {
  const auth = JSON.parse(localStorage.getItem(AUTH_TOKEN));
  if (auth) return auth.token;
  else return "";
};
