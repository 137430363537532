import React, { Component } from 'react';
import { intl } from '../route/AppRouter';


export default class NoMatch extends Component {
   render () {
      return (
         <div className="sms-form-control">
            <div className="sms-center-form" >
               <h1 className="text-form-control">404</h1><br/>
               <h2 className="text-form-control">{intl("noMatch.notFound")}</h2>
            </div>
         </div>
      );
   }
}
