import React from "react";
import {
  Navbar,
  Nav,
  NavItem,
  MenuItem,
  NavDropdown,
  Glyphicon
} from "react-bootstrap";
import PropTypes from "prop-types";
import { SHOP_SELLER } from "../utils/constants";
import { FormattedMessage } from "react-intl";
import { intl } from "../route/AppRouter";

class NavbarPage extends React.Component {
  static propTypes = {
    actions: PropTypes.shape({
      goToSetPassPage: PropTypes.func,
      logout: PropTypes.func,
      goToCreateUserPage: PropTypes.func,
      goToAddRecipientPage: PropTypes.func
    })
  };

  render() {
    const navDropdownTitle = <Glyphicon glyph="glyphicon glyphicon-user" />;
    return (
      <Navbar>
        <Navbar.Header>
          <Navbar.Brand>
            <a href="/">
              <i className="glyphicon glyphicon-home" />
            </a>
          </Navbar.Brand>
          <Navbar.Toggle />
        </Navbar.Header>
        <Navbar.Collapse>
          <Nav>
            <NavItem eventKey={1} href="/product">
              <FormattedMessage
                id="navBarPage.productText"
                defaultMessage="Продукт"
              />
            </NavItem>
            <NavItem eventKey={2} href="/inventories">
              <FormattedMessage
                id="navBarPage.inventoryText"
                defaultMessage="Инвентаризация"
              />
            </NavItem>
          </Nav>
          <Nav pullRight>
            <NavDropdown
              eventKey={3}
              id="basic-nav-dropdown"
              title={navDropdownTitle}
            >
              <MenuItem
                eventKey={3.2}
                onClick={this.props.actions.goToAddRecipientPage}
              >
                <FormattedMessage
                  id="navBarPage.addRecipientText"
                  defaultMessage="Добавить получателя"
                />
              </MenuItem>
              <MenuItem
                eventKey={3.1}
                disabled={this.props.user.user_category === SHOP_SELLER}
                onClick={
                  this.props.user.user_category === SHOP_SELLER
                    ? () => {}
                    : this.props.actions.goToCreateUserPage
                }
              >
                <FormattedMessage
                  id="navBarPage.createRecipientText"
                  defaultMessage="Создать получателя"
                />
              </MenuItem>
              <MenuItem
                eventKey={3.3}
                onClick={this.props.actions.goToSetPassPage}
              >
                <FormattedMessage
                  id="navBarPage.setPasswordText"
                  defaultMessage="Установить пароль"
                />
              </MenuItem>
              <MenuItem>
                <a
                  role="button"
                  onClick={e => this.props.actions.setLocale("ru")}
                >
                  Русский{" "}
                </a>
                |
                <a
                  role="button"
                  onClick={e => this.props.actions.setLocale("ky")}
                >
                  {" "}
                  Кыргызча
                </a>
              </MenuItem>
              <MenuItem divider />
              <MenuItem eventKey={3.3} onClick={this.props.actions.logout}>
                <FormattedMessage
                  id="navBarPage.logoutText"
                  defaultMessage="Выйти"
                />
              </MenuItem>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default NavbarPage;
