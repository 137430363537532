import axios from "axios";
import { authHeader } from "../utils/auth_headers";

export const OPEN = "ADD_INVENTORY_MODAL/OPEN";
export const CLOSE = "ADD_INVENTORY_MODAL/CLOSE";
export const ERROR_ALERT_CONFIRM = "ADD_INVENTORY_MODAL/ERROR_ALERT_CONFIRM";
export const ON_NAME_CHANGE = "ADD_INVENTORY_MODAL/ON_NAME_CHANGE";
export const ON_CHANGE_DESCRIPTION =
  "ADD_INVENTORY_MODAL/ON_CHANGE_DESCRIPTION";
export const REQUEST_ADD_INVENTORY =
  "ADD_INVENTORY_MODAL/REQUEST_ADD_INVENTORY";
export const ADD_INVENTORY_SUCCESS =
  "ADD_INVENTORY_MODAL/ADD_INVENTORY_SUCCESS";
export const ADD_INVENTORY_FAILURE =
  "ADD_INVENTORY_MODAL/ADD_INVENTORY_FAILURE";

export const open = () => {
  return {
    type: OPEN
  };
};

export const close = () => {
  return {
    type: CLOSE
  };
};

export const onNameChange = value => {
  return {
    type: ON_NAME_CHANGE,
    value
  };
};

export const handleChangeDescription = value => {
  return {
    type: ON_CHANGE_DESCRIPTION,
    value
  };
};

export const onAddInventory = () => {
  return (dispatch, getState) => {
    const url = "api/inventory/create";
    const { name, description } = getState().addInventoryModal;

    dispatch({ type: REQUEST_ADD_INVENTORY });

    axios.defaults.headers = authHeader();
    axios
      .post(
        url,
        JSON.stringify({
          name: name,
          description: description
        })
      )
      .then(res => {
        dispatch({ type: CLOSE });
        dispatch({ type: ADD_INVENTORY_SUCCESS, inventory: res.data });
      })
      .catch(error => {
        dispatch({ type: ADD_INVENTORY_FAILURE, error: error.response.data });
      });
  };
};

export const onConfirmErrorAlert = () => {
  return {
    type: ERROR_ALERT_CONFIRM
  };
};
