import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import NavbarPage from "../containers/NavbarPageContainer";
import AddProductModal from "../containers/AddProductModalContainer";
import AddProductTypeModal from "../containers/AddProductTypeModalContainer";
import QRcodeModal from "../containers/QRcodeModalContainer";
import ProductTypeList from "../containers/ProductTypeListContainer";
import ProductList from "../containers/ProductListConatainer";
import ProductInfo from "../containers/ProductInfoContainer";
import { FormattedMessage } from "react-intl";

class ProductPage extends Component {
  static propTypes = {
    isLoad: PropTypes.bool.isRequired,
    product_types: PropTypes.array.isRequired,
    actions: PropTypes.shape({
      getProudctTypes: PropTypes.func,
      onRequestHideProduct: PropTypes.func,
      onRequestShow: PropTypes.func,
      onRequestHide: PropTypes.func,
      onSave: PropTypes.func
    })
  };

  componentDidMount() {
    this.props.actions.showLoading();
    this.props.actions.getProudctTypes();
    this.props.actions.getProudcts();
  }
  render() {
    return (
      <div>
        <NavbarPage />
        <div className="container">
          <div className="row">
            <Button
              bsStyle="primary"
              className="col col-xs-4"
              onClick={this.props.actions.onRequestShowProductType}
            >
              <FormattedMessage
                id="productPage.productTypeButtonText"
                defaultMessage="Добавить тип продукта"
              />
            </Button>
            <Button
              bsStyle="primary"
              className="col col-xs-4"
              onClick={this.props.actions.onRequestShowProduct}
            >
              <FormattedMessage
                id="productPage.productButtonText"
                defaultMessage="Добавить продукт"
              />
            </Button>
            <Button
              bsStyle="primary"
              className="col col-xs-4"
              onClick={this.props.actions.goToProductItems}
            >
              <FormattedMessage
                id="productPage.productAddProductItemButtonText"
                defaultMessage="Добавить серийный номер"
              />
            </Button>
          </div>
        </div>
        <AddProductModal />
        <AddProductTypeModal />
        <ProductInfo />
        <QRcodeModal />
        <div>
          <div className="col col-xs-5">
            <ProductTypeList />
          </div>
          <div className="col col-xs-7">
            <ProductList />
          </div>
        </div>
      </div>
    );
  }
}

export default ProductPage;
