import {
  GET_USER_REQUEST_SUCCESS,
  GET_USER_REQUEST_FAILURE,
  GET_USER_REQUEST_IS_LOAD
} from "../actions/page_user";
import { USER_LOGIN } from "../utils/constants";

const initialState = {
  user: JSON.parse(localStorage.getItem(USER_LOGIN)) || {},
  isLoad: false
};

export default function page_user(state = initialState, action) {
  switch (action.type) {
    case GET_USER_REQUEST_SUCCESS:
      return { ...state, payload: action.payload };

    case GET_USER_REQUEST_FAILURE:
      return { ...state, error: action.error };

    case GET_USER_REQUEST_IS_LOAD:
      return { ...state, isLoad: true };
    default:
      return state;
  }
}
