import axios from "axios";
import { authHeader } from "../utils/auth_headers";

export const OPEN = "ADD_PRODUCT_TYPE_MODAL/OPEN";
export const CLOSE = "ADD_PRODUCT_TYPE_MODAL/CLOSE";
export const CHANGE_FIELD = "CHANGE_FIELD/";
export const NAME_PRODUCT_TYPE_FIELD = "NAME_PRODUCT_TYPE_FIELD";
export const DESCRIPTION_PRODUCT_TYPE_FIELD = "DESCRIPTION_PRODUCT_TYPE_FIELD";
export const PRICE_PRODUCT_TYPE_FIELD = "PRICE_PRODUCT_TYPE_FIELD";
export const WARRANTY_DAY_PRODUCT_TYPE_FIELD =
  "WARRANTY_DAY_PRODUCT_TYPE_FIELD";
export const VALIDATE_FEILDS = "PRODUCT_TYPE/VALIDATE_FEILDS";
export const ADD_PRODUCT_TYPE_REQUEST_SUCCESS =
  "ADD_PRODUCT_TYPE_REQUEST_SUCCESS";

export const ADD_PRODUCT_TYPE_REQUEST_FAILURE =
  "ADD_PRODUCT_TYPE_REQUEST_FAILURE";

export const FIELDS_CLEAR = "PRODUCT_TYPE/FIELDS_CLEAR";

export function isEmpty(obj) {
  for (var prop in obj) {
    if (obj.hasOwnProperty(prop)) return false;
  }

  return JSON.stringify(obj) === JSON.stringify({});
}

export const FIELDS = {
  name: CHANGE_FIELD + NAME_PRODUCT_TYPE_FIELD,
  description: CHANGE_FIELD + DESCRIPTION_PRODUCT_TYPE_FIELD,
  price: CHANGE_FIELD + PRICE_PRODUCT_TYPE_FIELD,
  warranty_days: CHANGE_FIELD + WARRANTY_DAY_PRODUCT_TYPE_FIELD
};

export function changeField(field, value) {
  return { type: FIELDS[field], value: value };
}

export const changeAddProudctModal = (name, value) => {
  return (dispatch, getState) => {
    dispatch(changeField(name, value));
  };
};

export const onRequestHide = () => {
  return (dispatch, getState) => {
    dispatch({ type: CLOSE });
  };
};

export const onRequestShow = () => {
  return (dispatch, getState) => {
    dispatch({ type: OPEN });
  };
};

export const validateFields = product_type => {
  const error = {};
  if (!product_type.name) {
    error["name"] = "add_product_type.isRequired";
  }

  if (!product_type.price) {
    error["price"] = "add_product_type.isRequired";
  }

  if (!product_type.warranty_days) {
    error["warranty_days"] = "add_product_type.isRequired";
  }

  return error;
};

export const onSave = () => {
  return (dispatch, getState) => {
    const product_type = getState().addProductTypeModal;
    const error = validateFields(product_type);

    if (isEmpty(error)) {
      axios.defaults.headers = authHeader();
      axios
        .post(
          "api/product_type/create",
          JSON.stringify({
            name: product_type.name,
            description: product_type.description,
            price: product_type.price,
            warranty_days: product_type.warranty_days
          })
        )
        .then(res => {
          let product_type = res.data;
          product_type.label = product_type.name;
          product_type.value = product_type.id;
          dispatch({
            type: ADD_PRODUCT_TYPE_REQUEST_SUCCESS,
            product_type: product_type
          });
          dispatch({ type: CLOSE });
          dispatch({ type: FIELDS_CLEAR });
        })
        .catch(error => {
          dispatch({
            type: ADD_PRODUCT_TYPE_REQUEST_FAILURE,
            errors: error.response
          });
        });
    } else {
      dispatch({ type: VALIDATE_FEILDS, error });
    }
  };
};
