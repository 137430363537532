import {OPEN, CLOSE, PRODUCT_INFO_SUCCESS, FIELDS_CLEAR } from "../actions/product_info-modal";

const initialState = {
  show: false,
  product: {
    product_items: [],
    product_type: {},
    final_buyer: {},
    who_the_product: {}
  }
};

export default function productInfo(state = initialState, action) {
  switch (action.type) {
    case OPEN:
      return { ...state, show: true };

    case CLOSE:
      return { ...state, show: false };

    case PRODUCT_INFO_SUCCESS:
      return { ...state, product: action.product };

    case FIELDS_CLEAR:
      return { ...initialState }

      default:
        return state;
  }
}
