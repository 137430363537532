import React, { Component } from "react";
import ReactPhoneInput from "react-phone-input-2";
import CreatableSelect from "react-select/lib/Creatable";
import NavbarPage from "../containers/NavbarPageContainer";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { intl } from "../route/AppRouter";

const pStyle = {
  width: "100%"
};

export default class CreateChildUser extends Component {
  renderCreateChildUser = () => {
    const { firstName, lastName, phone, store } = this.props;
    const actions = this.props.actions;
    return (
      <form>
        <div>
          <label>
            <FormattedMessage
              id="createChildUser.lastNameText"
              defaultMessage="Фамилия"
            />{" "}
            :
          </label>
          <input
            className="select-control"
            name="last_name"
            type="text"
            value={lastName}
            onChange={actions.changeInputFields}
            required={true}
          />
        </div>
        <div>
          <label>
            <FormattedMessage
              id="createChildUser.firstNameText"
              defaultMessage="Имя"
            />
            :
          </label>
          <input
            className="select-control"
            name="first_name"
            type="text"
            value={firstName}
            onChange={actions.changeInputFields}
          />
        </div>
        <div>
          <label>
            <FormattedMessage
              id="createChildUser.storeText"
              defaultMessage="Торговый точка"
            />
            :
          </label>
          <input
            className="select-control"
            name="store"
            type="text"
            value={store}
            onChange={actions.changeInputFields}
            required={true}
          />
        </div>
        <div>
          <label>
            <FormattedMessage
              id="createChildUser.phoneText"
              defaultMessage="Тел"
            />
            :
          </label>
          <div>
            <ReactPhoneInput
              value={phone}
              defaultCountry={"kg"}
              inputExtraProps={{
                name: "phone",
                required: true,
                autoFocus: true
              }}
              onChange={actions.changePhone}
              required="true"
              inputStyle={pStyle}
              onlyCountries={["kg", "ru", "kz", "uz"]}
              autoFormat={false}
            />
          </div>
        </div>
      </form>
    );
  };

  renderError = () => {
    const { error } = this.props;
    if (error && error.data)
      return <small className="text-danger">{error.data && intl(error.data.message)}</small>;
    else return <small />;
  }

  renderCategory = () => {
    return (
      <div>
        <label>
          <FormattedMessage
            id="createChildUser.categoryText"
            defaultMessage="Категория"
          />
          :
        </label>
        <div>
          <CreatableSelect
            name="category"
            options={this.props.options}
            placeholder={
              <FormattedMessage
                id="createChildUser.categoryPlaceholderText"
                defaultMessage="Выберите категории"
              />
            }
            onChange={this.props.actions.changeSelectCategory}
          />
        </div>
      </div>
    );
  };

  render() {
    return (
      <div>
        <NavbarPage />
        <div className="addRecipientForm">
          <h2 className="text-form-control">
            <FormattedMessage
              id="createChildUser.titleText"
              defaultMessage="Создать дочернего пользователя"
            />
          </h2>
          {this.renderCreateChildUser()}
          {this.renderError()}
          {this.renderCategory()} <br />
          <div>
            <Button
              onClick={this.props.actions.createChildUser}
              bsStyle="primary"
              bsSize="large"
              block
            >
              <FormattedMessage
                id="createChildUser.buttonText"
                defaultMessage="Зарегистрировать"
              />
            </Button>
          </div>
          <Link to="/" className="btn btn-link">
            <FormattedMessage
              id="createChildUser.linkText"
              defaultMessage="Назад"
            />
          </Link>
        </div>
      </div>
    );
  }
}
