import {
  OPEN,
  CLOSE,
  CHENGE_PRODUCT_ITEM,
  ADD_PRODUCT_ITEM_REQUEST_FAILURE,
  FIELDS_CLEAR
} from "../actions/add_product_item";

const initialState = {
  show: false,
  serial_number: "",
};

export default function addProductItem(state = initialState, action) {
  switch (action.type) {
    case OPEN:
      return { ...state, show: true };

    case CLOSE:
      return { ...state, show: false };

    case CHENGE_PRODUCT_ITEM:
      return { ...state, serial_number: action.value };

    case ADD_PRODUCT_ITEM_REQUEST_FAILURE:
      return { ...state, error: action.error };

    case FIELDS_CLEAR:
      return { ...initialState };

    default:
      return state;
  }
}
