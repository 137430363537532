import axios from "axios";
import { authHeader } from "../utils/auth_headers";

export const CHANGE_FIELD = "CHANGE_FIELD/";
export const FIELD_PHONE = "FIELD_PHONE";
export const SHOW_OK_ALERT = "SHOW_OK_ALERT";
export const SHOW_ADD_RECIPIENT_ALERT = "SHOW_ADD_RECIPIENT_ALERT";
export const SHOW_NOT_ADDED_RECIPIENT_ALERT = "SHOW_NOT_ADDED_RECIPIENT_ALERT";
export const SHOW_ERROR_ALERT = "SHOW_ERROR_ALERT";
export const SHOW_USER_ADDED_ALERT = "SHOW_USER_ADDED_ALERT";
export const SHOW_AUTH_USER_ALERT = "SHOW_AUTH_USER_ALERT";
export const HIDE_ADD_RECIPIENT_ALERT = "HIDE_ADD_RECIPIENT_ALERT";
export const HIDE_NOT_ADDED_RECIPIENT_ALERT = "HIDE_NOT_ADDED_RECIPIENT_ALERT";
export const HIDE_ERROR_ALERT = "HIDE_ERROR_ALERT";
export const HIDE_USER_ADDED = "HIDE_USER_ADDED";
export const HIDE_AUTH_USER = "HIDE_AUTH_USER";
export const FIELD_CLEAR = "FIELD_CLEAR";
export const ADD_RECIPIENT_SUCCESS = "ADD_RECIPIENT/SUCCESS";
export const ADD_RECIPIENT_FIALURE = "ADD_RECIPIENT/FIALURE";

export const FIELDS = {
  phone: CHANGE_FIELD + FIELD_PHONE
};

export function getFormValues(state) {
  const formValues = {
    user_recipient_phone: state.phone
  };
  return formValues;
}

export const changePhone = value => {
  return (dispatch, getState) => {
    dispatch(changeField("phone", value));
  };
};

export function changeField(field, value) {
  return { type: FIELDS[field], payload: value };
}

export function addRecipient() {
  return (dispatch, getState) => {
    const baseUrl = "api/users/add_recipient";
    const formValues = getFormValues(getState().addRecipient);
    axios.defaults.headers = authHeader();
    axios
      .post(baseUrl, JSON.stringify(formValues))
      .then(res => {
        dispatch({
          type: ADD_RECIPIENT_SUCCESS,
          res: res.data
        });
        const success = res;
        if (success && success.data && success.data.access === true) {
          dispatch({ type: SHOW_OK_ALERT });
        }
      })
      .catch(error => {
        dispatch({
          type: ADD_RECIPIENT_FIALURE,
          error: error.response
        });
        const errors = error.response;
        if (
          errors &&
          errors.data &&
          errors.data.message === "auth.user.notFound"
        ) {
          dispatch({ type: SHOW_ADD_RECIPIENT_ALERT });
        }
        if (
          errors &&
          errors.data &&
          errors.data.message === "addRecipient.user.canNotAddOnHimself"
        ) {
          dispatch({ type: SHOW_NOT_ADDED_RECIPIENT_ALERT });
        }
        if (
          errors &&
          errors.data &&
          errors.data.message === "auth.user.cannotCreateUserCategory"
        ) {
          dispatch({ type: SHOW_ERROR_ALERT });
        }
        if (
          errors &&
          errors.data &&
          errors.data.message === "addRecipient.user.UserHasAlreadyBeenAdded"
        ) {
          dispatch({ type: SHOW_USER_ADDED_ALERT });
        }
        if (
          errors &&
          errors.data &&
          errors.data.message === "auth.user.required"
        ) {
          dispatch({ type: SHOW_AUTH_USER_ALERT });
        }
      });
  };
}

export const cancelAddRecipient = () => {
  return (dispatch, getState) => {
    dispatch({ type: HIDE_ADD_RECIPIENT_ALERT });
    dispatch({ type: FIELD_CLEAR });
  };
};

export const cancelNotAddedRicipient = () => {
  return (dispatch, getState) => {
    dispatch({ type: HIDE_NOT_ADDED_RECIPIENT_ALERT });
    dispatch({ type: FIELD_CLEAR });
  };
};

export const cancelError = () => {
  return (dispatch, getState) => {
    dispatch({ type: HIDE_ERROR_ALERT });
    dispatch({ type: FIELD_CLEAR });
  };
};

export const cancelUserAdded = () => {
  return (dispatch, getState) => {
    dispatch({ type: HIDE_USER_ADDED });
    dispatch({ type: FIELD_CLEAR });
  };
};

export const cancelAuthUser = () => {
  return (dispatch, getState) => {
    dispatch({ type: HIDE_AUTH_USER });
    dispatch({ type: FIELD_CLEAR });
  };
};

export const clearDialogAddResipient = () => {
  return (dispatch, getState) => {
    dispatch({ type: FIELD_CLEAR });
  };
};