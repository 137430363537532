import React, { Component } from "react";
import { SearchBar, ProductTable } from "./CommonComponents";
import PropTypes from "prop-types";
import SweetAlert from "react-bootstrap-sweetalert";
import { FormattedMessage } from "react-intl";
import { intl } from "../route/AppRouter";
export const SOLD_PRODUCTS_RADIO_FILTER = "soldProducts";
export const ALL_PRODUCTS_RADIO_FILTER = "allProducts";

class ProductList extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      handleFilterTextInput: PropTypes.func,
      onClickDelete: PropTypes.func,
      onChangePrint: PropTypes.func,
      cancelDelete: PropTypes.func
    })
  };

  constructor(props) {
    super(props);
    this.handleFilterTextInput = this.handleFilterTextInput.bind(this);
  }

  handleFilterTextInput(filterText) {
    this.props.actions.handleFilterTextInput(filterText);
  }

  rendarDeleteAlert(show) {
    return (
      <SweetAlert
        show={show}
        warning
        showCancel
        confirmBtnText={
          <FormattedMessage
            id="productTypeList.DeleteAlertConfirmBtnTex"
            defaultMessage="Да, удалить!"
          />
        }
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="default"
        cancelBtnText={
          <FormattedMessage
            id="productTypeList.DeleteAlertCancelBtnText"
            defaultMessage="Отмена"
          />
        }
        title={
          <FormattedMessage
            id="productTypeList.DeleteAlertTitleText"
            defaultMessage="Вы уверены?"
          />
        }
        onConfirm={this.props.actions.deleteProduct}
        onCancel={this.props.actions.cancelDelete}
      >
        <FormattedMessage
          id="productTypeList.DeleteAlertText"
          defaultMessage="Вы не сможете восстановить этот воображаемый продукт!"
        />
      </SweetAlert>
    );
  }

  renderAddSerialNumberAlert(show) {
    return(
      <SweetAlert
        show={show}
        warning
        confirmBtnBsStyle="danger"
        confirmBtnText ={
          <FormattedMessage
            id="productList.errorAddSerialNumberAlertConfBtnText"
          />}
        title={
          <FormattedMessage
            id="productList.errorAddSerialNumberAlertTitle"
          />}
        onConfirm={this.props.actions.cancelAddSerialNumberAlert}
        >
      </SweetAlert>

    )
  }


  handleRadioFilter(e) {
    this.props.actions.onChangeRadioFilter(e.target.name, e.target.value);
  }

  render() {
    const { products, radioFilterValue } = this.props;
    const soldProducts = products.filter(
      product => product.final_buyer !== null
    );
    return (
      <div>
        <div className="row">
          <h2 className="col-md-3">
            <FormattedMessage
              id="productList.titleText"
              defaultMessage="Товары"
            />
          </h2>
          <div className="col-md-9 custom-product-search">
            <SearchBar
              filterText={this.props.filterText}
              onFilterTextInput={this.handleFilterTextInput}
            />
          </div>
        </div>
        <div className="custom-product-counts">
          <div className="col-md-3">
            <div className="radio">
              <label>
                <input
                  type="radio"
                  name={ALL_PRODUCTS_RADIO_FILTER}
                  onChange={this.handleRadioFilter.bind(this)}
                  checked={radioFilterValue === ALL_PRODUCTS_RADIO_FILTER}
                />
                <div className="col-md-7">
                  {intl("productList.allCountText")}
                </div>
                <div className="col-md-5">{products.length}</div>
              </label>
            </div>
          </div>
          <div className="col-md-4">
            <div className="radio">
              <label>
                <input
                  type="radio"
                  name={SOLD_PRODUCTS_RADIO_FILTER}
                  onChange={this.handleRadioFilter.bind(this)}
                  checked={radioFilterValue === SOLD_PRODUCTS_RADIO_FILTER}
                />
                <div className="col-md-9">
                  {intl("productList.soldCountText")}
                </div>
                <div className="col-md-3">{soldProducts.length}</div>
              </label>
            </div>
          </div>
        </div>
        <ProductTable
          products={
            radioFilterValue === ALL_PRODUCTS_RADIO_FILTER
              ? products
              : soldProducts
          }
          filterText={this.props.filterText}
          onChangePrint={this.props.actions.onChangePrint}
          onClickDelete={this.props.actions.onClickDelete}
          onRequestShowProductInfo={this.props.actions.onRequestShowProductInfo}
        />
        {this.rendarDeleteAlert(this.props.showDeleteAlert)}
        {this.renderAddSerialNumberAlert(this.props.showAddSerialNumberAlert)}
      </div>
    );
  }
}
export default ProductList;
