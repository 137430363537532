import {
  OPEN,
  CLOSE,
  OPEN_DELETE_ALERT,
  CLOSE_DELETE_ALERT,
  DELETE_PRODUCT_ITEM_REQUEST,
  DELETE_PRODUCT_ITEM_SUCCESS,
  DELETE_PRODUCT_ITEM_FAILURE,
  CLOSE_ERROR_ALERT,
  SELECT_PRODUCT_ITEM_ID,
  GET_PRODUCT_ITEM_SUCCESS
} from "../actions/product_item_info_modal";

const initialState = {
  show: false,
  isLoading: false,
  isOpenDeleteAlert: false,
  productItemId: null,
  productItem: {},
  error: {}
};

export default function productItemInfoModal(state = initialState, action) {
  switch (action.type) {
    case OPEN_DELETE_ALERT:
      return {
        ...state,
        isOpenDeleteAlert: true
      };

    case CLOSE_DELETE_ALERT:
      return {
        ...state,
        isOpenDeleteAlert: false
      };
    case OPEN:
      return {
        ...state,
        show: true
      };

    case SELECT_PRODUCT_ITEM_ID:
      return {
        ...state,
        productItemId: action.productItemId
      };

    case CLOSE:
      return {
        ...state,
        show: false
      };

    case DELETE_PRODUCT_ITEM_REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case DELETE_PRODUCT_ITEM_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isOpenDeleteAlert: false,
        show: false
      };

    case DELETE_PRODUCT_ITEM_FAILURE:
      return {
        ...state,
        isLoading: false,
        isOpenDeleteAlert: false,
        error: action.error
      };

    case CLOSE_ERROR_ALERT:
      return {
        ...state,
        error: {}
      };


      case GET_PRODUCT_ITEM_SUCCESS:
      return {
        ...state,
        productItem: action.productItem
      }

    default:
      return state;
  }
}
