import React from "react";
import QRCode from "qrcode-react";

export default class QRCodeToPrint extends React.Component {
  render() {
    return (
      <div className="qr-code-print">
        <QRCode
          value={this.props.value}
          size={120}
          bgColor={"#ffffff"}
          fgColor={"#000000"}
          level={"L"}
          includeMargin={false}
          renderAs={"svg"}
        />
        <h6 className="value-qr-code">QR: {this.props.value || "Not found"}</h6>
        {this.props.serial_numbers.map((serial_number, index) => (
          <h6 className="value-qr-code">
            {`№ ${index + 1}: ${serial_number || "Not found"}`}
          </h6>
        ))}
      </div>
    );
  }
}
