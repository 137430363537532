import React, { Component } from "react";
import { intl } from "../route/AppRouter";
import { logout } from "../actions/navbar";

export default class CategoryPermissionPage extends Component {
  logout() {
    logout();
  }
  render() {
    return (
      <div className="sms-form-control">
        <div className="sms-center-form cunstom-category_perm">
          <h1 className="text-form-control">422</h1>
          <br />
          <h3 className="text-form-control">
            {intl("categoryPermissionPage.text")}
          </h3>
          <button className="btn btn-danger" onClick={this.logout}>
            {intl("categoryPermissionPage.logoutBtnText")}
          </button>
        </div>
      </div>
    );
  }
}
