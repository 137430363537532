import React from "react";
import Select from "react-select";
import PropTypes from "prop-types";
import { AddButton, DeleteButton } from "../CommonComponents";
import UIModal from "./UIModal";
import { intl } from "../../route/AppRouter";

export default class AddProductModal extends React.Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    serial_numbers: PropTypes.array.isRequired,
    product_types: PropTypes.array.isRequired,
    actions: PropTypes.shape({
      onRequestHide: PropTypes.func,
      addSerialNumber: PropTypes.func,
      onChangeInputSerialNumber: PropTypes.func,
      onSave: PropTypes.func,
      handleSelectChange: PropTypes.func,
      handleChangeQr: PropTypes.func
    })
  };

  handleChangeQr = event => {
    this.props.actions.handleChangeQr(event.target.value);
  };

  handleChangeDescription = event => {
    this.props.actions.handleChangeDescription(event.target.value);
  };

  renderDescriptionInput = () => {
    return (
      <div>
        <label>{intl("addProductModal.discreptionText")}: </label>
        <textarea
          name="description"
          className="form-control"
          onChange={this.handleChangeDescription.bind(this)}
          value={this.props.description}
        />
      </div>
    );
  };

  renderQRInput = () => {
    const { errors } = this.props;
    let className = "form-control";
    if (errors && errors.qr_field)
      className = "form-control error-serial-number";
    return (
      <div>
        <label>{intl("addProductModal.qrCodeText")}</label>
        <input
          className={className}
          onChange={this.handleChangeQr.bind(this)}
          value={this.props.qr_field}
        />
        <small className="text-danger">{errors && intl(errors.qr_field)}</small>
      </div>
    );
  };

  renderTypeInput = () => {
    const { errors } = this.props;
    let className = "";
    if (errors && errors.select_product_type) className = "error-serial-number";
    return (
      <div>
        <label>{intl("addProductModal.typeInputText")}</label>
        <Select
          className={className}
          name="product_type"
          options={this.props.product_types}
          onChange={this.props.actions.handleSelectChange}
          placeholder={intl("addProductModal.typeInputPlaceholder")}
          value={this.props.select_product_type}
        />
        <small className="text-danger">
          {errors && intl(errors.select_product_type)}
        </small>
      </div>
    );
  };

  onChangeInputSerialNumber = (event, serial_number) => {
    this.props.actions.onChangeInputSerialNumber(
      serial_number,
      event.target.value
    );
  };

  hasErrorsSerialNuber = index => {
    const { errors } = this.props;
    return (
      (errors && errors.duplicates && errors.duplicates.includes(index)) ||
      (errors && errors.emptys && errors.emptys.includes(index)) ||
      (errors && errors.serial_numbers && errors.serial_numbers.includes(index))
    );
  };

  renderAddButtonSerialNumber = () => {
    return <AddButton onClick={() => this.props.actions.addSerialNumber(0)} />;
  };

  renderSerialNumers = () => {
    return (
      <div>
        <h5>{intl("addProductModal.serialNumersText")}: </h5>
        <div className="serial-number-option">
          {this.props.serial_numbers.map((serial_number, index) => {
            const { errors } = this.props;
            let classOption = "col col-xs-8 serial-number";
            if (this.hasErrorsSerialNuber(index))
              classOption += " error-serial-number";
            return (
              <div className="row number-option" key={index}>
                <input
                  className={classOption}
                  onChange={e =>
                    this.onChangeInputSerialNumber(e, serial_number)
                  }
                  value={this.props.serial_numbers[index].value}
                />
                <AddButton
                  className="col col-xs-1"
                  onClick={() => this.props.actions.addSerialNumber(index)}
                />
                <DeleteButton
                  className="col col-xs-1"
                  onClick={() => this.props.actions.removeSerialNumber(index)}
                />
                <span className="col col-xs-3 text-danger">
                  {(errors &&
                    errors.duplicates.length > 0 &&
                    errors.duplicates.includes(index) &&
                    intl("serialNumber.dublicate")) ||
                    (errors &&
                      errors.emptys.length > 0 &&
                      errors.emptys.includes(index) &&
                      intl("serialNumber.required")) ||
                    (errors &&
                      errors.serial_numbers.length > 0 &&
                      errors.serial_numbers.includes(index) &&
                      intl("serialNumber.exits"))}
                </span>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  render() {
    const { serial_numbers } = this.props;
    return (
      <UIModal
        title={intl("addProductModal.uIModalAddProductTitle")}
        textOk={intl("addProductModal.uIModalAddProductOk")}
        textCancel={intl("addProductModal.uIModalAddProductCancel")}
        show={this.props.show}
        onRequestHide={this.props.actions.onRequestHide}
        onClickCancel={this.props.actions.onRequestHide}
        onClickOk={this.props.actions.onSave}
      >
        {this.renderTypeInput()}
        {this.renderQRInput()}
        {this.renderDescriptionInput()}
        {this.renderSerialNumers()}
        {!serial_numbers.length && this.renderAddButtonSerialNumber()}
      </UIModal>
    );
  }
}
