import React, { Component } from "react";
import LoadingBar from "react-redux-loading-bar";
import PropTypes from "prop-types";

export const Loader = WrappedComponent => {
  return class Loader extends Component {
    static propTypes = {
      action: PropTypes.shape({})
    };

    constructor(props) {
      super(props);
      this.state = {
        isLoading: false
      };

      this.showLoader = this.showLoader.bind(this);
    }

    showLoader() {
      this.setState({ isLoading: true });
    }

    render() {
      return (
        <div>
          <header>
            <LoadingBar style={{ backgroundColor: "blue", height: "3px" }} />
          </header>
          <WrappedComponent {...this.props} showLoader={this.showLoader} />
        </div>
      );
    }
  };
};
