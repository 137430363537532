import axios from "axios";
import { history } from "../index";
import { authHeader } from "../utils/auth_headers";

export const CHANGE_FIELD = "CHANGE_FIELD/";
export const FIELD_FIRST_NAME = "FIELD_FIRST_NAME";
export const FIELD_LAST_NAME = "FIELD_LAST_NAME";
export const FIELD_PHONE = "FIELD_PHONE";
export const FIELD_STORE = "FIELD_STORE";
export const FIELD_CATEGORY = "FIELD_CATEGORY";
export const FIELD_CLEAR = "FIELD_CLEAR";
export const CREATE_CHILD_USER_REQUEST_SUCCESS = "CREATE_CHILD_USER_REQUEST_SUCCESS";
export const CREATE_CHILD_USER_REQUEST_FAILURE = "CREATE_CHILD_USER_REQUEST_FAILURE";

export const FIELDS = {
  first_name: CHANGE_FIELD + FIELD_FIRST_NAME,
  last_name: CHANGE_FIELD + FIELD_LAST_NAME,
  phone: CHANGE_FIELD + FIELD_PHONE,
  store: CHANGE_FIELD + FIELD_STORE,
  category: CHANGE_FIELD + FIELD_CATEGORY
};

export function getFormValues(state) {
  const formValues = {
    first_name: state.firstName,
    last_name: state.lastName,
    phone: state.phone,
    store: state.store,
    user_category: state.category
  };
  return formValues;
}

export const changeSelectCategory = (category, value) => {
  return (dispatch, getState) => {
    dispatch(changeField("category", category.value));
  };
};

export const changeInputFields = event => {
  return (dispatch, getState) => {
    dispatch(changeField(event.target.name, event.target.value));
  };
};

export const changePhone = value => {
  return (dispatch, getState) => {
    dispatch(changeField("phone", value));
  };
};

export function changeField(field, value) {
  return { type: FIELDS[field], payload: value };
}

export function createChildUser() {
  return (dispatch, getState) => {
    const formValues = getFormValues(getState().createChildUser);
    const baseUrl = "api/users/create_child_user";
    axios.defaults.headers = authHeader();
    axios
      .post(baseUrl, JSON.stringify(formValues))
      .then(res => {
        let user_info = res.data;
        history.push("/");
        dispatch({ type: FIELD_CLEAR });
        dispatch({ type: CREATE_CHILD_USER_REQUEST_SUCCESS, user_info: user_info })
      })
      .catch(error => {
        dispatch({ type: CREATE_CHILD_USER_REQUEST_FAILURE, error: error.response })
      });
  };
}
