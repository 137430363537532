import axios from "axios";
import { history } from "../index";
import { authHeader } from "../utils/auth_headers";
export const CHANGE_FIELD = "CHANGE_FIELD/";
export const FIELD_PHONE = "FIELD_PHONE";

export const FIELDS = {
   phone: CHANGE_FIELD + FIELD_PHONE,
};

export function getFormValues(state) {
   const formValues = {
      phone: state.phone,
   };
   return formValues;
}

export const changePhone = (value) => {
   return (dispatch, getState) => {
     dispatch(changeField("phone", value));
   }
 }

 export function changeField(field, value) {
   return { type: FIELDS[field], payload: value };
}

export function sentPhone () {
   return (dispatch, getState) =>{
      const phone = getState().change_phone.phone
      axios.defaults.headers = authHeader();
      const baseUrl = "/api/users/update";
      axios
      .put(baseUrl, JSON.stringify({phone}))
      .then(res => {
        history.push("/sms");
      })
   }
}
