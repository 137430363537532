import axios from "axios";
import { history } from "../index";
import { USER_LOGIN, SUPPLIER } from "../utils/constants";
import { authHeader } from "../utils/auth_headers";

export const GET_USER_REQUEST_SUCCESS = "GET_USER_REQUEST_SUCCESS";
export const GET_USER_REQUEST_FAILURE = "GET_USER_REQUEST_FAILURE";
export const GET_USER_REQUEST_IS_LOAD = "GET_USER_REQUEST_IS_LOAD";

export function getUser() {
  return (dispatch, getState) => {
    axios.defaults.headers = authHeader();
    axios
      .get("api/users")
      .then(res => {
        let user = res.data;
        if (user) localStorage.setItem(USER_LOGIN, JSON.stringify(user));
        dispatch({ type: GET_USER_REQUEST_SUCCESS, payload: user });
        dispatch({ type: GET_USER_REQUEST_IS_LOAD });
        if (user && user.is_email_or_sms_confirmed) {
          if (user.user_category === SUPPLIER) history.push("/");
          else history.push("/category_permission");
        } else history.push("/sms");
      })
      .catch(error => {
        dispatch({ type: GET_USER_REQUEST_FAILURE, error: error.response });
      });
  };
}
