import React, { Component } from "react";
import { Provider } from "react-redux";
import AppRouter from "./route/AppRouter";
import { composeWithDevTools } from "redux-devtools-extension";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import reducer from "./redusers";
import { loadingBarMiddleware } from "react-redux-loading-bar";
import { addLocaleData } from "react-intl";
import ru from "react-intl/locale-data/ru";
import ky from "react-intl/locale-data/ky";

addLocaleData(ru);
addLocaleData(ky);

const store = createStore(
  reducer,
  composeWithDevTools(applyMiddleware(thunk, loadingBarMiddleware()))
);

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <AppRouter />
      </Provider>
    );
  }
}

export default App;
