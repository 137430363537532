import { combineReducers } from "redux";
import { loadingBarReducer } from "react-redux-loading-bar";
import signUp from "./sign_up";
import confirmSMS from "./confrimation_sms";
import setPassword from "./set_passowrd";
import login from "./login";
import page_user from "./page_user";
import recipients from "./respients";
import addProductModal from "./add_product_modal";
import addProductTypeModal from "./add_product_type_modal";
import change_phone from "./change_phone";
import productPage from "./product_page";
import qrCodeModal from "./qr_code_modal";
import productTypeList from "./product_type_list";
import productList from "./product_list";
import createChildUser from "./create_child_user";
import addRecipient from "./add_recipient";
import addProductItem from "./add_product_item";
import productInfo from "./product_info_modal";
import locale from "./language";
import inventory from "./inventory";
import addInventoryModal from "./add_inventory_modal";
import productItemsPage from "./product_items_page";
import productItemInfoModal from "./product_item_info_modal";
import productTransactionPage from "./product_transactions"
import transactionInfoModal from "./transaction_info_modal"
import productsToRecipient from './products_to_recipient';

export default combineReducers({
  signUp,
  confirmSMS,
  setPassword,
  login,
  page_user,
  recipients,
  addProductModal,
  addProductTypeModal,
  loadingBar: loadingBarReducer,
  change_phone,
  productPage,
  qrCodeModal,
  inventory,
  productTypeList,
  productList,
  createChildUser,
  addRecipient,
  addProductItem,
  productInfo,
  locale,
  addInventoryModal,
  productItemsPage,
  productItemInfoModal,
  productTransactionPage,
  transactionInfoModal,
  productsToRecipient
});
