import React, { Component } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import UIModal from "./UIModal";
import { Link } from "react-router-dom";
import { getDate } from "../../utils/utils";
import { intl } from "../../route/AppRouter";

export default class ProductsToRecipient extends Component {
  dateCreateView(cell, row) {
    return getDate(new Date(cell));
  }

  dateDateReceivingView(cell, row) {
    const { selected_recipient_id } = this.props;
    const transaction = row.transactions.filter(
      transaction => transaction.customer_id == selected_recipient_id
    )[0];
    return transaction && getDate(new Date(transaction.date_create));
  }

  dateDateSellerView(cell, row) {
    const { users, selected_recipient_id, logged_user } = this.props;
    const transaction = row.transactions.filter(
      transaction => transaction.customer_id == selected_recipient_id
    )[0];
    const recipient = users.filter(
      recipient => recipient.id == (transaction && transaction.seller_id)
    )[0];
    if (recipient) return `${recipient.first_name}: ${recipient.phone}`;
    else return `${logged_user.first_name}: ${logged_user.phone}`;
  }

  phoneSendView(cell, row) {
    const { recipients, selected_recipient_id, users } = this.props;
    const transaction = row.transactions.filter(
      transaction => transaction.seller_id == selected_recipient_id
    )[0];
    const recipient = users.filter(
      user => user.id == (transaction && transaction.customer_id)
    )[0];
    if (recipient)
      return (
        <a
          className="link-to-recpient"
          onClick={e =>
            this.props.actions.getProductCountsToRecipient(recipient.id, true)
          }
        >{`${recipient.first_name}: ${recipient.phone}`}</a>
      );
    else return "";
  }

  dateSendView(cell, row) {
    const { selected_recipient_id } = this.props;
    const transaction = row.transactions.filter(
      transaction => transaction.seller_id == selected_recipient_id
    )[0];

    return transaction ? getDate(new Date(transaction.date_create)) : "";
  }

  dateSold(cell, row) {
    return row.final_buyer && getDate(new Date(row.final_buyer.create_at));
  }

  buyer(cell, row) {
    return (
      row.final_buyer && `${row.final_buyer.name}: ${row.final_buyer.phone}`
    );
  }

  render() {
    return (
      <div>
        <UIModal
          title={intl("transacrion.infoUIModalTitle")}
          textCancel={intl("productInfo.productInfoUIModalCancel")}
          show={this.props.isOpen}
          onClickCancel={this.props.actions.close}
          dialogClassName="your-dialog-classname"
        >
          <div>
            {this.props.products.length && (
              <BootstrapTable
                data={this.props.products}
                height="380"
                scrollTop={"Bottom"}
                trStyle={this.rowStyleFormat}
              >
                <TableHeaderColumn
                  dataField="id"
                  isKey
                  dataSort={true}
                  width="80"
                >
                  {intl("ID")}
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="qr_code"
                  dataSort={true}
                  filter={{ type: "TextFilter", delay: 500 }}
                  width="250"
                >
                  {intl("QRcode")}
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="date_create"
                  dataSort={true}
                  dataFormat={this.dateCreateView}
                  width="150"
                >
                  {intl("productsToRecipient.creationDate")}
                </TableHeaderColumn>

                <TableHeaderColumn
                  dataSort={true}
                  dataFormat={this.dateDateReceivingView.bind(this)}
                  width="150"
                >
                  {intl("productsToRecipient.dateOfReceipt")}
                </TableHeaderColumn>

                <TableHeaderColumn
                  dataSort={true}
                  dataFormat={this.buyer.bind(this)}
                  width="150"
                >
                  {intl("productsToRecipient.whoSold")}
                </TableHeaderColumn>

                <TableHeaderColumn
                  dataSort={true}
                  dataFormat={this.dateSold.bind(this)}
                  width="150"
                >
                  {intl("productsToRecipient.whenSold")}
                </TableHeaderColumn>

                <TableHeaderColumn
                  dataSort={true}
                  dataFormat={this.dateDateSellerView.bind(this)}
                  width="250"
                >
                  {intl("productsToRecipient.gave")}
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataSort={true}
                  dataFormat={this.phoneSendView.bind(this)}
                  width="250"
                >
                  {intl("productsToRecipient.toWhomDidYouGive")}
                </TableHeaderColumn>

                <TableHeaderColumn
                  dataSort={true}
                  dataFormat={this.dateSendView.bind(this)}
                  width="250"
                >
                  {intl("productsToRecipient.whenHanded")}
                </TableHeaderColumn>
              </BootstrapTable>
            )}
          </div>
        </UIModal>
      </div>
    );
  }
}
