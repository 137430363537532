import { connect } from "react-redux";
import {bindActionCreators} from 'redux';
import * as signUpActions from "../actions/sign_up";
import SignUp from "../components/SignUp";

const mapStateToProps = state => ({
  ...state
})

function mapDispatchToProps(dispatch){
  return {
    actions: bindActionCreators({
      ...signUpActions
    }, dispatch)
  }
}

  

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);