import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as recipientsActions from "../actions/recipients";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import RecipientList from "../components/RecipientList";
import { withRouter } from 'react-router-dom'

const mapStateToProps = state => ({
  ...state,
  ...state.recipients
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...recipientsActions,
        showLoading,
        hideLoading
      },
      dispatch
    )
  };
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(RecipientList));
