export default {
  ru: {
    "login.buttonText": "Вход",
    "login.titleText": "Войти",
    "login.phoneText": "Тел",
    "login.passwordText": "Пароль",
    "login.clickRegisterText": "Нет аккаунта? Нажмите здесь для регистрации.",
    "login.passwordPlaceholderText": "Введите пароль",
    "register.titleText": "Регистрация",
    "register.firstNameText": "Имя",
    "register.lastNameText": "Фамилия",
    "register.storText": "Торговая точка",
    "register.phoneText": "Тел",
    "register.categoryText": "Категория",
    "register.categoryPlaceholderText": "Выберите категорию",
    "rigester.buttonText": "Регистрация",
    "rigester.linkText": "Назад",
    "register.errorPhone": "Заполните поля",
    "register.errorPhoneExits": "Номер уже зарегистрирована",
    "sms.titleText": "СМС подтверждение",
    "sms.inputPlaceholderText": "Введите код",
    "sms.buttonText": "Ок",
    "sms.changeNumberText": "Изменить номер",
    "sms.sendSmsAgainText": "Отправить смс сново",
    "password.titleText": "Пароль",
    "password.passwordText": "Пароль",
    "password.passwordConfirm": "Подтвердить пароль",
    "password.buttonText": "Ок",
    "addRecipient.recipientAddedTitleText": "Получатель успешно добавлен!",
    "addRecipient.recipientAddedConfirmBtxText": "Ок",
    "addRecipient.authUserErrorConfirmBtnText": "Назад",
    "addRecipient.authUserErrorTitleText":
      "Требуется авторизация пользователя.",
    "addRecipient.addRecipientWarningConfirmBtnText": "Создать пользователя",
    "addRecipient.addRecipientWarningTitleText": "Пользователь не найден.",
    "addRecipient.notAddedRicipientConfirmBtnText": "Назад",
    "addRecipient.notAddedRicipientTitleText": "Вы не можете добавить себя.",
    "addRecipient.errorConfirmBtnText": "Назад",
    "addRecipient.errorTitleText":
      "Вы не можете добавить. Этот пользователь с высшей категории!",
    "addRecipient.userAddedConfirmBtnText": "Назад",
    "addRecipient.userAddedTitleText": "Пользователь уже добавлен.",
    "addRecipient.addRecipientPhoneTitleText": "Тел",
    "addRecipient.addRecipientButtonText": "Добавить получателя",
    "addRecipient.linkText": "Назад",
    "changePhone.phoneText": "Тел",
    "changePhone.buttonText": "Изменить",
    "changePhone.titleText": "Изменить номер",
    "createChildUser.titleText": "Создать дочернего пользователя",
    "createChildUser.buttonText": "Зарегистрировать",
    "createChildUser.linkText": "Назад",
    "createChildUser.lastNameText": "Фамилия",
    "createChildUser.firstNameText": "Имя",
    "createChildUser.storeText": "Торговая точка",
    "createChildUser.phoneText": "Тел",
    "createChildUser.categoryText": "Категория",
    "createChildUser.categoryPlaceholderText": "Выберите категорию",
    "navBarPage.productText": "Товары",
    "navBarPage.inventoryText": "Инвентаризация",
    "navBarPage.transactions": "Пути",
    "navBarPage.addRecipientText": "Добавить получателя",
    "navBarPage.createRecipientText": "Создать получателя",
    "navBarPage.setPasswordText": "Установить пароль",
    "navBarPage.logoutText": "Выйти",
    "productList.deleteAlertConfirmBtnText": "Да, удалить!",
    "productList.deleteAlertTitleText": "Вы уверены?",
    "productList.sweetAlertTitleText":
      "Вы не сможете восстановить этот продукт после удаления!",
    "productList.titleText": "Товары",
    "productPage.productTypeButtonText": "Добавить тип продукта",
    "productPage.productButtonText": "Добавить продукт",
    "productPage.productAddProductItemButtonText": "Добавить серийный номер",
    "productTypeList.DeleteAlertConfirmBtnTex": "Да, удалить!",
    "productTypeList.DeleteAlertCancelBtnText": "Отмена",
    "productTypeList.DeleteAlertTitleText": "Вы уверены?",
    "productTypeList.DeleteAlertText":
      "Вы не сможете восстановить этот продукт после удаления!",
    "recipientItem.fullNameText": "ФИО",
    "recipientItem.phoneText": "Тел",
    "recipientItem.storeText": "Торговая точка",
    "recipientItem.categoryText": "Категория",
    "recipientPage.userProfileText": "Профиль пользователя",
    "recipientPage.recipientFirst_name": "Фамилия:",
    "recipientPage.recipientLast_name": "Имя:",
    "recipientPage.recipientPhone": "Тел",
    "recipientPage.recipientUser_category": "Категория:",
    "recipientPage.productInfoText": "Информация о продукте",
    "recipientPage.productName": "Товар",
    "recipientPage.productQuantityOfGoods": "Количество товара",
    "recipientPage.productProductsByUser": "Продукты пользователя",
    "recipientPage.productSoldProducts": "Проданные продукты",
    "recipientPage.productSentProducts": "Переданные продукты",
    "recipientPage.productAllProducts": "Все продукты",
    "comComponents.productQrCode": "QR код",
    "comComponents.dateOfReceiving": "Дата получения",
    "comComponents.dateSold": "Дата продажи",
    "comComponents.productType": "Тип продукта",
    "comComponents.dataCreate": "Дата создания",
    "comComponents.uIModalTitle": "Удалить товар",
    "comComponents.uIModalTextOk": "Да",
    "comComponents.uIModalTextCancel": "Нет",
    "comComponents.productTypeName": "Название",
    "comComponents.productTypePrice": "Цена",
    "comComponents.productTypeCound": "Количество продуктов",
    "comComponents.": "Количество продуктов",
    "comComponents.serialNumber": "Серийные номеры",
    "comComponents.productItemSerialNumber": "Серийный номер",
    "comComponents.productItemReplacementDate": "Дата замены",
    "addProductItem.serialNumber": "Серийный номер",
    "addProductItem.uIModalTitle": "Серийный номер",
    "addProductItem.uIModalTextOK": "Добавить",
    "addProductItem.uIModalTextCancel": "Отменить",
    "addProductModal.discreptionText": "Описание",
    "addProductModal.qrCodeText": "QR код",
    "addProductModal.typeInputText": "Тип продукта",
    "addProductModal.typeInputPlaceholder": "Выберите категорию",
    "addProductModal.serialNumersText": "Серийный номер",
    "addProductModal.uIModalAddProductTitle": "Добавить продукт",
    "addProductModal.uIModalAddProductOk": "Сохранить",
    "addProductModal.uIModalAddProductCancel": "Отменить",
    "addProductTypeModal.nameInputText": "Название",
    "addProductTypeModal.descriptionInput": "Описание",
    "addProductTypeModal.priceInput": "Цена",
    "addProductTypeModal.warrantyDaysInput": "Гарантийный срок (день)",
    "addProductTypeModal.uIModalTypeModdalText": "Добавить продукт",
    "addProductTypeModal.uIModalTypeModdalOk": "Сохранить",
    "addProductTypeModal.uIModalTypeModdalCancel": "Отменить",
    "productInfo.productInfoQRCode": "QR код:",
    "productInfo.productInfoProductTypeName": "Название продукта",
    "productInfo.productInfofinalBuyerName": "Имя покупателя:",
    "productInfo.productInfoFinalBuyerCreateAt": "Дата продажи:",
    "productInfo.productInfoFinalBuyerPhone": "Тел",
    "productInfo.productInfoFinalBuyerCarBrand": "Марка машины:",
    "productInfo.productInfoWhoTheProductName": "Владелец",
    "productInfo.productInfoWhoTheProductPhone": "Тел",
    "productInfo.productInfoUIModalTitle": "Информация о продукте",
    "productInfo.productInfoUIModalCancel": "Назад",
    "productList.allCountText": "Все:",
    "productList.soldCountText": "Продано:",
    "productList.errorAddSerialNumberAlertTitle": "Товар продан",
    "productList.errorAddSerialNumberAlertConfBtnText": "Ок",
    "qrCodeModal.printButton": "Распечатать",
    "qrCodeModal.modalTitle": "QR код продукта",
    "updateProductTypeModal.nameInput": "Название",
    "updateProductTypeModal.descriptionInput": "Описание",
    "updateProductTypeModal.priceInput": "Цена",
    "updateProductTypeModal.warrantyDaysInput": "Гарантийный срок (день)",
    "updateProductTypeModal.uIModalTitle": "Добавить серийный номер",
    "updateProductTypeModal.uIModalTextOk": "Сохранить",
    "updateProductTypeModal.uIModalTextCancel": "Отменить",
    "recipientList.deleteRecipientConfirmBtnText": "Да, удалить!",
    "recipientList.deleteRecipientCancelBtnText": "Отменить",
    "recipientList.deleteRecipientTitle":
      "Вы действительно хотите удалить получателя?",
    "recipientList.errorRecipientConnotDeleteConfirmBtnText": "Отменить",
    "recipientList.errorRecipientConnotDeleteTitle":
      "Вы не можете удалить пользователя.",
    "recipientList.errorRecipientConnotDeleteText":
      "У этого пользователя есть получатели.",
    "recipientList.errorRecipientPoductDeleteConfirmBtnText": "Отменить",
    "recipientList.errorRecipientPoductDeleteTitle":
      "Вы не можете удалить пользователя. Получатель уже удален.",
    "recipientList.errorRecipientPoductDeleteText":
      "У этого пользователя есть продукты",
    "recipientList.deleteRecipientUserNoRecipientTitle": "Вы не можете удалить",
    "recipientList.deleteRecipientUserNoRecipient": "Не ваш получатель",
    "recipientList.recipientDeletedConfirmBtnText": "Отменить",
    "recipientList.ecipientDeletedTitle": "Получатель успешно удален.",
    "serialNumber.dublicate": "Дублируются",
    "serialNumber.required": "Объязвтельно",
    "serialNumber.exits": "Уже существует.",
    "add_product_type_modal.reqired": "Объязвтельно",
    "add_product_type.isRequired": "Объязвтельно",
    "product.item.serialNumberAlreadyExits": "Уже в базе.",
    "error.phone.notValidate": "Введите корректный номер.",
    "auth.password.notMatch": "Неверный номер или пароль!",
    "auth.user.notFound": "Неверный номер или пароль!",
    "inventory.table.id": "ID",
    "inventory.table.name": "Имя инвенторизации",
    "inventory.table.close_at": "Дата закрытие",
    "inventory.table.created_at": "Дата создание",
    "inventory.youHaveNoProducts":
      "У вас нету продуктов для создния инветоризации",
    "addInventoryModal.title": "Создать Инветаризацию",
    "addInventoryModal.name": "Имя инвентаризации",
    "inventory.closeBtnText": "Закрыть",
    "inventory.preview": "Посмотр",
    "inventory.user.firstName": "Имя",
    "inventory.user.lastName": "Фамилия",
    "user.inventoryItem.productQr": "Qr код",
    "user.inventoryItem.id": "ID",
    "user.inventoryItem.closedAt": "Дата закрытие",
    "inventory.user.phone": "Телефон",
    "inventoryItem.count": "Количество:",
    "inventoryItem.closed.count": "Прошли:",
    "auth.phone.notAllowed": "Заполните поля.",
    "auth.phone.alreadyPhoneExits":
      "Пользователь с таким номером уже существует.",
    "auth.user.notCorrectCategory": "Вы не выбрали котегорию.",
    "user.auth.invalidConfirmationToken": "Неверный код",
    "password.validate.doNotMatch": "Пароль не совпадает",
    "productItem.table.id": "ID",
    "productItem.table.serialNumber": "Серийный номер",
    "productItem.table.replacementDate": "Дата замены",
    "productItem.table.info": "Инфо",
    "productItemInfoModal.title": "Информация",
    "productItem.DeleteAlertText": "Операция необратима!",
    "productErrorAler.auth.user.permissionDenied":
      "Серийный номер привязан к продукту!",
    "product.delete.userIsNotTheOwner": "Вы не являетесь владельцем!",
    "productItemInfoModal.body.qrCode": "QR код:",
    "productItemInfoModal.body.serialNumber": "Серийный номер:",
    "productItemInfoModal.body.owner": "Владелец:",
    "productItemInfoModal.body.phone": "Тел:",
    "noMatch.notFound": "Страница не найдено!",
    "categoryPermissionPage.text": "У вас нету доступа!",
    "categoryPermissionPage.logoutBtnText": "Выйти",
    "transacrion.infoUIModalTitle": "Информация",
    "productsToRecipient.creationDate": "Дата создание",
    "productsToRecipient.dateOfReceipt": "Дата получение",
    "productsToRecipient.whoSold": "Кому продан",
    "productsToRecipient.whenSold": "Когда продан",
    "productsToRecipient.gave": "Отдал",
    "productsToRecipient.toWhomDidYouGive": "Кому передал",
    "productsToRecipient.whenHanded": "Когда передал",
    "ID": "ID",
    "QRcode": "QR code",
    "auth.phone.alreadyPhoneExits": "Номер уже зарегистрирован"
  },
  // ө
  // ң
  // ү
  // ү
  // Ө
  ky: {
    "login.buttonText": "Кирүү",
    "login.titleText": "Кирүү",
    "login.phoneText": "Тел",
    "login.passwordText": "Сыр сөз",
    "login.clickRegisterText":
      "Аккаунтуңуз жокпу? Катталуу үчүн бул жерди басыңыз.",
    "login.passwordPlaceholderText": "Сыр сөзду жазуу",
    "register.titleText": "Катталуу",
    "register.firstNameText": "Ысым",
    "register.lastNameText": "Атаңыздын аты",
    "register.storText": "Соода түйүнү",
    "register.phoneText": "Тел",
    "register.categoryText": "Категория",
    "register.categoryPlaceholderText": "Сиздин категорияңыз",
    "rigester.buttonText": "Катталуу",
    "rigester.linkText": "Артка",
    "register.errorPhone": "Номурду жазыныз",
    "register.errorPhoneExits": "Бул номур катталган",
    "sms.titleText": "СМС ырастоо",
    "sms.inputPlaceholderText": "Кодту жазыңыз",
    "sms.buttonText": "Ок",
    "sms.changeNumberText": "Номурду өзгөртүү",
    "sms.sendSmsAgainText": "Кайра смс жөнөтүү",
    "password.titleText": "Сыр сөз",
    "password.passwordText": "Сыр сөз",
    "password.passwordConfirm": "Сыр создү ырастоо",
    "password.buttonText": "Ок",
    "addRecipient.recipientAddedTitleText": "Алуучу кошулду",
    "addRecipient.recipientAddedConfirmBtxText": "Ок",
    "addRecipient.authUserErrorConfirmBtnText": "Жокко чыгаруу",
    "addRecipient.authUserErrorTitleText": "Алуучуну тастыктоо талап кылынат",
    "addRecipient.addRecipientWarningConfirmBtnText": "Алуучуну түзүү",
    "addRecipient.addRecipientWarningTitleText": "Колдонуучу табылган жок.",
    "addRecipient.notAddedRicipientConfirmBtnText": "Артка",
    "addRecipient.notAddedRicipientTitleText":
      "Бул колдонуучуну кошуу мүмкүн эмес.",
    "addRecipient.errorConfirmBtnText": "Жокко чыгаруу",
    "addRecipient.errorTitleText":
      "Бул колдонуучуну кошо албайсыз! Колдонуучу жогорку категориядан.",
    "addRecipient.userAddedConfirmBtnText": "Жокко чыгаруу",
    "addRecipient.userAddedTitleText": "Колдонуучу мурунтан эле кошулган.",
    "addRecipient.addRecipientPhoneTitleText": "Тел",
    "addRecipient.addRecipientButtonText": "Алуучуну кошуу",
    "addRecipient.linkText": "Арка",
    "changePhone.phoneText": "Тел",
    "changePhone.buttonText": "Өзгөртүү",
    "changePhone.titleText": "Номурду өзгөртуу",
    "createChildUser.titleText": "Алуучу түзүү",
    "createChildUser.buttonText": "Каттоодон өткөрүү",
    "createChildUser.linkText": "Артка",
    "createChildUser.firstNameText": "Ысымы",
    "createChildUser.lastNameText": "Атасынын аты",
    "createChildUser.storeText": "Соода түйүнү",
    "createChildUser.phoneText": "Тел",
    "createChildUser.categoryText": "Категория",
    "createChildUser.categoryPlaceholderText": "Категорияны тандаңыз",
    "navBarPage.productText": "Товарлар",
    "navBarPage.inventoryText": "Инвентаризациялоо",
    "navBarPage.addRecipientText": "Алуучуну кошуу",
    "navBarPage.createRecipientText": "Алуучуну түзүү",
    "navBarPage.setPasswordText": "Сыр сөзду орнотуу",
    "navBarPage.logoutText": "Чыгуу",
    "navBarPage.transactions": "Жолдор",
    "productList.deleteAlertConfirmBtnText": "Ооба, жок кылынсын!",
    "productList.deleteAlertTitleText": "Чын элеби?",
    "productList.sweetAlertTitleText":
      "Продуктуну жок кылгандан кийин, калыбына келтирүү мумкун эмес!",
    "productList.titleText": "Товарлар",
    "productPage.productTypeButtonText": "Продукт тибин кошуу",
    "productPage.productButtonText": "Продуктту кошуу",
    "productPage.productAddProductItemButtonText": "Сериялык номур кошуу",
    "productTypeList.DeleteAlertConfirmBtnTex": "Ооба. Жокко чыгаруу!",
    "productTypeList.DeleteAlertCancelBtnText": "Артка",
    "productTypeList.DeleteAlertTitleText": "Чын элеби?",
    "productTypeList.DeleteAlertText":
      "Продуктуну жок кылгандан кийин, калыбына келтирүү мумкун эмес!",
    "productList.allCountText": "Баары:",
    "productList.soldCountText": "Сатылды:",
    "productList.errorAddSerialNumberAlertTitle": "Мүлк сатылган",
    "productList.errorAddSerialNumberAlertConfBtnText": "Макул",
    "recipientItem.fullNameText": "Аты жөнү",
    "recipientItem.phoneText": "Тел",
    "recipientItem.storeText": "Соода түйүнү",
    "recipientItem.categoryText": "Категория",
    "recipientPage.userProfileText": "Колдонуучунун профили",
    "recipientPage.recipientFirst_name": "Атасынын аты:",
    "recipientPage.recipientLast_name": "Ысым:",
    "recipientPage.recipientPhone": "Тел",
    "recipientPage.recipientUser_category": "Категориясы:",
    "recipientPage.productInfoText": "Товар тууралуу маалымат",
    "recipientPage.productName": "Товар",
    "recipientPage.productQuantityOfGoods": "Товардын саны",
    "recipientPage.productProductsByUser": "Колдонуучунун товарлары",
    "recipientPage.productSoldProducts": "Сатылган товарлар",
    "recipientPage.productSentProducts": "Берилген товарлар",
    "recipientPage.productAllProducts": "Жалпы товар",
    "comComponents.productQrCode": "QR коду",
    "comComponents.dateOfReceiving": "Алган убактысы",
    "comComponents.dateSold": "Сатылган убактысы",
    "comComponents.productType": "Продуктунун тиби",
    "comComponents.dataCreate": "Түзүлгөн убактысы",
    "comComponents.uIModalTitle": "Товарды жок кылуу",
    "comComponents.uIModalTextOk": "Ооба",
    "comComponents.uIModalTextCancel": "Жок",
    "comComponents.productTypeName": "Аталышы",
    "comComponents.productTypePrice": "Баасы",
    "comComponents.productTypeCound": "Саны",
    "comComponents.": "Товардын саны",
    "comComponents.serialNumber": "Сериялык номердин тизмеси",
    "comComponents.productItemSerialNumber": "Сериялык номери",
    "comComponents.productItemReplacementDate": "Өзгөртүлгөн күнү",
    "addProductItem.serialNumber": "Сериялык номери",
    "addProductItem.uIModalTitle": "Товарды кошуу",
    "addProductItem.uIModalTextOK": "Кошуу",
    "addProductItem.uIModalTextCancel": "Жокко чыгаруу",
    "addProductModal.discreptionText": "Сыпатталышы",
    "addProductModal.qrCodeText": "QR коду",
    "addProductModal.typeInputText": "Продуктунун тиби",
    "addProductModal.typeInputPlaceholder": "Категорияны тандаңыз",
    "addProductModal.serialNumersText": "Сериялык номери",
    "addProductModal.uIModalAddProductTitle": "Товарды кошуу",
    "addProductModal.uIModalAddProductOk": "Сактоо",
    "addProductModal.uIModalAddProductCancel": "Жокко чыгаруу",
    "addProductTypeModal.nameInputText": "Аталышы",
    "addProductTypeModal.descriptionInput": "Сыпатталышы",
    "addProductTypeModal.priceInput": "Баасы",
    "addProductTypeModal.warrantyDaysInput": "Кепилдик мөөнөтү (күн)",
    "addProductTypeModal.uIModalTypeModdalText": "Товарды кошуу",
    "addProductTypeModal.uIModalTypeModdalOk": "Сактоо",
    "addProductTypeModal.uIModalTypeModdalCancel": "Жокко чыгаруу",
    "productInfo.productInfoQRCode": "QR коду:",
    "productInfo.productInfoProductTypeName": "Товардын аталышы",
    "productInfo.productInfofinalBuyerName": "Алуучунун ысымы:",
    "productInfo.productInfoFinalBuyerCreateAt": "Сатылган куну:",
    "productInfo.productInfoFinalBuyerPhone": "Тел",
    "productInfo.productInfoFinalBuyerCarBrand": "Унаанын маркасы:",
    "productInfo.productInfoWhoTheProductName": "Ээси",
    "productInfo.productInfoWhoTheProductPhone": "Тел",
    "productInfo.productInfoUIModalTitle": "Мүлк тууралуу маалымыт",
    "productInfo.productInfoUIModalCancel": "Артка",
    "qrCodeModal.printButton": "Басма",
    "qrCodeModal.modalTitle": "Товардын QR коду",
    "updateProductTypeModal.nameInput": "Аталышы",
    "updateProductTypeModal.descriptionInput": "Сыпатталышы",
    "updateProductTypeModal.priceInput": "Баасы",
    "updateProductTypeModal.warrantyDaysInput": "Кепилдик мөөнөтү (күн)",
    "updateProductTypeModal.uIModalTitle": "Товарды кошуу",
    "updateProductTypeModal.uIModalTextOk": "Сактоо",
    "updateProductTypeModal.uIModalTextCancel": "Жокко чыгаруу",
    "recipientList.deleteRecipientConfirmBtnText": "Ооба",
    "recipientList.deleteRecipientCancelBtnText": "Артка",
    "recipientList.deleteRecipientTitle": "Чын эле жок кыласызбы?",
    "recipientList.errorRecipientConnotDeleteConfirmBtnText": "Жокко чыгаруу",
    "recipientList.errorRecipientConnotDeleteTitle":
      "Сиз колдонуучуну жок кыла албайсыз.",
    "recipientList.errorRecipientConnotDeleteText":
      "Бул колдонуучунун алуучулары бар.",
    "recipientList.errorRecipientPoductDeleteConfirmBtnText": "Жокко чыгаруу",
    "recipientList.errorRecipientPoductDeleteTitle":
      "Сиз колдонуучуну жок кыла албайсыз.",
    "recipientList.errorRecipientPoductDeleteText":
      "Бул колдонуучуда мулктор бар.",
    "recipientList.deleteRecipientUserNoRecipientTitle": "Сиз жокко чыгара албайсыз",
    "recipientList.deleteRecipientUserNoRecipient": "Сиздин алуучунуз эмес",
    "recipientList.recipientDeletedConfirmBtnText": "Артка",
    "recipientList.ecipientDeletedTitle": "Колдонуучу жокко чыгарылды.",
    "serialNumber.dublicate": "Кайталанып жатат.",
    "serialNumber.required": "Толтуруш керек.",
    "serialNumber.exits": "Бул базада бар.",
    "add_product_type_modal.reqired": "Толтуруш керек",
    "add_product_type.isRequired": "Толтуруш керек",
    "product.item.serialNumberAlreadyExits": "Бул базада бар",
    "error.phone.notValidate": "Номериңизди туура жазыңыз",
    "auth.password.notMatch": "Номериңиз же паролуңуз туура эмес",
    "auth.user.notFound": "Номериңиз же паролуңуз туура эмес",
    "inventory.table.id": "ID",
    "inventory.table.name": "Аты",
    "inventory.table.close_at": "Жабылган убактысы",
    "inventory.table.created_at": "Кошулган убактысы",
    "inventory.youHaveNoProducts": "Сиздин товарыныз жок!",
    "addInventoryModal.title": "Инветаризацияны Кошуу",
    "addInventoryModal.name": "Аты",
    "inventory.closeBtnText": "Жабуу",
    "inventory.preview": "Көрүү",
    "inventory.user.firstName": "Аты",
    "inventory.user.lastName": "Фамилиясы",
    "user.inventoryItem.productQr": "Qr коду",
    "user.inventoryItem.id": "ID",
    "user.inventoryItem.closedAt": "Жабылган убактысы",
    "inventory.user.phone": "Телефону",
    "inventoryItem.count": "Саны:",
    "inventoryItem.closed.count": "Өттү:",
    "auth.phone.notAllowed": "Номурунузда жазыныз",
    "auth.phone.alreadyPhoneExits": "Булу номур катталган",
    "auth.user.notCorrectCategory": "Категиряны танданыз",
    "user.auth.invalidConfirmationToken": "Код туура эмес",
    "password.validate.doNotMatch": "Сыр сөздөр дал келбейт",
    "productItem.table.id": "ID",
    "productItem.table.serialNumber": "Номери",
    "productItem.table.replacementDate": "Алмаштырган убактысы",
    "productItem.table.info": "Маалымат",
    "productItemInfoModal.title": "Маалымат",
    "productItem.DeleteAlertText": "Кайра кайтарууга болбойт!",
    "productErrorAler.auth.user.permissionDenied": "Товарга байланган!",
    "product.delete.userIsNotTheOwner": "Бул силердики эмес!",
    "productItemInfoModal.body.qrCode": "QR коду:",
    "productItemInfoModal.body.serialNumber": "Номери:",
    "productItemInfoModal.body.owner": "Ээси:",
    "productItemInfoModal.body.phone": "Тел:",
    "noMatch.notFound": "Мындай бет жок!",
    "categoryPermissionPage.text": "Сизге уруксат жок!",
    "categoryPermissionPage.logoutBtnText": "Чыгуу",
    "transacrion.infoUIModalTitle": "Маалымат",
    "productsToRecipient.creationDate": "Түзүлгөн датасы",
    "productsToRecipient.dateOfReceipt": "Алынган датасы",
    "productsToRecipient.whoSold": "Кимге сатылган",
    "productsToRecipient.whenSold": "Качан сатылган",
    "productsToRecipient.gave": "Ал берди",
    "productsToRecipient.toWhomDidYouGive": "Кимге берди",
    "productsToRecipient.whenHanded": "Качан берди",
    "ID": "ID",
    "QRcode": "QR коду",
    "auth.phone.alreadyPhoneExits": "Номур буга чейин катталган"
  }
  //Ү ү// ө
  // ң
  // ү
  // ү
  // Ө
};
