import axios from "axios";
import { authHeader } from "../utils/auth_headers";
import { isEmpty } from "./add_product_type_modal";
import { OPEN as OPEN_QR_CODE } from "../actions/qr_code_modal";

export const OPEN = "ADD_PRODUCT_MODAL/OPEN";
export const CLOSE = "ADD_PRODUCT_MODAL/CLOSE";
export const ADD_SERIAL_NUMBER = "ADD_SERIAL_NUMBER";
export const REMOVE_SERIAL_NUMBER = "REMOVE_SERIAL_NUMBER";
export const CHANGE_SERIAL_NUMBER = "CHANGE_SERIAL_NUMBER";
export const INCREMENT_INDEX = "SERIAL_NUMBER/INCREMENT_INDEX";
export const DECREMENT_INDEX = "SERIAL_NUMBER/DECREMENT_INDEX";
export const CLEAR_INDEX = "SERIAL_NUMBER/CLEAR";
export const ERROR_DUBLICATE_SERIAL_NUMBER = "ERROR_DUBLICATE_SERIAL_NUMBER";
export const SELECT_PRODUCT_TYPE = "SELECT_PRODUCT_TYPE";
export const ERROR_EMPTY_SERIAL_NUMBER = "ERROR_EMPTY_SERIAL_NUMBER";
export const CHANGE_QR_PRODUCT_TYPE = "CHANGE_QR_PRODUCT_TYPE";
export const ADD_PRODUCT_REQUEST_SUCCESS = "ADD_PRODUCT_REQUEST_SUCCESS";
export const ADD_PRODUCT_REQUEST_FAILURE = "ADD_PRODUCT_REQUEST_FAILURE";
export const FIELDS_CLEAR = "FIELDS_CLEAR_ADD_PRODUCT";
export const CHANGE_DESCPRIPTION_PRODUCT_TYPE =
  "CHANGE_DESCPRIPTION_PRODUCT_TYPE";
export const VALIDE_SERIAL_NUMBER_REQUEST = "VALIDE_SERIAL_NUMBER_REQUEST";
export const ERROR_VALIDATE_ADD_PRODUCT = "ERROR_VALIDATE_ADD_PRODUCT";
export const ERROR_VALIDATE_QR_CODE = "ERROR_VALIDATE_QR_CODE";

function errorsIsEmpty(errors) {
  for (let error in errors) {
    if (errors[error] && errors[error].length > 0) return false;
  }
  return true;
}

export const onRequestHide = () => {
  return (dispatch, getState) => {
    dispatch({ type: CLOSE });
  };
};

export const onRequestShow = () => {
  return (dispatch, getState) => {
    dispatch({ type: OPEN });
  };
};

export const addSerialNumber = index => {
  return (dispatch, getState) => {
    dispatch({ type: INCREMENT_INDEX });
    dispatch({
      type: ADD_SERIAL_NUMBER
    });
  };
};

export const removeSerialNumber = index => {
  return (dispatch, getState) => {
    dispatch({ type: REMOVE_SERIAL_NUMBER, index });
    dispatch({
      type: VALIDE_SERIAL_NUMBER_REQUEST,
      errors: []
    });
  };
};

export const onChangeInputSerialNumber = (serial_number, value) => {
  return (dispatch, getState) => {
    serial_number.value = value;
    dispatch({ type: CHANGE_SERIAL_NUMBER, serial_number, value });
    dispatch({
      type: VALIDE_SERIAL_NUMBER_REQUEST,
      errors: []
    });
  };
};

export const handleSelectChange = product_type => {
  return (dispatch, getState) => {
    dispatch({ type: SELECT_PRODUCT_TYPE, product_type });
  };
};

export const handleChangeQr = value => {
  return (dispatch, getState) => {
    const error = validateQRcode(value);
    dispatch({ type: ERROR_VALIDATE_QR_CODE, error });
    dispatch({ type: CHANGE_QR_PRODUCT_TYPE, value });
  };
};

export const handleChangeDescription = value => {
  return (dispatch, getState) => {
    dispatch({ type: CHANGE_DESCPRIPTION_PRODUCT_TYPE, value });
  };
};

function validateQRcode(code) {
  let error = "";
  if (!code.match(/^[A-Za-z0-9-]*[A-Za-z0-9][A-Za-z0-9-]*$/))
    error = "qr_code.notValid";
  return error;
}

function valideRequired(product) {
  let error = {};
  if (!product.qr_field) error["qr_field"] = "add_product_type_modal.reqired";
  if (isEmpty(product.select_product_type))
    error["select_product_type"] = "add_product_type_modal.reqired";
  return error;
}

function validateDublicateSerialNubers(serial_numbers) {
  var duplicates = {};

  for (let i = 0; i < serial_numbers.length; i++) {
    if (duplicates.hasOwnProperty(serial_numbers[i].value)) {
      duplicates[serial_numbers[i].value].push(i);
    } else if (serial_numbers.lastIndexOf(serial_numbers[i].value) !== i) {
      duplicates[serial_numbers[i].value] = [i];
    }
  }
  const index_duplicates = [];
  for (let prop in duplicates) {
    const indexs = duplicates[prop];
    if (indexs.length > 1) {
      index_duplicates.push.apply(index_duplicates, indexs);
    }
  }
  return index_duplicates;
}

function validateSerialNumber(serial_numbers) {
  const index_duplicates = [];
  for (let i = 0; i < serial_numbers.length; i++) {
    if (!serial_numbers[i].value) {
      index_duplicates.push(i);
    }
  }
  return index_duplicates;
}

function serialNumberErrors(error, serial_numbers) {
  let indexs = [];
  for (let i = 0; i < serial_numbers.length; i++) {
    if (error.some(el => el.value === serial_numbers[i].value)) {
      indexs.push(i);
    }
  }
  return indexs;
}

export const onSave = () => {
  return (dispatch, getState) => {
    const serial_numbers = getState().addProductModal.serial_numbers;
    const product = getState().addProductModal;
    const errors = validateDublicateSerialNubers(serial_numbers);
    const errorsEmpty = validateSerialNumber(serial_numbers);
    const errorValidate = valideRequired(product);

    dispatch({ type: ERROR_DUBLICATE_SERIAL_NUMBER, errors });
    dispatch({ type: ERROR_EMPTY_SERIAL_NUMBER, errors: errorsEmpty });
    dispatch({ type: ERROR_VALIDATE_ADD_PRODUCT, errors: errorValidate });

    const allErrors = getState().addProductModal.errors;
    if (errorsIsEmpty(allErrors)) {
      axios.defaults.headers = authHeader();
      axios
        .post(
          "api/product/create",
          JSON.stringify({
            product_type_id: product.select_product_type.id,
            qr_unique_word: product.qr_field,
            description: product.description,
            serial_numbers: product.serial_numbers
          })
        )
        .then(res => {
          let product = res.data;
          dispatch({ type: FIELDS_CLEAR });
          dispatch({
            type: ADD_PRODUCT_REQUEST_SUCCESS,
            product: product
          });
          dispatch({ type: CLOSE });
          dispatch({ type: OPEN_QR_CODE });
        })
        .catch(errorRequests => {
          const error = errorRequests.response && errorRequests.response.data;
          const qrCodeError = error && errorRequests.response.data.message;
          const errorSerialNumber = serialNumberErrors(
            error.json,
            getState().addProductModal.serial_numbers
          );
          if (qrCodeError === "product.item.qrCodeAlreadyExits")
            dispatch({
              type: ADD_PRODUCT_REQUEST_FAILURE,
              errors: qrCodeError
            });
          dispatch({
            type: VALIDE_SERIAL_NUMBER_REQUEST,
            errors: errorSerialNumber
          });
        });
    }
  };
};
