import {
  CHANGE_FIELD,
  FIELD_FIRST_NAME,
  FIELD_LAST_NAME,
  FIELD_PHONE,
  FIELD_CATEGORY,
  FIELD_STORE,
  FIELD_CLEAR,
  SIGN_UP_REQUEST_SUCCESS,
  SIGN_UP_REQUEST_FAILURE
} from "../actions/sign_up";
import { AUTH_TOKEN } from "../utils/constants";

const initialState = {
  firstName: "",
  lastName: "",
  phone: "",
  category: "",
  store: "",
  errorSingUp: [],
  auth: localStorage.getItem(AUTH_TOKEN)
};

export default function signUp(state = initialState, action) {
  switch (action.type) {
    case CHANGE_FIELD + FIELD_FIRST_NAME:
      return { ...state, firstName: action.payload };
    case CHANGE_FIELD + FIELD_LAST_NAME:
      return { ...state, lastName: action.payload };
    case CHANGE_FIELD + FIELD_PHONE:
      return { ...state, phone: action.payload };
    case CHANGE_FIELD + FIELD_CATEGORY:
      return { ...state, category: action.payload };
    case CHANGE_FIELD + FIELD_STORE:
      return { ...state, store: action.payload };

    case SIGN_UP_REQUEST_SUCCESS:
      return { ...state, auth: action.payload };

    case SIGN_UP_REQUEST_FAILURE:
      return { ...state, errorSingUp: action.error };
    
    case FIELD_CLEAR:
      return { ...initialState }

    default:
      return state;
  }
}
