import React, { Component } from "react";
import UIModal from "./UIModal";
import { SerialNumberTable } from "../CommonComponents";
import { getDate } from "../../utils/utils";
import { intl } from "../../route/AppRouter";

export default class ProductInfo extends Component {
  renderProductInfo = () => {
    const { product } = this.props;
    return (
      <div>
        <div>
          <table className="table table-striped">
            <thead>
              <tr className="row">
                <th className="col-sm-4">
                  {intl("productInfo.productInfoQRCode")}
                </th>
                <th className="col-sm-8">{this.props.product.qr_code}</th>
              </tr>
            </thead>
          </table>
          <table className="table">
            <thead>
              <tr className="row">
                <th className="col-sm-4">
                  {intl("productInfo.productInfoProductTypeName")}
                </th>
                <th className="col-sm-8">
                  {this.props.product.product_type.name}
                </th>
              </tr>
            </thead>
          </table>
          {product.final_buyer && (
            <table className="table table-striped">
              <thead>
                <tr className="row">
                  <th className="col-sm-4">
                    {intl("productInfo.productInfofinalBuyerName")}
                  </th>
                  <th className="col-sm-3">
                    {this.props.product.final_buyer.name}
                  </th>
                  <th className="col-sm-2">
                    {intl("productInfo.productInfoFinalBuyerCreateAt")}
                  </th>
                  <th className="col-sm-3">
                    {getDate(
                      new Date(this.props.product.final_buyer.create_at)
                    )}
                  </th>
                </tr>
              </thead>
            </table>
          )}
          <table className="table table-striped">
            <thead>
              {product.final_buyer && product.final_buyer.phone && (
                <tr className="row">
                  <th className="col-sm-4">
                    {intl("productInfo.productInfoFinalBuyerPhone")}:
                  </th>
                  <th className="col-sm-8">
                    {this.props.product.final_buyer.phone}
                  </th>
                </tr>
              )}
              {product.final_buyer && product.final_buyer.car_brand && (
                <tr className="row">
                  <th className="col-sm-4">
                    {intl("productInfo.productInfoFinalBuyerCarBrand")}
                  </th>
                  <th className="col-md-8">
                    {this.props.product.final_buyer.car_brand}
                  </th>
                </tr>
              )}
            </thead>
          </table>
          <table className="table table-striped">
            <thead>
              <tr className="row">
                <th className="col-sm-4">
                  {intl("productInfo.productInfoWhoTheProductName")}
                </th>
                <th className="col-sm-8">
                  {this.props.product.who_the_product.first_name}{" "}
                  {this.props.product.who_the_product.last_name}
                </th>
              </tr>
              <tr className="row">
                <th className="col-sm-4">
                  {intl("productInfo.productInfoWhoTheProductPhone")}
                </th>
                <th className="col-sm-4">
                  {this.props.product.who_the_product.phone}
                </th>
              </tr>
            </thead>
          </table>
          {product.product_items.length > 0 && (
            <SerialNumberTable product_items={product.product_items} />
          )}
        </div>
      </div>
    );
  };

  render() {
    return (
      <UIModal
        title={intl("productInfo.productInfoUIModalTitle")}
        textCancel={intl("productInfo.productInfoUIModalCancel")}
        show={this.props.show}
        onClickCancel={this.props.actions.onRequestHide}
      >
        {this.renderProductInfo()}
      </UIModal>
    );
  }
}
