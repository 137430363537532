import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AddInventoryModal from "../components/Modals/AddInventoryModal";
import * as AddInventoryModalActions from "../actions/add_inventory_modal";

const mapStateToProps = state => ({
  ...state.addInventoryModal
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...AddInventoryModalActions
      },
      dispatch
    )
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddInventoryModal);
