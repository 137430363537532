export const OPEN = "PRODUTS_TO_RECIPIENTS_MODAL/OPEN";
export const CLOSE = "PRODUTS_TO_RECIPIENTS_MODAL/CLOSE";

export const open = () => {
  return { type: OPEN };
};

export const close = () => {
  return { type: CLOSE };
};
