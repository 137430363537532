import React from "react";
import PropTypes from "prop-types";
import UIModal from "./UIModal";
import { intl } from "../../route/AppRouter";

export default class AddProductTypeModal extends React.Component {
  static propTypes = {
    show: PropTypes.bool,
    actions: PropTypes.shape({
      onRequestHide: PropTypes.func,
      addSerialNumber: PropTypes.func
    })
  };

  onChange = event => {
    this.props.actions.changeAddProudctModal(
      event.target.name,
      event.target.value
    );
  };

  renderNameInput = () => {
    const { error } = this.props;
    let className = "form-control";
    if (error && error.name) className = "form-control error-serial-number";
    return (
      <div>
        <label>{intl("addProductTypeModal.nameInputText")}: </label>
        <input
          className={className}
          name="name"
          onChange={this.onChange.bind(this)}
          value={this.props.name}
        />
        <small className="text-danger">{error && intl(error.name)}</small>
      </div>
    );
  };

  renderDescriptionInput = () => {
    return (
      <div>
        <label>{intl("addProductTypeModal.descriptionInput")}: </label>
        <textarea
          name="description"
          className="form-control"
          onChange={this.onChange.bind(this)}
          value={this.props.description}
        />
      </div>
    );
  };

  renderPriceInput = () => {
    const { error } = this.props;
    let className = "form-control";
    if (error && error.price) className = "form-control error-serial-number";
    return (
      <div>
        <label>{intl("addProductTypeModal.priceInput")}: </label>
        <input
          type="number"
          className={className}
          name="price"
          onChange={this.onChange.bind(this)}
          value={this.props.price}
        />
        <small className="text-danger">{error && intl(error.price)}</small>
      </div>
    );
  };
  warranty_days;

  renderWarrantyDaysInput = () => {
    const { error } = this.props;
    let className = "form-control";
    if (error && error.warranty_days)
      className = "form-control error-serial-number";
    return (
      <div>
        <label>{intl("addProductTypeModal.warrantyDaysInput")}: </label>
        <input
          type="number"
          className={className}
          name="warranty_days"
          onChange={this.onChange.bind(this)}
          value={this.props.warranty_days}
        />
        <small className="text-danger">{error && intl(error.warranty_days)}</small>
      </div>
    );
  };
  render() {
    return (
      <UIModal
        title={intl("addProductTypeModal.uIModalTypeModdalText")}
        textOk={intl("addProductTypeModal.uIModalTypeModdalOk")}
        textCancel={intl("addProductTypeModal.uIModalTypeModdalCancel")}
        show={this.props.show}
        onRequestHide={this.props.actions.onRequestHide}
        onClickCancel={this.props.actions.onRequestHide}
        onClickOk={this.props.actions.onSave}
      >
        {this.renderNameInput()}
        {this.renderDescriptionInput()}
        {this.renderPriceInput()}
        {this.renderWarrantyDaysInput()}
      </UIModal>
    );
  }
}
