import React, { Component } from "react";
import ReactPhoneInput from "react-phone-input-2";
import { Button } from "react-bootstrap";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

const pStyle = {
  width: "100%"
};

export default class ChangePhone extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      changePhone: PropTypes.func
    })
  };

  renderPhoneForm = () => {
    const { phone } = this.props;
    const actions = this.props.actions;
    return (
      <form>
        <label>
          <FormattedMessage id="changePhone.phoneText" defaultMessage="Тел" />:
        </label>
        <div>
          <ReactPhoneInput
            value={phone}
            defaultCountry={"kg"}
            inputExtraProps={{
              name: "phone",
              required: true,
              autoFocus: true
            }}
            onChange={actions.changePhone}
            required="true"
            inputStyle={pStyle}
            onlyCountries={["kg", "ru", "kz", "uz"]}
            autoFormat={false}
          />
        </div>
        <div>
          <Button
            className="button-Password"
            bsStyle="primary"
            bsSize="large"
            onClick={actions.sentPhone}
            block
          >
            <FormattedMessage
              id="changePhone.buttonTex"
              defaultMessage="Изменить"
            />
          </Button>
        </div>
      </form>
    );
  };

  render() {
    return (
      <div>
        <div className="signUpForm">
          <h2 className="text-form-control">
            <FormattedMessage
              id="changePhone.titleText"
              defaultMessage="Изменить номер"
            />{" "}
          </h2>
          {this.renderPhoneForm()}
        </div>
      </div>
    );
  }
}
