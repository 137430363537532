import {
  OPEN,
  CLOSE,
  ON_NAME_CHANGE,
  ON_CHANGE_DESCRIPTION,
  REQUEST_ADD_INVENTORY,
  ADD_INVENTORY_SUCCESS,
  ADD_INVENTORY_FAILURE,
  ERROR_ALERT_CONFIRM
} from "../actions/add_inventory_modal";

const initialState = {
  isOpen: false,
  name: "",
  description: "",
  isLoading: false,
  inventory: {},
  error: {}
};

export default function addInventoryModal(state = initialState, actoin) {
  switch (actoin.type) {
    case OPEN:
      return {
        ...state,
        isOpen: true
      };

    case CLOSE:
      return {
        ...state,
        ...initialState
      };
    case ON_NAME_CHANGE:
      return {
        ...state,
        name: actoin.value
      };

    case ON_CHANGE_DESCRIPTION:
      return {
        ...state,
        description: actoin.value
      };

    case REQUEST_ADD_INVENTORY:
      return {
        ...state,
        isLoading: true
      };

    case ADD_INVENTORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        inventory: actoin.inventory
      };
    case ADD_INVENTORY_FAILURE:
      return {
        ...state,
        error: actoin.error,
        isLoading: false
      };

    case ERROR_ALERT_CONFIRM:
      return {
        ...state,
        error: {},
        isOpen: false
      };

    default:
      return state;
  }
}
