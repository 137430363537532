export const OPEN = "PRODUCT_INFO_MODAL/OPEN";
export const CLOSE = "PRODUCT_INFO_MODAL/CLOSE";
export const PRODUCT_INFO_SUCCESS = "PRODUCT_INFO_SUCCESS";
export const FIELDS_CLEAR = "FIELDS_CLEAR";

export const onRequestShow = product_id => {
  return (dispatch, getState) => {
    dispatch({ type: OPEN });
  };
};

export const onRequestHide = () => {
  return (dispatch, getState) => {
    dispatch({ type: CLOSE });
    dispatch({ type: FIELDS_CLEAR });
  };
};
