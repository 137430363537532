import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as ChangePhoneActions from "../actions/change_phone";
import ChangePhone from "../components/ChangePhone";

const mapStateToProps = state => ({
  ...state.change_phone
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...ChangePhoneActions
      },
      dispatch
    )
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePhone);
