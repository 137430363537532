import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as createChildUserActions from "../actions/create_child_user";
import CreateChildUser from "../components/CreateChildUser";

const mapStateToProps = state => ({
  ...state.createChildUser
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...createChildUserActions
      },
      dispatch
    )
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateChildUser);
