import React from "react";
import NavbarPage from "../containers/NavbarPageContainer";
import PropTypes from "prop-types";
import { intl } from "../route/AppRouter";
import { AddButton } from "./CommonComponents";
import InventoryTable from "./InventoryTabel";
import AddInventoryModal from "../containers/AddInventoryModalContainer";

export default class Inventory extends React.Component {
  static propTypes = {
    inventories: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
    actions: PropTypes.shape({
      goToSetPassPage: PropTypes.func,
      logout: PropTypes.func,
      goToCreateUserPage: PropTypes.func,
      goToAddRecipientPage: PropTypes.func,
      addInventoryModalOpen: PropTypes.func,
      closeOpenInventory: PropTypes.func
    })
  };
  enableAddButton = () => {
    return this.props.inventories.every(
      inventory => inventory.closed_at != null
    );
  };
  componentDidMount() {
    this.props.actions.getInventories();
  }
  render() {
    const { inventories } = this.props;
    return (
      <div>
        <NavbarPage />
        <div className="table-custom-inventory">
          <AddButton
            className="btn btn-primary add-inventory-button"
            onClick={this.props.actions.addInventoryModalOpen}
            disabled={!this.enableAddButton()}
          />
          <InventoryTable
            inventories={inventories}
            closeOpenInventory={this.props.actions.closeOpenInventory}
            getFullInventory={this.props.actions.getFullInventory}
            disabledCloseButton={this.props.isLoading}
            onAfterDeleteRow={this.props.actions.onAfterDeleteRow}
            users={this.props.users}
            inventory={this.props.inventory}
          />
          <AddInventoryModal />
        </div>
      </div>
    );
  }
}
