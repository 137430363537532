import React from "react";
import NavbarPage from "../containers/NavbarPageContainer";
import PropTypes from "prop-types";
import { intl } from "../route/AppRouter";
import Tree from "react-tree-graph";
import "react-tree-graph/dist/style.css";
import TransactionInfoModal from "../containers/TransactionInfoModalContainer";

export default class ProductTransactionPage extends React.Component {
  static propTypes = {
    actions: PropTypes.shape({})
  };

  componentDidMount() {
    this.props.actions.getProductTransactions();
    this.props.actions.getFullRecipients();
  }

  render() {
    let { user, users } = this.props;
    return (
      <div>
        <NavbarPage />
        <div className="custom-users">
          <Tree
            data={user}
            height={900}
            width={1024}
            duration={1500}
            nodeRadius={5}
            svgProps={{
              className: "custom"
            }}
            steps={200}
            textProps={{
              className: "custom",
              onClick: this.props.actions.onClickRecipient
            }}
            animated
          />
        </div>
        <TransactionInfoModal/>
      </div>
    );
  }
}
