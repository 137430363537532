import React from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "react-bootstrap";
import ReactToPrint from "react-to-print";
import QRCodeToPrint from "./QRcodeToPrint";
import { intl } from "../../route/AppRouter";

export default class QRcodeModal extends React.Component {
  static propTypes = {
    actions: PropTypes.shape({
      onRequestHide: PropTypes.func,
      changeAddProudctModal: PropTypes.func
    })
  };

  onChange = event => {
    this.props.actions.changeAddProudctModal(
      event.target.name,
      event.target.value
    );
  };

  renderPrintButton = () => {
    return (
      <Button className="button-qr-print" bsStyle="primary">
        {intl("qrCodeModal.printButton")}
      </Button>
    );
  };

  renderCloseButton = () => {
    return (
      <button
        type="button"
        className="close"
        onClick={this.props.actions.onRequestHide}
      >
        <span aria-hidden="true">×</span>
      </button>
    );
  };

  render() {
    const { product } = this.props;
    const serialNumbers =
      product &&
      product.product_items &&
      product.product_items.map(product_item => product_item.serial_number);
    return (
      <Modal
        show={this.props.show}
        onRequestHide={this.props.actions.onRequestHide}
      >
        <Modal.Header>
          <Modal.Title>
            {intl("qrCodeModal.modalTitle")}
            {this.renderCloseButton()}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="center-qr-code">
            <QRCodeToPrint
              ref={el => (this.componentRef = el)}
              value={product && product.qr_code}
              serial_numbers={serialNumbers || []}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <ReactToPrint
            trigger={() => this.renderPrintButton()}
            content={() => this.componentRef}
          />
        </Modal.Footer>
      </Modal>
    );
  }
}
