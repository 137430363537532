

export const OPEN = "QR_CODE_MODAL/OPEN";
export const CLOSE = "QR_CODE_MODAL/CLOSE";

export const onRequestHide = () => {
    return (dispatch, getState) => {
      dispatch({ type: CLOSE });
    };
  };
  
  export const onRequestShow = () => {
    return (dispatch, getState) => {
      dispatch({ type: OPEN });
    };
  };

