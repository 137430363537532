import axios from "axios";
import { authHeader } from "../utils/auth_headers";
import { SOLD, SEND, ALL } from "../components/RecipientPage";
import {
  open as openProductsToRecipientModal,
  close as closeProductsToRecipientModal
} from "../actions/products_to_recipient";
import { history } from "..";

export const USER_PRODUCT_LIST_SUCCESS = "USER_PRUDUCT_LIST_SUCCESS";
export const PRODUCT_ALL_COUNT = "PRODUCT_PAGE/PRODUCT_ALL_COUNT";
export const PRODUCT_SEND_PRODUCT_COUNT =
  "PRODUCT_PAGE/PRODUCT_SEND_PRODUCT_COUNT";
export const PRODUCT_SOLD_PRODUCT_COUNT =
  "PRODUCT_PAGE/PRODUCT_SOLD_PRODUCT_COUNT";
export const PRODUCT_UNSOLD_PRODUCT_COUNT =
  "PRODUCT_PAGE/PRODUCT_UNSOLD_PRODUCT_COUNT";
export const USER_PRODUCT_LIST_FAILUER =
  "PRODUCT_PAGE/USER_PRODUCT_LIST_FAILUER";

export const PRODUCTS_TO_RECIPIENT_SUCCESS =
  "PRODUCT_PAGE/PRODUCTS_TO_RECIPIENT_SUCCESS";
export const PRODUCTS_TO_RECIPIENT_FAILURE =
  "PRODUCT_PAGE/PRODUCTS_TO_RECIPIENT_FAILURE";

export const getProductCountsToRecipient = (recipient_id, isNow = false) => {
  return (dispatch, getState) => {
    const baseUrl = `/api/product/product_counts_recipients?recipient_id=${recipient_id}`;
    axios.defaults.headers = authHeader();
    axios
      .get(baseUrl)
      .then(res => {
        const {
          all_count,
          send_product_count,
          sold_product_count,
          unsold_product_count
        } = res.data;
        dispatch({ type: USER_PRODUCT_LIST_SUCCESS });
        dispatch({ type: PRODUCT_ALL_COUNT, all_count });
        dispatch({ type: PRODUCT_SEND_PRODUCT_COUNT, send_product_count });
        dispatch({ type: PRODUCT_SOLD_PRODUCT_COUNT, sold_product_count });
        dispatch({ type: PRODUCT_UNSOLD_PRODUCT_COUNT, unsold_product_count });
        if (isNow) {
          dispatch(closeProductsToRecipientModal());
          history.push(`/recipient/${recipient_id}`);
        }
      })
      .catch(error => {
        dispatch({ type: USER_PRODUCT_LIST_FAILUER });
      });
  };
};

export const getProductsRecipient = (recipient_id, type) => {
  return (dispatch, getState) => {
    let param = "";
    if (type === SOLD) param = "sold=true";
    else if (type === SEND) param = "send=true";
    else if (type === ALL) param = "all=true";
    else param = "sold=false";

    const url = `/api/transactions/get_to_recipient?recipient_id=${recipient_id}&${param}`;
    axios.defaults.headers = authHeader();
    axios
      .get(url)
      .then(res => {
        const { products, users } = res.data;
        dispatch({ type: PRODUCTS_TO_RECIPIENT_SUCCESS, products, users });
        if (products.length > 0) {
          dispatch(openProductsToRecipientModal());
        }
      })
      .catch(err => {
        dispatch({ type: PRODUCTS_TO_RECIPIENT_FAILURE });
      });
  };
};
