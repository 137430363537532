import {
  GET_PRODUCT_TYPES_REQUEST_SUCCESS,
  GET_PRODUCT_TYPES_REQUEST_FAILURE,
  GET_PRODUCT_TYPES_REQUEST_IS_LOAD,
  GET_PRODUCTS_REQUEST_SUCCESS,
  GET_PRODUCTS_REQUEST_FAILURE,
  DELETE_PRODUCT_REQUEST_SUCCESS
} from "../actions/product_page";
import { ADD_PRODUCT_TYPE_REQUEST_SUCCESS } from "../actions/add_product_type_modal";
import { ADD_PRODUCT_REQUEST_SUCCESS } from "../actions/add_product_modal";
import {
  DELETE_PRODUCT_REQUEST_TYPE_SUCCESS,
  PRODUCT_TYPE_UPDATE_REQUEST_SUCCESS
} from "../actions/product_type_list";

const initialState = {
  isLoad: false,
  product_types: [],
  error: [],
  products: []
};

export default function productPage(state = initialState, action) {
  switch (action.type) {
    case GET_PRODUCT_TYPES_REQUEST_SUCCESS:
      return { ...state, product_types: action.product_types };

    case GET_PRODUCT_TYPES_REQUEST_FAILURE:
      return { ...state, errors: action.errors };

    case GET_PRODUCT_TYPES_REQUEST_IS_LOAD:
      return { ...state, isLoad: true };

    case ADD_PRODUCT_TYPE_REQUEST_SUCCESS:
      return {
        ...state,
        product_types: [...state.product_types, action.product_type]
      };

    case GET_PRODUCTS_REQUEST_SUCCESS:
      return { ...state, products: action.products };

    case GET_PRODUCTS_REQUEST_FAILURE:
      return { ...state, errors: action.errors };

    case ADD_PRODUCT_REQUEST_SUCCESS:
      const product_type_ = state.product_types.find(
        product_type => product_type.id === action.product.product_type_id
      );
      return {
        ...state,
        products: [action.product, ...state.products],
        product_types: [
          {
            ...product_type_,
            product_count: product_type_.product_count + 1
          },
          ...state.product_types.filter(
            product_type => product_type.id !== action.product.product_type_id
          )
        ]
      };

    case DELETE_PRODUCT_REQUEST_SUCCESS:
      const product_type = state.product_types.find(
        product_type => product_type.id === action.product.product_type_id
      );
      return {
        ...state,
        products: [
          ...state.products.filter(product => product.id !== action.product.id)
        ],
        product_types: [
          ...state.product_types.filter(
            product_type => product_type.id !== action.product.product_type_id
          ),
          {
            ...product_type,
            product_count: product_type.product_count - 1
          }
        ]
      };

    case DELETE_PRODUCT_REQUEST_TYPE_SUCCESS:
      return {
        ...state,
        product_types: [
          ...state.product_types.filter(
            product_type => product_type.id !== action.product_type.id
          )
        ]
      };

    case PRODUCT_TYPE_UPDATE_REQUEST_SUCCESS:
      return {
        ...state,
        product_types: [
          action.product_type,
          ...state.product_types.filter(
            product_type => product_type.id !== action.product_type.id
          )
        ]
      };

    default:
      return state;
  }
}
