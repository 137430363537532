import React from "react";
import UIModal from "./UIModal";
import { intl } from "../../route/AppRouter";
import PropTypes from "prop-types";
import SweetAlert from "react-bootstrap-sweetalert/lib/dist/SweetAlert";

export default class AddInventoryModal extends React.Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    isLoading: PropTypes.func,
    actions: PropTypes.shape({
      onAddInventory: PropTypes.func,
      open: PropTypes.func,
      close: PropTypes.func
    })
  };
  onNameChange = e => {
    this.props.actions.onNameChange(e.target.value);
  };

  handleChangeDescription = e => {
    this.props.actions.handleChangeDescription(e.target.value);
  };

  renderDescriptionInput = () => {
    return (
      <div>
        <label>{intl("addProductModal.discreptionText")}: </label>
        <textarea
          name="description"
          className="form-control"
          onChange={this.handleChangeDescription.bind(this)}
          value={this.props.description}
        />
      </div>
    );
  };

  renderNameInput = () => {
    return (
      <div>
        <label>{intl("addInventoryModal.name")}:</label>
        <input
          className="form-control"
          name="serial_number"
          onChange={this.onNameChange.bind(this)}
          value={this.props.serial_number}
        />
        <div className="text-danger">
          {this.props.error && intl(this.props.error.message)}
        </div>
      </div>
    );
  };

  render() {
    return (
      <div>
        <UIModal
          title={intl("addInventoryModal.title")}
          textOk={intl("addProductItem.uIModalTextOK")}
          textCancel={intl("addProductItem.uIModalTextCancel")}
          show={this.props.isOpen}
          onRequestHide={this.props.actions.close}
          onClickCancel={this.props.actions.close}
          onClickOk={this.props.actions.onAddInventory}
          disabledOkBtn={this.props.isLoading}
        >
          {this.renderNameInput()}
          {this.renderDescriptionInput()}
        </UIModal>
        <SweetAlert
          show={!!this.props.error.message}
          error
          confirmBtnText={intl("OK")}
          cancelBtnBsStyle="danger"
          title={"Ой"}
          onConfirm={this.props.actions.onConfirmErrorAlert}
          className="custom-alret"
        >
          {intl(`${this.props.error.message}`)}
        </SweetAlert>
      </div>
    );
  }
}
