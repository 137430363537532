import React, { Component } from "react";
import { Button } from "react-bootstrap";
import FormattedInput from "@buttercup/react-formatted-input";
import { AUTH_TOKEN } from "../utils/constants";
import { Redirect, Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { intl } from "../route/AppRouter";

import PropTypes from "prop-types";

const idPattern = [
  { char: /\d/, repeat: 2 },
  { exactly: "-" },
  { char: /\d/, repeat: 2 },
  { exactly: "-" },
  { char: /\d/, repeat: 2 },
  { exactly: "-" },
  { char: /\d/, repeat: 2 }
];

export default class SMSConfirm extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      changeSmsForm: PropTypes.func,
      sendComfirmation: PropTypes.func
    })
  };

  getAuthToken = () => {
    const auth = JSON.parse(localStorage.getItem(AUTH_TOKEN));
    if (auth) return auth.token;
    else return "";
  };

  isUserConfirm = () => {
    const { user } = this.props;
    return user && user.is_email_or_sms_confirmed;
  };

  hasErrors = () => {
    const { errors } = this.props;
    return errors.length > 0;
  };

  renderConfirmError() {
    const { errorConfirm } = this.props;
    return (
      <small className="text-danger">
        {errorConfirm && errorConfirm.data && intl(errorConfirm.data.message)}
      </small>
    );
  }

  renderSmsForm = () => {
    return (
      <form>
        <div>
          <FormattedInput
            className="sms-input-form-control"
            name="sms_number"
            format={idPattern}
            onChange={this.props.actions.changeSmsForm}
            placeholder="ВВедиты код: ##-##-##-##"
          />
        </div>
        <br />
        <div className="page">
          <Button
            bsStyle="primary"
            bsSize="large"
            disabled={this.hasErrors()}
            block
            onClick={e => this.props.actions.sendComfirmation()}
          >
            <FormattedMessage 
              id="sms.buttonText"
              defaultMessage="Ok" />
          </Button>
        </div>
      </form>
    );
  };

  render() {
    const auth_token = this.getAuthToken();
    if (!auth_token) {
      return <Redirect to="/login" from="/sms" />;
    }

    return (
      <div className="sms-form-control">
        <div className="sms-center-form">
          <h2 className="text-form-control text-center"><FormattedMessage 
            id="sms.titleText"
            defaultMessage="SMS Подтверждение" /></h2>
          <br />
          {this.renderConfirmError()}
          {this.renderSmsForm()}
          <Link to="/change_phone" className="btn btn-link">
          <FormattedMessage 
            id="sms.changeNumberText"
            defaultMessage="Изменить номер" />
          </Link>
          <Link
            to=""
            onClick={e => this.props.actions.reSentSms()}
            className="btn btn-link"
          >
            <FormattedMessage 
              id="sms.sendSmsAgainText"
              defaultMessage="Отправить СМС снова" /> 
          </Link>
        </div>
      </div>
    );
  }
}
