import axios from "axios";
import { authHeader } from "../utils/auth_headers";
import { changeField } from "../actions/add_product_type_modal";


export const SHOW_LOAD = "SHOW_LOAD_PRODUCT_TYPE_LIST";
export const HIDE_DELETE_ALERT = "HIDE_DELETE_ALERT_PRODUCT_TYPE";
export const SHOW_DELETE_ALERT = "SHOW_DELETE_ALERT_PRODUCT_TYPE";
export const DELETE_PRODUCT_REQUEST_TYPE_SUCCESS =
  "DELETE_PRODUCT_REQUEST_TYPE_SUCCESS";

export const SELECT_PRODUCT_TYPE = "SELECT_PRODUCT_TYPE";
export const SHOW_UPDATE_MODAL_PRODUCT_TYPE = "SHOW_UPDATE_MODAL_PRODUCT_TYPE";
export const HIDE_UPDATE_MODAL_PRODUCT_TYPE = "HIDE_UPDATE_MODAL_PRODUCT_TYPE";
export const PRODUCT_TYPE_UPDATE_REQUEST_SUCCESS =
  "PRODUCT_TYPE_UPDATE_REQUEST_SUCCESS";
export const PRODUCT_TYPE_UPDATE_REQUEST_FAILURE =
  "PRODUCT_TYPE_UPDATE_REQUEST_FAILURE";

export const DELETE_PRODUCT_REQUEST_TYPE_FAILURE =
  "DELETE_PRODUCT_REQUEST_TYPE_FAILURE";


export const deleteProductType = () => {
  return (dispatch, getState) => {
    const product_type = getState().productTypeList.selected_product_type;

    axios.defaults.headers = authHeader();

    axios({
      method: "DELETE",
      url: "api/product_type/delete",
      data: { product_type_id: product_type.id }
    })
      .then(res => {
        dispatch({ type: HIDE_DELETE_ALERT });
        dispatch({ type: DELETE_PRODUCT_REQUEST_TYPE_SUCCESS, product_type });
      })
      .catch(error => {
        dispatch({ type: DELETE_PRODUCT_REQUEST_TYPE_FAILURE, product_type });
      });
  };
};

export const cancelDelete = () => {
  return (dispatch, getState) => {
    dispatch({ type: HIDE_DELETE_ALERT });
  };
};



export const onClickDelete = product_type => {
  return (dispatch, getState) => {
    dispatch({ type: SHOW_DELETE_ALERT });
    dispatch({ type: SELECT_PRODUCT_TYPE, product_type });
  };
};

export const updateProductType = product_type => {
  return (dispatch, getState) => {
    dispatch({ type: SELECT_PRODUCT_TYPE, product_type });
    dispatch({ type: SHOW_UPDATE_MODAL_PRODUCT_TYPE });
  };
};

export const onRequestHide = () => {
  return (dispatch, getState) => {
    dispatch({ type: HIDE_UPDATE_MODAL_PRODUCT_TYPE });
  };
};

export const changeUpdateProudctModal = (name, value) => {
  return (dispatch, getState) => {
    dispatch(changeField(name, value));
  };
};

export const onUpdate = () => {
  return (dispatch, getState) => {
    const product_type = getState().productTypeList.selected_product_type;
    axios.defaults.headers = authHeader();
    const product_type_data = {
      product_type_id: product_type.id,
      name: product_type.name,
      description: product_type.description,
      price: product_type.price,
      warranty_days: product_type.warranty_days
    };
    axios
      .put("api/product_type/update", JSON.stringify(product_type_data))
      .then(res => {
        const product_type = res.data;
        product_type.label = product_type.name;
        product_type.value = product_type.id;
        dispatch({ type: HIDE_UPDATE_MODAL_PRODUCT_TYPE });
        dispatch({ type: PRODUCT_TYPE_UPDATE_REQUEST_SUCCESS, product_type });
      })
      .catch(error => {
        dispatch({
          type: PRODUCT_TYPE_UPDATE_REQUEST_FAILURE,
          error: error.response.data
        });
      });
  };
};
