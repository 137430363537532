import {
  REQUEST_ALL_INVENTORY,
  GET_ALL_INVENTORY_SUCCESS,
  CLOSE_OPEN_INVENTORY_SUCCESS,
  DELETE_INVENTORY_SUCCESS,
  GET_FULL_INVENTORY_SUCCESS
} from "../actions/inventory";
import { getDate } from "../utils/utils";
import { ADD_INVENTORY_SUCCESS } from "../actions/add_inventory_modal";
import { getInventoryUsers } from "../utils/inventory_utils";

const initialState = {
  isLoading: false,
  inventories: [],
  inventory: {
    inventory_items: []
  },
  users: []
};

export default function inventory(state = initialState, action) {
  switch (action.type) {
    case REQUEST_ALL_INVENTORY:
      return {
        ...state,
        isLoading: true
      };

    case GET_ALL_INVENTORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        inventories: action.inventories.map(inventory => {
          if (inventory.closed_at)
            inventory.closed_at = getDate(new Date(inventory.closed_at));
          inventory.created_at = getDate(new Date(inventory.created_at));
          return inventory;
        })
      };

    case ADD_INVENTORY_SUCCESS:
      const inventory = action.inventory;
      inventory.created_at = getDate(new Date(inventory.created_at));
      return {
        ...state,
        inventories: [...state.inventories, action.inventory]
      };

    case CLOSE_OPEN_INVENTORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        inventories: [
          ...state.inventories.map(inventory => {
            if (inventory.id === action.inventoryId) {
              inventory.closed_at = getDate(new Date());
            }
            return inventory;
          })
        ],
        users: []
      };

    case DELETE_INVENTORY_SUCCESS:
      return {
        ...state,
        inventories: [
          ...state.inventories.filter(
            inventory => !action.inventoryIds.includes(inventory.id)
          )
        ]
      };

    case GET_FULL_INVENTORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        inventory: action.inventory,
        users: getInventoryUsers(action.inventory)
      };

    default:
      return state;
  }
}
