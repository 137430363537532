export const OPEN = "TRANSACTION_INFO_MODAL/OPEN";
export const CLOSE = "TRANSACTION_INFO_MODAL/CLOSE";
export const TRANSACTION_INFO_MODAL = "TRANSACTION_INFO_MODAL/GET_INFO"

export const open = () => {
  return { type: OPEN };
};

export const close = () => {
  return { type: CLOSE };
};
