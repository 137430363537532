import React, { Component } from "react";
import NavbarPage from "../containers/NavbarPageContainer";
import RecipientList from "../containers/RecipientListContainer";
import PropTypes from "prop-types";

export default class PageUser extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      getUser: PropTypes.func
    })
  };

  componentDidMount() {
    this.props.actions.getUser();
  }

  render() {
    return (
      <div>
        <NavbarPage />
        <div>
          <RecipientList />
        </div>
      </div>
    );
  }
}
