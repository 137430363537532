import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ProductsToRecipient from "../components/Modals/ProductsToRecipient";
import * as ProductsToRecipientActions from "../actions/products_to_recipient";
import { getProductCountsToRecipient } from "../actions/recipient_page";

const mapStateToProps = (state, ownProps) => {
  return {
    ...state.productsToRecipient,
    recipients: state.recipients.users
  };
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...ProductsToRecipientActions,
        getProductCountsToRecipient
      },
      dispatch
    )
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductsToRecipient);
