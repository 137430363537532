import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

export default class RecipientItem extends Component {
  renderRecipients = () => {
    const { user } = this.props;
    return (
      <div>
        <div className="row">
          <div className="col-6 col-md-3">
            <div className="image-form-control">
              <img
                src={require("../utils/images/icon_user.png")}
                alt="avatar"
                className="image-container"
              />
            </div>
          </div>
          <div className="col-6 col-md-6">
            <Link to={`recipient/${user.id}`}>
              <h4>
                <label>
                  <FormattedMessage
                    id="recipientItem.fullNameText"
                    defaultMessage="ФИО"
                  />{" "}
                  : {user.first_name + "  " + user.last_name}
                </label>
              </h4>
            </Link>
            <label>
              <FormattedMessage
                id="recipientItem.phoneText"
                defaultMessage="Тел"
              />
              :{" "}
            </label>{" "}
            {user.phone}
            <br />
            <label>
              <FormattedMessage
                id="recipientItem.storeText"
                defaultMessage="Торговый точка"
              />
              :{" "}
            </label>{" "}
            {user.store}
            <br />
            <label>
              <FormattedMessage
                id="recipientItem.categoryText"
                defaultMessage="Категории"
              />
              :{" "}
            </label>{" "}
            {user.user_category}
            <br />
          </div>
          <div className="col-6 col-rm-6">
            <Button
              className="button-form"
              onClick={this.props.showDeletet.bind(this, this.props.user)}
            >
              <i className="glyphicon glyphicon-trash" />
            </Button>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { user } = this.props;
    if (user.user_category === "UC1") {
      user.user_category = "Производитель";
    }
    if (user.user_category === "UC2") {
      user.user_category = "Дистрибютор";
    }
    if (user.user_category === "UC3") {
      user.user_category = "Диллер-оптовик";
    }
    if (user.user_category === "UC4") {
      user.user_category = "Магазин-продавец";
    }
    return (
      <div className="user-list-form-control">{this.renderRecipients()}</div>
    );
  }
}
